import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {IntercomService} from '../../../core/services/IntercomService';
import {UtilsService} from '../../../core/services/utils.service';
import {MatDialog} from '@angular/material';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {CategoryGuide, CategoryGuides, DocType, Guide} from '../../../models/Guide';
import {MenuItem} from '../../../shared/components/tabs-menu/tabs-menu.component';
import {GuestGuideDetailsComponent} from '../guest-guides/guest-guide-details/guest-guide-details.component';
import {Corporate} from '../../../models/guestview/Corporate';
import {GuideService} from '../../../core/services/GuideService';
import {GuestServiceIntern} from '../guest.service';
import {CorporateBookletSection} from '../../../models/CorporateBookletSection';

@Component({
  selector: 'app-y3-landing-guides',
  templateUrl: './y3-landing-guides.component.html',
  styleUrls: ['./y3-landing-guides.component.scss']
})
export class Y3LandingGuidesComponent extends AbstractWindow implements OnInit, OnChanges {

  @Input() booking: BookingForGuest;
  @Input() property: PropertyForGuest;
  @Input() corporate: Corporate;
  @Input() curIndex: number;
  @Input() section: CorporateBookletSection;
  categoryTabs: MenuItem[] = [];
  guides: Guide[];

  currentSelectedCategory: string;
  availableCategories = CategoryGuide.list();
  categoryGuides: CategoryGuides[] = [];
  currentGuidesList: Guide[] = [];

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              private guideServiceIntern: GuestServiceIntern,
              protected dialog: MatDialog) {
    super(intercomService, utilsService);
  }

  ngOnInit() {

    this.guideServiceIntern.getCurrentGuideId().subscribe( guideId => {
      if (this.property != null && this.property.guides != null) {
        this.property.guides.forEach(g => {
          if (g.guideId === guideId) {
            this.showGuide(g);
          }
        });
      }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.property && this.property.guides) {
      this.guides = this.property.guides;
      this.initCategories();
    }
  }

  initCategories() {
    const grouped = this.utilsService.groupByKey(this.property.guides, 'category');

    this.categoryGuides = [];
    Object.keys(grouped).forEach(categoryKey => {
      const catGuides = new CategoryGuides();
      catGuides.category = categoryKey; catGuides.guides = grouped[categoryKey];
      if (grouped[categoryKey].length > 0) {
        if (!this.availableCategories.includes(categoryKey)) {
          catGuides.custom = true;
        }
      }
      this.categoryGuides.push(catGuides);
    });

    this.categoryGuides.forEach(item => {
      if (item.custom) {
        const translatedCategory = this.getTranslatedCategory(item);
        this.categoryTabs.push(new MenuItem(translatedCategory, item.category, this.currentSelectedCategory === item.category));
      } else {
        this.categoryTabs.push(new MenuItem('guides-list.' + item.category, item.category, this.currentSelectedCategory === item.category));
      }
    });

    if (this.categoryGuides && this.categoryGuides.length > 0) {
      if (!this.currentSelectedCategory) {
        this.currentSelectedCategory = this.categoryGuides[0].category;
        this.currentGuidesList = this.categoryGuides[0].guides;
      }
    }
  }

  getTranslatedCategory(item: CategoryGuides) {
    if (!item.guides || item.guides.length === 0 || !item.guides[0] || !item.guides[0].translations) {
      return item.category;
    }
    return  item.guides[0].translations[0].category ? item.guides[0].translations[0].category : item.category;
  }

  getGuideCover(guide: Guide) {
    if (!guide || !guide.documents || guide.documents.length === 0) {
      return this.getPictureFromCategory(this.currentSelectedCategory);
    }
    const firstPhoto = guide.documents.find(item => item.type === DocType.IMAGE);
    if (!firstPhoto) {
      return this.getPictureFromCategory(guide.category);
    }

    return firstPhoto.url;
  }

  getPictureFromCategory(category: string) {
    let image;

    if (!category) {
      return image;
    }
    if (this.corporate && this.corporate.customGuideCategoriesPictures) {
      const found = this.corporate.customGuideCategoriesPictures.find(
        (item) => category === item.categoryName
      );
      if (found && found.url) {
        return found.url;
      }
    }

    switch (category) {
      case CategoryGuide.APPLIANCES:
        image = '../../../../assets/images/list-guides/Appliance/Appliances.png';
        break;
      case CategoryGuide.CHECKIN:
        image = '../../../../assets/images/list-guides/Checkin/checkin.png';
        break;
      case CategoryGuide.CHECKOUT:
        image = '../../../../assets/images/list-guides/Check-out/checkout.png';
        break;
      case CategoryGuide.ORIENTATION:
        image = '../../../../assets/images/list-guides/Direction/dir.png';
        break;
      case CategoryGuide.RULES:
        image = '../../../../assets/images/list-guides/Rules/img@3x.png';
        break;
      case CategoryGuide.SERVICES:
        image = '../../../../assets/images/list-guides/service.png';
        break;
      case CategoryGuide.WIFI:
        image = '../../../../assets/images/list-guides/Wifi/wifi.png';
        break;
      case CategoryGuide.PARKING:
        image = '../../../../assets/images/list-guides/Parking/parking.png';
        break;
      case CategoryGuide.TRASH:
        image = '../../../../assets/images/list-guides/Trash/trash.png';
        break;
      case CategoryGuide.HEATING:
        image = '../../../../assets/images/list-guides/Heating/heater.png';
        break;
      case CategoryGuide.OTHER:
        image = '../../../../assets/images/list-guides/Other/questions.png';
        break;
      default: {
        image = '../../../../assets/images/list-guides/Other/questions.png';
      }
    }
    return image;

  }

  needToShowGuide(guide: Guide) {
    if (guide.partnerName != null && guide.partnerName !== '' && guide.partnerType != null && guide.partnerType !== '') {
      if (guide.partnerName === 'swikly') {
        return this.isSwiklyConfigured() && !this.booking.swiklyInfo.swiklyDepositAcceptedDate;
      } else if (guide.partnerName === 'the-keys') {
        return this.isTheKeysConfigured();
      } else if (guide.partnerName === 'igloohome') {
        return this.isIgloohomeConfigured();
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  onTabMenuSelected(event) {
    this.currentSelectedCategory = event;
    this.currentGuidesList = this.property.guides.filter(item => item.category === event);
  }

  isSwiklyConfigured() {
    return this.booking && this.booking.swiklyInfo && this.booking.swiklyInfo.swiklyAcceptUrl;
  }
  isIgloohomeConfigured(): boolean {
    return this.property.iglooHomeDisplayWelcomeBooklet && this.booking && !!(this.booking.iglooHomeAccess ? this.booking.iglooHomeAccess.code : this.booking.iglooHomeLockCode);
  }

  isTheKeysConfigured(): boolean {
    return this.property.smartLockDisplayWelcomeBooklet && this.booking && this.booking.theKeysAccesses && this.booking.theKeysAccesses.length > 0;
  }

  categorySelectionChange(event) {
    this.currentGuidesList = this.property.guides.filter(item => item.category === event);
  }

  showGuide(guide: Guide) {
    const taskCreationDialog = this.dialog.open(GuestGuideDetailsComponent, {
      height: this.isDesktopMode() ? '90%' : '100vh',
      width: this.isDesktopMode() ? '75%': '100vw',
      autoFocus: false,
      data: {
        property: this.property,
        guide: guide
      }
    });
  }

}
