import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input, OnChanges,
  OnInit,
  Pipe, SimpleChanges,
  ViewChild,
} from '@angular/core';
import SignaturePad from "signature_pad";
import { BookingForGuest } from "../../../models/guestview/BookingForGuest";
import { delay, takeUntil } from "rxjs/operators";
import { LanguageManagementService } from "../../../core/services/language.service";
import { ActivatedRoute } from "@angular/router";
import { GuestService } from "../../../core/services/guest.service";
import { PropertyForGuest } from "../../../models/guestview/PropertyForGuest";
import { Subject } from "rxjs";

import { MatDialog } from "@angular/material/dialog";
import {
  DocumentUploadTypes,
  YaagoDocument,
} from "../../../models/YaagoDocument";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { UtilsService } from "src/app/core/services/utils.service";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Corporate } from "src/app/models/guestview/Corporate";
import { DeletePhotoComponent } from "../../guest/guest-inventory/delete-photo/delete-photo.component";
import { MatStepper } from "@angular/material";
import { OnlineCheckDetailsComponent } from "../online-check-details.component";
import { PaysService } from "src/app/core/services/pays.service";
import { DocumentService } from "src/app/core/services/DocumentService";
import { Photo } from "src/app/models/Photo";
import { DocType } from "src/app/models/Guide";

import {
  CountryISO,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { InternationalPhoneNumber } from "../InternationalPhoneNumber";
import { UsefulNumber } from "src/app/models/UsefulNumber";
import { OnlineCheckin } from "src/app/models/guestview/OnlineCheckin";
import { CheckinService } from "src/app/core/services/checkin.service";
import { CustomHeaderComponent } from "../custom-header/custom-header.component";
import { LoaderService } from "../../../core/services/LoaderService";
import { Console } from "console";
import { SnackbarService } from "../../../core/services/SnackbarService";
import { InternalPropertyService } from "../../../core/services/internal.property.service";
import { SharedSnackbarService } from "src/app/shared/components/shared-snackbar/services/shared-snackbar.service";
import {Title} from '@angular/platform-browser';

@Component({
  selector: "app-desktop-online-check-details",
  templateUrl: "./desktop-online-check-details.component.html",
  styleUrls: ["./desktop-online-check-details.component.scss"],
})
export class DesktopOnlineCheckDetailsComponent
  extends OnlineCheckDetailsComponent
  implements OnInit, AfterViewInit, OnChanges {
  // staticDate = new Date(2020, 25, 2);

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.France,
    CountryISO.UnitedKingdom,
  ];
  internationalPhoneNumber: InternationalPhoneNumber =
    new InternationalPhoneNumber();
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  signaturePad: any;
  signaturePadIsEmpty = false;
  // @ViewChild("canvasID", { static: false }) canvasID: ElementRef;

  //  bookingId: string;
  documentType : string
  onDestroy = new Subject();
  proofOfIdentity = [];
  proofOfIdentityy = [];
  documents : YaagoDocument[];
  empty = false;
  DocType = DocType;
  urlsPhotos = [];
  documentSignature = [];
  allowedImagesDocs = DocumentUploadTypes.images().concat(DocumentUploadTypes.pdf());
  allowedPfd = DocumentUploadTypes.pdf();
  pdf = DocType.PDF
  showSpinner: boolean;
  message: string;
  currentLang: string;
  checkin: any;
  checkout: any;
  onLineCheck: any;
  public myFirebaseProfile: BookingForGuest;
  language = "";
  signatureImg: any;

  display = false;
  // checkinOnlineWithTransport = false
  customHeader = CustomHeaderComponent;
  @Input() corporate: Corporate;
  @Input() booking: BookingForGuest;
  @Input() property: PropertyForGuest;
  @Input() countries: any[] = [];
  @Input() usefulNumber: UsefulNumber = new UsefulNumber();
  @Input() localOnLineCheck: OnlineCheckin;

  public config: PerfectScrollbarConfigInterface = {};
  blob: any;
  constructor(
    public loaderService: LoaderService,
    public languageService: LanguageManagementService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public guestService: GuestService,
    public translateService: TranslateService,
    public fb: FormBuilder,
    public paysService: PaysService,
    public documentService: DocumentService,
    public utilService: UtilsService,
    public checkinService: CheckinService,
    public snackbarService: SnackbarService,
    public propertyService: InternalPropertyService,
    public sharedSnackbarService: SharedSnackbarService,
    public title: Title
  ) {
    super(
      loaderService,
      languageService,
      route,
      dialog,
      guestService,
      translateService,
      fb,
      utilService,
      checkinService,
      paysService,
      documentService,
      snackbarService,
      propertyService,
      sharedSnackbarService,
      title
    );
  }
  resizeCanvas(isDesktop) {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    this.canvasID = document.getElementById("canvasIDForSignature");

    if (this.canvasID != null) {
      this.canvasID.width = this.canvasID.offsetWidth * ratio;

      this.canvasID.height = 250;
      this.canvasID.getContext("2d").scale(ratio, ratio);
    }
  }
  isDesktopMode(): boolean {
    return this.utilService.isDesktop(this.screenWidth);
  }
  ngOnInit() {
    console.log("prop", this.booking)
    console.log('ONLINE CHECK',this.localOnLineCheck)
    this.initForm();

    this.checkinService.mode.subscribe(res=> {
      this.mode = res;

      if(this.mode.edit) {
        this.proofOfIdentity = [];
        if (this.localOnLineCheck && this.localOnLineCheck.identityProofUrls) {
          this.localOnLineCheck.identityProofUrls.forEach( proof => {
            this.proofOfIdentity.push({
              url: proof,
              type: proof.endsWith('.pdf') ? 'PDF' : 'IMAGE'
            });
          });
        }
      }
    });
    if (this.localOnLineCheck && this.localOnLineCheck.phoneNumber) {
      this.firstFormGroup.controls["phone"].setValue(
        this.localOnLineCheck.phoneNumber.slice(
          this.localOnLineCheck.phoneNumber.indexOf(" ") + 1
        )
      );
    }

    this.utilService.bookingTransferSub.subscribe((res) => {
      this.booking = res;

      this.route.queryParams.subscribe((res) => {
        if (res.lang) {
          this.language = res.lang;
        } else {
          if (this.booking.guests[0].languages) {
            this.language = this.booking.guests[0].languages[0].toUpperCase();
          }
        }
      });
      this.paysService
        .getPays(this.languageService.getLanguageInUrl())
        .pipe(takeUntil(this.onDestroy))
        .subscribe((res) => {
          this.countries = res;

          if (this.booking) {
            let language;
            console.log("this.language", this.language);
            if (
              this.language.toLowerCase() == "en" ||
              this.language.toLowerCase() == "ja" ||
              this.language.toLowerCase() == "tr" ||
              this.language.toLowerCase() == "ru" ||
              this.language.toLowerCase() == "zh"
            ) {
              language = "eng";
            } else {
              language = res.find(
                (element) =>
                  element.cca2.toUpperCase() == this.language.toUpperCase() // this.booking.guests[0].languages[0].toUpperCase()
              );
            }

            console.log("Lang", language);
            this.route.queryParams.subscribe((res) => {
              if (res.lang) {
                switch (res.lang) {
                  case "fr":
                    this.language = "fra";
                    break;

                  case "en":
                    this.language = "eng";
                    break;
                  case "de":
                    this.language = "deu";
                    break;
                  case "es":
                    this.language = "spa";
                    break;
                  case "it":
                    this.language = "ita";
                    break;
                  case "nl":
                    this.language = "nld";
                    break;
                  case "da":
                    this.language = "eng";
                    break;
                  case "pt":
                    this.language = "por";
                    break;
                  case "ja":
                    this.language = "eng";
                    break;
                  case "no":
                    this.language = "slk";
                    break;
                  case "pl":
                    this.language = "pol";
                    break;
                  case "ru":
                    this.language = "eng";
                    break;
                  case "sv":
                    this.language = "swe";
                    break;
                  case "tr":
                    this.language = "eng";
                    break;
                  case "zh":
                    this.language = "eng";
                    break;
                }
              } else {
                if (language && language != "eng") {
                  this.language = language.cca3.toLowerCase();
                } else {
                  this.language = "eng";
                }

                if (this.language === "esp") {
                  this.language = "spa";
                }
              }

              if (this.language == "eng") {
                this.countries.sort((a, b) =>
                  a.name.common > b.name.common ? 1 : -1
                );
              } else {
                this.countries.sort((a, b) =>
                  a.translations[this.language].common[0] >
                    b.translations[this.language].common[0]
                    ? 1
                    : -1
                );
              }
            });
          }
        });
    });
  }
  ngAfterViewInit(): void {

    this.canvasID = document.getElementById("canvasIDForSignature");
    if (this.localOnLineCheck) {
      this.localOnLineCheck = this.booking.onLineCheck;
      // this.proofOfIdentity = this.booking.onLineCheck.identityProofUrls;

    }
    if (this.localOnLineCheck && this.localOnLineCheck.phoneNumber) {
      this.firstFormGroup.controls["phone"].setValue(
        this.localOnLineCheck.phoneNumber.slice(
          this.localOnLineCheck.phoneNumber.indexOf(" ") + 1
        )
      );
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    let test = this.isDesktopMode();
    this.resizeCanvas(test);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.corporate != null) {
      console.log("corporate", this.corporate);
      if (this.corporate.intlPreferredCountries != null && this.corporate.intlPreferredCountries.length > 0) {
        this.preferredCountries = this.corporate.intlPreferredCountries;
      }
    }
  }
}
