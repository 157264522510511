import { Component, OnInit } from '@angular/core';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {LoaderService} from '../../../core/services/LoaderService';
import {IntercomService} from '../../../core/services/IntercomService';
import {GuestService} from '../../../core/services/guest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../core/services/utils.service';
import {LanguageManagementService} from '../../../core/services/language.service';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {Title} from '@angular/platform-browser';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {GuestBookingInformationConfirmationDto} from '../../../models/preCheckOnLine/GuestBookingInformationConfirmationDto';

@Component({
  selector: 'app-online-check-light',
  templateUrl: './online-check-light.component.html',
  styleUrls: ['./online-check-light.component.scss']
})
export class OnlineCheckLightComponent extends AbstractWindow implements OnInit {

  disableMainEmail = false;
  favIcon: HTMLLinkElement = document.querySelector('#favicon');

  public guruTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#FFF',
      buttonColor: '#072D4D',
      primaryFontFamily: 'Barlow Semi Condensed',
    },
    dial: {
      dialBackgroundColor: '#49B1D6',
      dialActiveColor: '#FFF',
      dialInactiveColor: '#BFD7DD',
    },
    clockFace: {
      clockFaceBackgroundColor: '#F0F3F4',
      clockHandColor: '#01085C',
      clockFaceTimeInactiveColor: '#072D4D',
      clockFaceTimeDisabledColor: '#8FB0C4',
    },
  };
  property: PropertyForGuest;
  booking: BookingForGuest;
  corporate: Corporate;
  bookingToSend: GuestBookingInformationConfirmationDto;
  adult = 0;
  children = 0;
  infants = 0;
  pets = 0;
  double_beds = 0;
  single_beds = 0;
  sofa_beds = 0;
  baby_beds = 0;
  note: string;
  bookingId: string;
  checkin: any;
  checkout: any;
  screenWidth: number;
  disableButton: boolean = false;
  constructor(
    private loaderService: LoaderService,
    public intercomService: IntercomService,
    private guestService: GuestService,
    private route: ActivatedRoute,
    private snackbar: SharedSnackbarService,
    private translate: TranslateService,
    public utilService: UtilsService,
    private languageService: LanguageManagementService,
    private title: Title,
    public router: Router
  ) {
    super(intercomService, utilService);
    this.getScreenWidth();
  }
  urlLang = this.translate.currentLang;

  ngOnInit() {
    this.route.paramMap.subscribe( ParamMap => {
      this.bookingId =  ParamMap.get('bookingId');
      if (!this.bookingId) {
        return;
      }
      const lang = this.languageService.getLanguageInUrl();
      this.guestService.getPropertyAndBookingForGuest( this.bookingId , lang).pipe(takeUntil(this.onDestroy)).subscribe( res => {
        this.property = res.property;
        this.booking = res.booking;
        this.corporate = res.corporate;



        if (this.booking) {
          this.adult = this.booking.adults;
          this.children = this.booking.children;
          this.infants = this.booking.babies;
          this.pets = (this.booking.pets == null) ? 0 : this.booking.pets ;
          this.double_beds = this.booking.doubleBeds;
          this.single_beds = this.booking.singleBeds;
          this.sofa_beds = this.booking.sofaBeds;
          this.baby_beds = this.booking.babyBeds;

          console.log("-----------checkEmail");
          if (this.booking.guests != null && this.booking.guests.length > 0 && this.booking.guests[0].email) {
            console.log("-----------checkEmail");
            if (this.booking.guests[0].email.indexOf("guest.airbnb.com") > 0 ) {
              this.disableMainEmail = true;
            }
            if (this.booking.guests[0].email.indexOf("guest.booking.com") > 0 ) {
              this.disableMainEmail = true;
            }
            if (this.booking.guests[0].email.indexOf("airbnb.bookingsync.com") > 0 ) {
              this.disableMainEmail = true;
            }

          }

          if (this.booking.expectedCheckinLocalTime) {
            this.checkin = moment(this.booking.expectedCheckinLocalTime, 'hh:mm:ss').format('HH:mm');
          }
          if (this.booking.requestedCheckinLocalTime) {
            this.checkin = moment(this.booking.requestedCheckinLocalTime, 'hh:mm:ss').format('HH:mm');
          }

          if (this.booking.expectedCheckoutLocalTime) {
            this.checkout = moment(this.booking.expectedCheckoutLocalTime, 'hh:mm:ss').format('HH:mm');
          }
          if (this.booking.requestedCheckoutLocalTime) {
            this.checkin = moment(this.booking.requestedCheckoutLocalTime, 'hh:mm:ss').format('HH:mm');
          }

          console.log("Get the corporate from the ngOninit Component", this.corporate);
          if (this.corporate != null && this.corporate.favLogoUrl != null && this.corporate.favLogoUrl !== '' ) {
            this.favIcon.href = this.corporate.favLogoUrl;
            if (this.corporate.bookletBrowserTitle != null) {
              this.title.setTitle(this.corporate.bookletBrowserTitle);
            } else {
              this.title.setTitle(this.corporate.name);
            }
          }
          // CUSTOMISATION DES COULEURS
          this.utilService.manageCorporateColors(this.corporate);

        }
      });
    });


  }
  AddType(type: string) {
    console.log(this.booking);
    switch (type) {
      case 'adult':
        console.log('personCap', this.property.personCapacity);
        if (this.property.personCapacity > 0) {
          if (this.adult + this.children < this.property.personCapacity) {
            this.adult++;
          }
        } else {
          this.adult++;
        }
        break;
      case 'children':
        if (this.property.personCapacity > 0) {
          if (this.adult + this.children < this.property.personCapacity) {
            this.children++;
          }
        }
        break;
      case 'infants':
        this.infants++;
        break;
      case 'pets':
        this.pets++;
        break;
      case 'double_beds':
        this.double_beds < this.property.doubleBeds ? this.double_beds++ : this.double_beds = this.property.doubleBeds;
        break;
      case 'single_beds':
        this.single_beds < this.property.singleBeds ? this.single_beds++ : this.single_beds = this.property.singleBeds;
        break;
      case 'sofa_beds':
        this.sofa_beds < this.property.sofaBeds ? this.sofa_beds++ : this.sofa_beds = this.property.sofaBeds;
        break;
      case 'baby_beds':
        this.baby_beds < this.property.babyBeds ? this.baby_beds++ : this.baby_beds = this.property.babyBeds;
        break;
      default: {

      }
    }
  }
  removeType(type: string) {
    switch (type) {
      case 'adult':
        this.adult > 0 ? this.adult-- : this.adult = 0;
        break;
      case 'children':
        this.children > 0 ? this.children-- : this.children = 0;
        break;
      case 'infants':
        this.infants > 0 ? this.infants-- : this.infants = 0;
        break;
      case 'pets':
        this.pets > 0 ? this.pets-- : this.pets = 0;
        break;
      case 'double_beds':
        this.double_beds > 0 ? this.double_beds-- : this.double_beds = 0;
        break;
      case 'single_beds':
        this.single_beds > 0 ? this.single_beds-- : this.single_beds = 0;
        break;
      case 'sofa_beds':
        this.sofa_beds > 0 ? this.sofa_beds-- : this.sofa_beds = 0;
        break;
      case 'baby_beds':
        this.baby_beds > 0 ? this.baby_beds-- : this.baby_beds = 0;
        break;
      default: {

      }
    }
  }

  saveBookingInfo() {

    this.loaderService.dismissLoader();
    this.snackbar.dismiss();

    if (this.booking.guests[0].secondaryEmail == '' && this.disableMainEmail) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant( 'guest-setup-page.empty-email'), 'error'));
      return;
    }


    if (!this.checkin || !this.checkout) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant( 'guest-setup-page.checkin-checkout-empty'), 'error'));
      return;
    }

    if (!this.booking.guests[0].firstName || !this.booking.guests[0].lastName) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant( 'guest-setup-page.name-empty'), 'error'));
      return;
    }

    if (this.property.defaultCheckInTime && !this.property.canEarlyCheck) {
      if (moment(this.checkin, 'hh:mm').isBefore(moment(this.property.defaultCheckInTime, 'hh:mm'))) {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-setup-page.impossible-checkin-time') + ' '
          + this.property.defaultCheckInTime, 'error'));
        return;
      }
    }
    if (this.property.defaultCheckOutTime && !this.property.canEarlyCheck) {
      if (moment(this.checkout, 'hh:mm').isAfter(moment(this.property.defaultCheckOutTime, 'hh:mm'))) {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-setup-page.impossible-checkout-time') + ' '
          + this.property.defaultCheckOutTime, 'error'));
        return;
      }
    }

    this.disableButton = true;
    this.bookingToSend = new GuestBookingInformationConfirmationDto();

    if (!this.corporate.requestedCheckTimeOnly) {
      this.bookingToSend.expectedCheckinLocalTime = this.checkin;
      this.bookingToSend.expectedCheckoutLocalTime = this.checkout;
      this.bookingToSend.requestedCheckinLocalTime = null;
      this.bookingToSend.requestedCheckoutLocalTime = null;
    } else {
      this.bookingToSend.expectedCheckinLocalTime = null;
      this.bookingToSend.expectedCheckoutLocalTime = null;
      this.bookingToSend.requestedCheckinLocalTime = this.checkin;
      this.bookingToSend.requestedCheckoutLocalTime = this.checkout;
    }

    this.bookingToSend.guestNote = this.booking.guestNote;
    this.bookingToSend.adults = this.adult;
    this.bookingToSend.children = this.children;
    this.bookingToSend.babies = this.infants;
    this.bookingToSend.pets = this.pets;
    this.bookingToSend.doubleBeds = this.double_beds;
    this.bookingToSend.singleBeds = this.single_beds;
    this.bookingToSend.sofaBeds = this.sofa_beds;
    this.bookingToSend.babyBeds = this.baby_beds;
    this.bookingToSend.guestNote = this.booking.guestNote;
    this.bookingToSend.firstName = this.booking.guests[0].firstName;
    this.bookingToSend.lastName = this.booking.guests[0].lastName;
    this.bookingToSend.phone = this.booking.guests[0].phone;
    this.bookingToSend.secondaryPhone = this.booking.guests[0].secondaryPhone;
    this.bookingToSend.email = this.booking.guests[0].email;
    this.bookingToSend.secondaryEmail = this.booking.guests[0].secondaryEmail;
    this.bookingToSend.companyName = this.booking.guests[0].companyName;

    this.loaderService.showFullLoader('');
    this.guestService.saveBooking(this.booking.id , this.bookingToSend ).subscribe(resp => {
      this.loaderService.dismissLoader();
      this.disableButton = false;
      this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-setup-page.guest_success_message'), 'info'));
      this.router.navigate(["/guest/" + this.booking.id]);
    }, err => {
      this.loaderService.dismissLoader();
      this.disableButton = false;
      console.log('ERROR', err);
      this.snackbar.push(new SnackbarMessage(this.translate.instant('guest-setup-page.' + err.error.message), 'error'));

    });

  }

  removeAdult() {
    if (this.adult > 0) {
      this.adult--;
    }
  }

  checkDisableBtnDecre(value : number) {
    if (value<1 ) {
      return 'primary-not-valid';
    }
  }


  addAdult() {
    if (this.property.personCapacity > 0) {
      if (this.adult + this.children < this.property.personCapacity) {
        this.adult++;
      }
    } else {
      this.adult++;
    }
  }

  addChild() {
    if (this.property.personCapacity > 0) {
      if (this.adult + this.children < this.property.personCapacity) {
        this.children++;
      }
    } else {
      this.children++;
    }

  }

  removeChild() {
    if (this.children > 0) {
      this.children--;
    }
  }

  addBaby() {
    this.infants++;
  }
  addPets() {
    this.pets++;
  }
  removePets() {
    if (this.pets>0) {
      this.pets--;
    }
  }
  removeBaby() {
    if (this.infants > 0) {
      this.infants--;
    }
  }

  add(type: string) {
    if (type === 'double' && this.double_beds<this.property.doubleBeds) {
      this.double_beds++;
    }
    if (type === 'single' && this.single_beds<this.property.singleBeds) {
      this.single_beds++;
    }
    if (type === 'sofa' && this.sofa_beds<this.property.sofaBeds) {
      this.sofa_beds++;
    }
    if (type === 'crib' && this.baby_beds<this.property.babyBeds) {
      this.baby_beds++;
    }
  }

  remove(type: string) {
    if (type === 'double' && this.double_beds > 0) {
      this.double_beds--;
    }
    if (type === 'single' && this.single_beds > 0) {
      this.single_beds--;
    }
    if (type === 'sofa' && this.sofa_beds > 0) {
      this.sofa_beds--;
    }
    if (type === 'crib' && this.baby_beds > 0) {
      this.baby_beds--;
    }

  }

  checkDisableBtnIncre(value : number,type: string) {
    if (type === 'double' && value > this.property.doubleBeds-1) {
      return 'primary-not-valid';
    }
    if (type === 'single' && value > this.property.singleBeds-1) {
      return 'primary-not-valid';
    }
    if (type === 'sofa' && value > this.property.sofaBeds-1) {
      return 'primary-not-valid';
    }
    if (type === 'crib' && value > this.property.babyBeds-1) {
      return 'primary-not-valid';
    }
  }

  isCotoon(): boolean {
    return (this.property.host.email.indexOf('cotoon') >= 0);
  }

}
