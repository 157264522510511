/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./photo-detail.component";
import * as i3 from "@angular/material/dialog";
var styles_PhotoDetailComponent = [i0.styles];
var RenderType_PhotoDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoDetailComponent, data: {} });
export { RenderType_PhotoDetailComponent as RenderType_PhotoDetailComponent };
export function View_PhotoDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["id", "detail-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "closeIcon"], ["src", "assets/icon/icon-exit.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "photo-detail"], ["class", "photodetail"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "nav-barre"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "nav-btn prev"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "../../../../assets/icon/back.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "nav-btn next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["src", "../../../../assets/icon/next.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.photo.url.replace("aki_policy=medium", "aki_policy=large"), ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_PhotoDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-photo-detail", [], null, null, null, View_PhotoDetailComponent_0, RenderType_PhotoDetailComponent)), i1.ɵdid(1, 114688, null, 0, i2.PhotoDetailComponent, [i3.MAT_DIALOG_DATA, i3.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoDetailComponentNgFactory = i1.ɵccf("app-photo-detail", i2.PhotoDetailComponent, View_PhotoDetailComponent_Host_0, {}, {}, []);
export { PhotoDetailComponentNgFactory as PhotoDetailComponentNgFactory };
