<footer tabindex="-1" id="SITE_FOOTER_WRAPPER">
  <div id="SITE_FOOTER" class="_3Fgqs">
    <!-- <div class="rmFV4"></div> -->
    <div class="_319u9">
      <div class="_3N4he"></div>
      <div class="_1U65c">
        <div
          data-mesh-id="SITE_FOOTERinlineContent"
          data-testid="inline-content"
          class=""
        >
          <div
            data-mesh-id="SITE_FOOTERinlineContent-gridContainer"
            data-testid="mesh-container-content"
          >
            <div
              id="comp-jdebmz3r"
              class="_1Q9if"
              data-testid="richTextElement"
            >
              <h2 class="font_2" style="font-size: 25px">
                <span style="font-size: 25px"
                  ><span class="color_23"
                    ><span style="font-family: Futura-Heavy, sans-serif"
                      >VBM</span
                    ></span
                  ></span
                >
              </h2>
            </div>
            <div id="comp-jdecpqou" aria-disabled="false" class="_2kLdM">
              <a
                data-testid="linkElement"
                href="https://www.villabalimanagement.com/about-vbm"
                target="_blank"
                class="_6lnTT"
                aria-disabled="false"
                ><span class="wQYUw">About us</span></a
              >
            </div>
            <div id="comp-jdecrrc9" aria-disabled="false" class="_2kLdM">
              <a
                data-testid="linkElement"
                href="https://www.villabalimanagement.com/about-bali"
                target="_blank"
                class="_6lnTT"
                aria-disabled="false"
                ><span class="wQYUw">About Bali</span></a
              >
            </div>
            <div id="comp-kgejb66m" aria-disabled="false" class="_2kLdM">
              <a
                data-testid="linkElement"
                href="https://www.villabalimanagement.com/about-vbmconcierge"
                target="_blank"
                class="_6lnTT"
                aria-disabled="false"
                ><span class="wQYUw">VBM Concierge</span></a
              >
            </div>
            <div
              id="comp-jdebptbp"
              class="_1Q9if"
              data-testid="richTextElement"
            >
              <h2 class="font_2" style="font-size: 25px">
                <span style="font-size: 25px"
                  ><span class="color_23"
                    ><span style="font-family: Futura-Heavy, sans-serif"
                      >Explore</span
                    ></span
                  ></span
                >
              </h2>
            </div>

            <div id="comp-jdectu941" aria-disabled="false" class="_2kLdM">
              <a
                data-testid="linkElement"
                href="https://www.villabalimanagement.com/properties"
                target="_blank"
                class="_6lnTT"
                aria-disabled="false"
                ><span class="wQYUw">Our Properties</span></a
              >
            </div>
            <div id="comp-jdect9rm" aria-disabled="false" class="_2kLdM">
              <a
                data-testid="linkElement"
                href="https://www.balinb.com/"
                target="_blank"
                class="_6lnTT"
                aria-disabled="false"
                ><span class="wQYUw">Balinb, VBM Booking Platform</span></a
              >
            </div>
            <div id="comp-kgej88vo" aria-disabled="false" class="_2kLdM">
              <a
                data-testid="linkElement"
                href="https://www.airbnb.com/users/21787812/listings?user_id=21787812&amp;s=50"
                target="_blank"
                class="_6lnTT"
                aria-disabled="false"
                ><span class="wQYUw wQYUw_trx">Main Account</span></a
              >
            </div>

            <div id="comp-kgej9jzn" class="XUUsC _2S73V" title="">
              <a
                data-testid="linkElement"
                href="https://www.airbnb.com/users/21787812/listings?user_id=21787812&amp;s=50"
                target="_blank"
                class="xQ_iF"
              >
                <img
                  id="img_comp-kgej9jzn"
                  class="_1-6YJ _1Fe8-"
                  data-image-info='{"containerId":"comp-kgej9jzn","displayMode":"fill","imageData":{"width":1548,"height":641,"uri":"e16dc7_f6943f16e9cf4ec792e0879c02361a6b~mv2.png","name":"airBnBsuperhost_badge.png","displayMode":"fill"}}'
                  data-bg-effect-name=""
                  data-is-svg="false"
                  data-is-svg-mask="false"
                  data-image-zoomed=""
                  data-has-ssr-src=""
                  data-src="https://static.wixstatic.com/media/e16dc7_f6943f16e9cf4ec792e0879c02361a6b~mv2.png/v1/fill/w_98,h_40,al_c,q_85,usm_0.66_1.00_0.01/airBnBsuperhost_badge.webp"
                />
                <img
                  src="https://static.wixstatic.com/media/e16dc7_f6943f16e9cf4ec792e0879c02361a6b~mv2.png/v1/fill/w_98,h_40,al_c,q_85,usm_0.66_1.00_0.01/airBnBsuperhost_badge.webp"
                  alt="airBnBsuperhost_badge.png"
                  style="
                    width: 98px;
                    height: 40px;
                    object-fit: cover;
                    object-position: 50% 50%;
                  "
                />
              </a>
            </div>
            <div
              id="comp-jdebr3uz"
              class="_1Q9if"
              data-testid="richTextElement"
            >
              <h2 class="font_2" style="font-size: 25px">
                <span style="font-size: 25px"
                  ><span class="color_23"
                    ><span style="font-family: Futura-Heavy, sans-serif"
                      >Connect</span
                    ></span
                  ></span
                >
              </h2>
            </div>
            <div id="comp-jdecvotc" aria-disabled="false" class="_2kLdM">
              <a
                data-testid="linkElement"
                href="https://www.instagram.com/villabalimanagement/"
                target="_blank"
                class="_6lnTT"
                aria-disabled="false"
                ><span class="wQYUw">Instagram</span></a
              >
            </div>
            <div id="comp-jdecvotd" aria-disabled="false" class="_2kLdM">
              <a
                data-testid="linkElement"
                href="https://www.facebook.com/VillaBaliManagement/"
                target="_blank"
                class="_6lnTT"
                aria-disabled="false"
                ><span class="wQYUw">Facebook</span></a
              >
            </div>
            <div id="comp-jdecvotd1" aria-disabled="false" class="_2kLdM">
              <a
                data-testid="linkElement"
                href="https://www.villabalimanagement.com/contact-us"
                class="_6lnTT"
                aria-disabled="false"
                ><span class="wQYUw">Contact us</span></a
              >
            </div>
            <div *ngIf="isDesktopMode()">
              <section id="comp-ku65pv0u" class="_3d64y">
                <div
                  id="bgLayers_comp-ku65pv0u"
                  data-hook="bgLayers"
                  class="_3wnIc"
                >
                  <div data-testid="colorUnderlay" class="_3KzuS _3SQN-"></div>
                  <div id="bgMedia_comp-ku65pv0u" class="_2GUhU"></div>
                </div>
                <div data-testid="columns" class="_1uldx">
                  <div id="comp-ku65pv1v1" class="_1vNJf">
                    <div
                      id="bgLayers_comp-ku65pv1v1"
                      data-hook="bgLayers"
                      class="_3wnIc"
                    >
                      <div
                        data-testid="colorUnderlay"
                        class="_3KzuS _3SQN-"
                      ></div>
                      <div id="bgMedia_comp-ku65pv1v1" class="_2GUhU">
                        <img
                          src="https://static.wixstatic.com/media/e16dc7_9945f6299c584781979315a9677bd2b6~mv2.jpg/v1/fill/w_1903,h_434,al_c,q_85,usm_0.66_1.00_0.01/e16dc7_9945f6299c584781979315a9677bd2b6~mv2.webp"
                          alt="Villa Lumahi Tiga, Canggu"
                        />
                      </div>
                    </div>
                    <div
                      data-mesh-id="comp-ku65pv1v1inlineContent"
                      data-testid="inline-content"
                      class=""
                    >
                      <div
                        data-mesh-id="comp-ku65pv1v1inlineContent-gridContainer"
                        data-testid="mesh-container-content"
                      >
                        <div id="comp-ku65pv1z" class="_2bjge">
                          <div class="qDqoW" data-testid="container-bg"></div>
                          <div
                            data-mesh-id="comp-ku65pv1zinlineContent"
                            data-testid="inline-content"
                            class=""
                          >
                            <div
                              data-mesh-id="comp-ku65pv1zinlineContent-gridContainer"
                              data-testid="mesh-container-content"
                            >
                              <div
                                id="comp-ku65pv20"
                                class="_1Q9if"
                                data-testid="richTextElement"
                              >
                                <h2
                                  class="font_2"
                                  style="font-size: 36px; text-align: center"
                                >
                                  <span class="color_11"
                                    ><span style="font-weight: bold"
                                      ><span style="font-size: 36px"
                                        ><span style="letter-spacing: 0.15em"
                                          ><span
                                            style="
                                              font-family: Futura-Light,
                                                sans-serif;
                                            "
                                            >Visit or Contact us</span
                                          ></span
                                        ></span
                                      ></span
                                    ></span
                                  >
                                </h2>
                              </div>
                              <div id="comp-ku66sviw" class="_1KV2M">
                                <div
                                  data-mesh-id="comp-ku66sviwinlineContent"
                                  data-testid="inline-content"
                                  class=""
                                >
                                  <div
                                    data-mesh-id="comp-ku66sviwinlineContent-gridContainer"
                                    data-testid="mesh-container-content"
                                  >
                                    <div
                                      id="comp-ku65pv21"
                                      class="_2Hij5"
                                      data-testid="richTextElement"
                                    >
                                      <p
                                        class="font_8"
                                        style="
                                          font-size: 24px;
                                          line-height: normal;
                                          text-align: center;
                                        "
                                      >
                                        <span style="font-size: 24px"
                                          ><span
                                            style="
                                              font-family: Futura-Light,
                                                sans-serif;
                                            "
                                            ><span
                                              style="
                                                color: rgb(252, 252, 252);
                                                font-weight: bold;
                                                letter-spacing: 0.03em;
                                              "
                                              >Villa Bali Management</span
                                            ></span
                                          ></span
                                        >
                                      </p>

                                      <p
                                        class="font_8"
                                        style="
                                          font-size: 15px;
                                          line-height: normal;
                                          text-align: center;
                                        "
                                      >
                                        <span
                                          style="
                                            color: rgb(252, 252, 252);
                                            font-family: Futura-Light,
                                              sans-serif;
                                            letter-spacing: 0.03em;
                                          "
                                          >Canggu Square,</span
                                        >
                                      </p>

                                      <p
                                        class="font_8"
                                        style="
                                          font-size: 15px;
                                          line-height: normal;
                                          text-align: center;
                                        "
                                      >
                                        <span
                                          style="
                                            color: rgb(252, 252, 252);
                                            font-family: Futura-Light,
                                              sans-serif;
                                            letter-spacing: 0.03em;
                                          "
                                          >Jl.Pantai Berawa No.13</span
                                        >
                                      </p>

                                      <p
                                        class="font_8"
                                        style="
                                          font-size: 15px;
                                          line-height: normal;
                                          text-align: center;
                                        "
                                      >
                                        <span style="color: #fcfcfc"
                                          ><span style="letter-spacing: 0.03em"
                                            ><span
                                              style="
                                                font-family: Futura-Light,
                                                  sans-serif;
                                              "
                                              ><span style="font-size: 15px"
                                                >80361</span
                                              ></span
                                            ></span
                                          ></span
                                        >
                                      </p>

                                      <p
                                        class="font_8"
                                        style="
                                          font-size: 15px;
                                          line-height: normal;
                                          text-align: center;
                                        "
                                      >
                                        <span style="color: #fcfcfc"
                                          ><span style="letter-spacing: 0.03em"
                                            ><span
                                              style="
                                                font-family: Futura-Light,
                                                  sans-serif;
                                              "
                                              ><span style="font-size: 15px"
                                                >Bali, Indonesia</span
                                              ></span
                                            ></span
                                          ></span
                                        >
                                      </p>
                                    </div>
                                    <div
                                      id="comp-ku65pv221"
                                      class="_2UdPt"
                                    ></div>
                                    <div
                                      id="comp-ku65szc9"
                                      class="_1Q9if"
                                      data-testid="richTextElement"
                                    >
                                      <p
                                        class="font_8"
                                        style="
                                          font-size: 18px;
                                          text-align: center;
                                        "
                                      >
                                        <span style="font-size: 18px"
                                          ><span
                                            style="
                                              font-family: Futura-Light,
                                                sans-serif;
                                            "
                                            ><span
                                              style="
                                                color: rgb(252, 252, 252);
                                                font-weight: 700;
                                                letter-spacing: 0.03em;
                                              "
                                              >General inquiries</span
                                            ></span
                                          ></span
                                        >
                                      </p>

                                      <p
                                        class="font_8"
                                        style="
                                          font-size: 15px;
                                          text-align: center;
                                        "
                                      >
                                        <a
                                          href="mailto:info@villabalimanagement.com"
                                          target="_self"
                                          ><span
                                            style="
                                              font-family: Futura-Light,
                                                sans-serif;
                                            "
                                            ><span
                                              style="
                                                color: rgb(252, 252, 252);
                                                font-weight: 700;
                                                letter-spacing: 0.03em;
                                              "
                                              >info</span
                                            ></span
                                          ><span class="color_11"
                                            ><span
                                              style="
                                                font-family: Futura-Light,
                                                  sans-serif;
                                              "
                                              >@villabalimanagement.com</span
                                            ></span
                                          ></a
                                        >
                                      </p>
                                    </div>
                                    <div
                                      id="comp-ku66rnxr"
                                      class="_1Q9if"
                                      data-testid="richTextElement"
                                    >
                                      <p
                                        class="font_8"
                                        style="
                                          font-size: 18px;
                                          text-align: center;
                                        "
                                      >
                                        <span style="font-size: 18px"
                                          ><span
                                            style="
                                              font-family: Futura-Light,
                                                sans-serif;
                                            "
                                            ><span
                                              style="
                                                color: rgb(252, 252, 252);
                                                font-weight: 700;
                                                letter-spacing: 0.03em;
                                              "
                                              >Booking inquiries</span
                                            ></span
                                          ></span
                                        >
                                      </p>

                                      <p
                                        class="font_8"
                                        style="
                                          font-size: 15px;
                                          text-align: center;
                                        "
                                      >
                                        <a
                                          href="mailto:booking@villabalimanagement.com"
                                          target="_self"
                                          ><span
                                            style="
                                              font-family: Futura-Light,
                                                sans-serif;
                                            "
                                            ><span
                                              style="
                                                color: rgb(252, 252, 252);
                                                font-weight: 700;
                                                letter-spacing: 0.03em;
                                              "
                                              >booking</span
                                            ></span
                                          ><span class="color_11"
                                            ><span
                                              style="
                                                font-family: Futura-Light,
                                                  sans-serif;
                                              "
                                              >@villabalimanagement.com</span
                                            ></span
                                          ></a
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isDesktopMode()">
    <div class="lower-section">
      <div class="card grid-img GridMar">
        <img
          class="imageFEddv"
          src="https://static.wixstatic.com/media/e16dc7_9945f6299c584781979315a9677bd2b6~mv2.jpg/v1/fill/w_640,h_280,al_c,q_80,usm_0.66_1.00_0.01/e16dc7_9945f6299c584781979315a9677bd2b6~mv2.webp"
        />
        <div class="card-body">
          <div id="comp-ku66sviw" class="_1KV2M">
            <div
              data-mesh-id="comp-ku66sviwinlineContent"
              data-testid="inline-content"
              class=""
            >
              <div
                data-mesh-id="comp-ku66sviwinlineContent-gridContainer"
                data-testid="mesh-container-content"
              >
                <div
                  id="comp-ku65pv21"
                  class="_2Hij5"
                  data-testid="richTextElement"
                >
                  <p
                    class="font_8"
                    style="
                      font-size: 24px;
                      line-height: normal;
                      text-align: center;
                    "
                  >
                    <span style="font-size: 24px"
                      ><span style="font-family: Futura-Light, sans-serif"
                        ><span
                          style="
                            color: rgb(252, 252, 252);
                            font-weight: bold;
                            letter-spacing: 0.03em;
                          "
                          >Villa Bali Management</span
                        ></span
                      ></span
                    >
                  </p>

                  <p
                    class="font_8"
                    style="
                      font-size: 15px;
                      line-height: normal;
                      text-align: center;
                    "
                  >
                    <span
                      style="
                        color: rgb(252, 252, 252);
                        font-family: Futura-Light, sans-serif;
                        letter-spacing: 0.03em;
                      "
                      >Canggu Square,</span
                    >
                  </p>

                  <p
                    class="font_8"
                    style="
                      font-size: 15px;
                      line-height: normal;
                      text-align: center;
                    "
                  >
                    <span
                      style="
                        color: rgb(252, 252, 252);
                        font-family: Futura-Light, sans-serif;
                        letter-spacing: 0.03em;
                      "
                      >Jl.Pantai Berawa No.13</span
                    >
                  </p>

                  <p
                    class="font_8"
                    style="
                      font-size: 15px;
                      line-height: normal;
                      text-align: center;
                    "
                  >
                    <span style="color: #fcfcfc"
                      ><span style="letter-spacing: 0.03em"
                        ><span style="font-family: Futura-Light, sans-serif"
                          ><span style="font-size: 15px">80361</span></span
                        ></span
                      ></span
                    >
                  </p>

                  <p
                    class="font_8"
                    style="
                      font-size: 15px;
                      line-height: normal;
                      text-align: center;
                    "
                  >
                    <span style="color: #fcfcfc"
                      ><span style="letter-spacing: 0.03em"
                        ><span style="font-family: Futura-Light, sans-serif"
                          ><span style="font-size: 15px"
                            >Bali, Indonesia</span
                          ></span
                        ></span
                      ></span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
