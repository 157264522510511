import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit, OnChanges {

  @Input() padding = '16px';
  @Input() type: TagType = TagType.TRANSPARENT;
  @Input() status: TagStatus;
  @Input() text: string;
  @Input() text2: string;
  @Input() sizeText2 = '14px';
  @Input() minWidth;
  @Input() url;
  @Output() closeEvent = new EventEmitter();

  tagStatusImage: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status && changes.status.currentValue) {
      switch (this.status) {
        case TagStatus.ERROR: this.tagStatusImage = 'assets/icon/not-valid.svg'; break;
        case TagStatus.SUCCESS: this.tagStatusImage = 'assets/icon/valid.svg'; break;
        case TagStatus.CLOSE: this.tagStatusImage = 'assets/icon/close-round.svg'; break;
      }
    }
  }

}

export enum TagType {
  BOOKED = 'booked',
  GREEN = 'green',
  ORANGE = 'orange',
  BLUE = 'blue',
  RED = 'red',
  TRANSPARENT = 'transparent',
  CONFIRMED = 'confirmed',
  PAID = 'paid',
  PRERESERVATION = 'prereservation',
  DEPROPRIETAIRE = 'deproprietaire',
  UNDISPO = 'undispo'
}

export enum TagStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  CLOSE = 'close'
}
