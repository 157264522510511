import { IntercomSettings } from '../../models/intercom/IntercomSettings';
import * as i0 from "@angular/core";
import * as i1 from "ng-intercom";
export class IntercomService {
    constructor(intercom) {
        this.intercom = intercom;
        this.isIntercomOpen = false;
    }
    init() {
        this.settings = new IntercomSettings(window["intercomAppId"]);
        this.settings.hide_default_launcher = false; // hide on mobile
        this.intercom.boot(this.settings);
    }
    screenResize(isDesktopMode) {
        if (!isDesktopMode) {
            this.intercom.update({
                hide_default_launcher: true
            });
        }
        else {
            this.intercom.update({
                hide_default_launcher: true
            });
        }
    }
}
IntercomService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IntercomService_Factory() { return new IntercomService(i0.ɵɵinject(i1.Intercom)); }, token: IntercomService, providedIn: "root" });
