<footer
  id="footer"
  class="bg-dark text-white pb-5 pb-sm-0"
  itemscope=""
  itemtype="http://schema.org/Organization"
>
  <div class="container py-5 py-md-6">
    <div class="row mb-n3">
      <div class="col-12 col-md-4 mb-3">
        <h4>Contact</h4>
        <p>
          Nous serions ravis d'échanger avec vous, n'hésitez pas à nous
          contacter pour toute demande.
        </p>
      </div>
      <div
        class="d-none d-md-block col-12 col-md-5 mb-3"
        *ngIf="isDesktopMode()"
      >
        <div class="vcard">
          <h4 class="fn" itemprop="name">Address</h4>
          <div class="row">
            <div class="col-md-5">
              <p
                class="adr"
                itemprop="address"
                itemscope=""
                itemtype="http://schema.org/PostalAddress"
              >
                <span class="street-address" itemprop="streetAddress"
                  >Grand Est, de Metz à Luxembourg </span
                ><br />
                <span class="postal-code" itemprop="postalCode"></span>
                <span class="locality" itemprop="addressLocality">Metz</span
                ><br />

                <span class="country" itemprop="addressCountry">France</span>
              </p>
            </div>
            <div class="col-md-7">
              <div class="tel">
                <span class="type">Mobile</span>:
                <a href="tel:+33786113371" target="_blank"
                  ><span class="text-primary" itemprop="telephone"
                    >+33786113371</span
                  ></a
                >
              </div>

              <div class="email">
                <span class="type">Email</span>:
                <a href="mailto:contact@my-french-concierge.com" target="_blank"
                  ><span class="text-primary"
                    >contact@my-french-concierge.com</span
                  ></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-3">
        <h4>Navigation</h4>
        <ul class="menu-footer list-unstyled">
          <li class=" ">
            <a href="https://www.my-french-concierge.com/" target="_blank"
              >Accueil</a
            >
          </li>

          <li class=" ">
            <a
              href="https://www.my-french-concierge.com//fr/contact"
              target="_blank"
              >Contact</a
            >
          </li>

          <li class=" ">
            <a
              href="https://www.my-french-concierge.com//fr/nos-conditions-generales"
              target="_blank"
              >Nos conditions générales</a
            >
          </li>

          <li class=" ">
            <a
              href="https://www.my-french-concierge.com//fr/nous-gerons-pour-vous"
              target="_blank"
              >Nous gérons pour vous !</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <section id="footer-bottom">
    <div class="container">
      <hr class="border-secondary mt-0 mb-4" />
      <div
        class="d-flex flex-column flex-sm-row justify-content-between align-items-center"
      >
        <p class="d-flex flex-column text-center text-sm-left">
          <small class="mb-2"
            >© 2022 www.my-french-concierge.com. Tous droits réservés.</small
          >
        </p>

        <ul class="d-flex list-inline social mx-n2">
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/https://www.instagram.com/my.french.concierge/"
              title="Instagram"
            >
            <img src="assets/icon/instagram-french.svg" style="width: 15px;" >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</footer>
