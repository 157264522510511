import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Recommendation } from '../../models/guestview/Recommendation';
import { map } from 'rxjs/operators';
import { backEndComment } from '../../models/guestview/CommentForGuest';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../services/conf.service";
import * as i3 from "../services/language.service";
export class ApiService {
    constructor(http, confService, languageService) {
        this.http = http;
        this.confService = confService;
        this.languageService = languageService;
    }
    static getHeaders() {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    }
    static getOptionsWithParams(httpParams) {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: httpParams };
    }
    static getHeadersForUpload() {
        return { headers: new HttpHeaders() };
    }
    getGuestBooking(bookingID, lang) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('lang', lang);
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/guest/' + bookingID, ApiService.getOptionsWithParams(httpParams));
    }
    getPropertyAndBookingForGuest(bookingID, lang) {
        let httpParams = new HttpParams();
        if (lang) {
            httpParams = httpParams.set('lang', lang);
        }
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/guest/propertyAndBookingForGuest/' + bookingID, ApiService.getOptionsWithParams(httpParams));
    }
    getPropertyAndBookingForGuestCheck(propertyBookingId, type) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/guest/propertyAndBookingForGuest/check/' + type + '/' + propertyBookingId);
    }
    closeDisplayWords(bookingId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/guest/propertyAndBookingForGuest/displayWords/' + bookingId, ApiService.getHeaders());
    }
    saveBooking(bookingID, booking) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/guest/confirmGuestInformations/' + bookingID, booking, ApiService.getHeaders());
    }
    savePropertyRating(bookingID, propertyRating) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/guest/ratingproperty/' + bookingID, propertyRating);
    }
    savePropertyRatingLivretSeul(propertyRating) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/property-rating', propertyRating);
    }
    saveTeammateRating(bookingID, teammateRating) {
        return this.http.put(this.confService.getBackendApiEndPoint() + 'api-v2/guest/ratingteammate/' + bookingID, teammateRating, ApiService.getHeaders());
    }
    getForceMajeurs(lang) {
        let httpParams = new HttpParams();
        if (lang) {
            httpParams = httpParams.set('lang', lang);
        }
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/hygiene', ApiService.getOptionsWithParams(httpParams));
    }
    likeRecommendation(bookingId, recommendationId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/guest/recommendation/like/' + recommendationId + '/' + bookingId, ApiService.getHeaders());
    }
    unlikeRecommendation(bookingId, recommendationId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/guest/recommendation/unlike/' + recommendationId + '/' + bookingId, ApiService.getHeaders());
    }
    getRecommendations(query, lang, personId, corporateId) {
        let httpParams = new HttpParams();
        if (lang) {
            httpParams = httpParams.set('lang', lang);
        }
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/recommendation/search', query, ApiService.getOptionsWithParams(httpParams))
            .pipe(map(res => {
            return res.result.map(rec => {
                return new Recommendation(rec, this.languageService.getLanguageInUrl(), personId, corporateId);
            });
        }, err => {
        }));
    }
    getTranslatedReviews(recommendationId, lang) {
        let httpParams = new HttpParams();
        if (lang) {
            httpParams = httpParams.set('lang', lang);
        }
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/guest/recommendation/comments/' + recommendationId, ApiService.getOptionsWithParams(httpParams));
    }
    getTranslatedComment(requestForTranslation) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/recommendation/comments', requestForTranslation, ApiService.getHeaders());
    }
    getTags() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/tags', ApiService.getHeaders());
    }
    getEmptyHeadersForUpload() {
        return { headers: new HttpHeaders() };
    }
    guestInventoryCheck(type, check, bookingId) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/inventory/' + type + '/' + bookingId, check);
    }
    sendMessage(bookingId, message) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/messages/direct/' + bookingId, message);
    }
    getMessages(bookingId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/messages/thread/' + bookingId);
    }
    leaveBookletReviews(bookingId, bookletReviewLight) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/booklet/reviews/' + bookingId, bookletReviewLight);
    }
    getUpsales(bookingID, lang) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('lang', lang);
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/upsale/' + bookingID, ApiService.getOptionsWithParams(httpParams));
    }
    saveGuestOrder(bookingId, guestOrder) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/upsale/' + bookingId, guestOrder);
    }
    getCheckoutUrl(guestOrderId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/upsale/getcheckout/' + guestOrderId);
    }
    getCheckPassword(checkPassword) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/guest/pwd', checkPassword);
    }
    getLanguages() {
        return this.http.get('/api/webconfiguration/getlanguages').pipe(map(response => response));
    }
    saveOnlineCheckin(onlineCheckin) {
        return this.http.post(this.confService.getBackendApiEndPoint() + 'api-v2/onlinecheck', onlineCheckin);
    }
    createContract(bookingId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/onlinecheck/' + bookingId);
    }
    getPropertyByPlatformAndReference(platformName, reference) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/widget/property/' + platformName + '/' + reference);
    }
    getCorporate(corporateId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/corporate/' + corporateId);
    }
    getPropertyAndBookingForGuestWelcomeSeen(propertyBookingId) {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/guest/propertyAndBookingForGuest/welcome-seen/' + propertyBookingId);
    }
    sendGetUrl(urlToGet) {
        return this.http.get(urlToGet);
    }
    getRteSignal() {
        return this.http.get(this.confService.getBackendApiEndPoint() + 'api-v2/rte/signals');
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfService), i0.ɵɵinject(i3.LanguageManagementService)); }, token: ApiService, providedIn: "root" });
export class RequestForTranslation {
}
