import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../http/api.service";
export class GuestService {
    constructor(api) {
        this.api = api;
        this.isMap$ = new BehaviorSubject({ value: null, property: null, corporate: null });
        this.cardDetails$ = new BehaviorSubject({ recommendation: null, property: null });
        this.seeAllReviews$ = new BehaviorSubject({ reviews: null, title: null });
        this.property$ = new BehaviorSubject(null);
        this.booking$ = new BehaviorSubject(null);
        this.miniCardDetails$ = new BehaviorSubject(null);
        this.corporate$ = new BehaviorSubject(null);
        this.retOpen$ = new BehaviorSubject(false);
        this.upsaleCategoryAndProductDtos = [];
    }
    openRet(open) {
        this.retOpen$.next(open);
    }
    getRet() {
        return this.retOpen$.asObservable();
    }
    openMiniCard(recommendation, property) {
        this.miniCardDetails$.next({ recommendation, property });
    }
    closeMiniCard() {
        this.miniCardDetails$.next(null);
    }
    getMiniCard() {
        return this.miniCardDetails$.asObservable();
    }
    showMap(value, property, corporate) {
        this.isMap$.next({ value, property, corporate });
    }
    closeMap() {
        this.isMap$.next({ value: false, property: null, corporate: null });
    }
    getStatusOfMap() {
        return this.isMap$.asObservable();
    }
    openCard(recommendation, property) {
        this.cardDetails$.next({ recommendation, property });
    }
    closeCard() {
        this.cardDetails$.next({ recommendation: null, property: null });
    }
    getStatusOfCard() {
        return this.cardDetails$.asObservable();
    }
    openReviews(reviews, title) {
        this.seeAllReviews$.next({ reviews, title });
    }
    closeReviews() {
        this.seeAllReviews$.next({ reviews: null, title: null });
    }
    getStatusOfReviews() {
        return this.seeAllReviews$.asObservable();
    }
    sendProperty(property) {
        this.property$.next(property);
    }
    getProperty() {
        return this.property$.asObservable();
    }
    sendBooking(booking) {
        this.booking$.next(booking);
    }
    getBooking() {
        return this.booking$.asObservable();
    }
    sendupsaleCategoryAndProductDtos(upsales) {
        this.upsaleCategoryAndProductDtos = upsales;
    }
    getupsaleCategoryAndProductDtos() {
        return this.upsaleCategoryAndProductDtos;
    }
    sendCorporate(corporate) {
        this.corporate$.next(corporate);
    }
    getCorporate() {
        return this.corporate$.asObservable();
    }
    getGuestBooking(bookingID, lang) {
        return this.api.getGuestBooking(bookingID, lang);
    }
    getPropertyAndBookingForGuest(bookingID, lang) {
        return this.api.getPropertyAndBookingForGuest(bookingID, lang);
    }
    getPropertyAndBookingForGuestCheck(propertyBookingId, type) {
        return this.api.getPropertyAndBookingForGuestCheck(propertyBookingId, type);
    }
    closeDisplayWords(bookingId) {
        return this.api.closeDisplayWords(bookingId);
    }
    saveBooking(bookingID, booking) {
        return this.api.saveBooking(bookingID, booking);
    }
    getListForceMajeurs(lang) {
        return this.api.getForceMajeurs(lang);
    }
    getRecommendations(query, lang, personId, corporateId) {
        return this.api.getRecommendations(query, lang, personId, corporateId);
    }
    likeRecommendation(bookingId, recommendationId) {
        return this.api.likeRecommendation(bookingId, recommendationId);
    }
    unlikeRecommendation(bookingId, recommendationId) {
        return this.api.unlikeRecommendation(bookingId, recommendationId);
    }
    getReviewsTranslated(recommendationId, lang) {
        return this.api.getTranslatedReviews(recommendationId, lang);
    }
    getTags() {
        return this.api.getTags();
    }
    getPropertyAndBookingForGuestWelcomeSeen(propertyBookingId) {
        return this.api.getPropertyAndBookingForGuestWelcomeSeen(propertyBookingId);
    }
    getClusterStyle() {
        return [
            {
                textColor: 'white',
                url: './assets/icon/recommendation/marker-clustered.svg',
                height: 70,
                width: 70,
                textSize: 15
            },
            {
                textColor: 'white',
                url: './assets/icon/recommendation/marker-clustered.svg',
                height: 70,
                width: 70,
                textSize: 15
            },
            {
                textColor: 'white',
                url: './assets/icon/recommendation/marker-clustered.svg',
                height: 70,
                width: 70,
                textSize: 15
            },
            {
                textColor: 'white',
                url: './assets/icon/recommendation/marker-clustered.svg',
                height: 70,
                width: 70,
                textSize: 15
            },
            {
                textColor: 'white',
                url: './assets/icon/recommendation/marker-clustered.svg',
                height: 70,
                width: 70,
                textSize: 15
            }
        ];
    }
}
GuestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuestService_Factory() { return new GuestService(i0.ɵɵinject(i1.ApiService)); }, token: GuestService, providedIn: "root" });
