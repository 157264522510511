/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./y3-explained-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./y3-explained-button.component";
var styles_Y3ExplainedButtonComponent = [i0.styles];
var RenderType_Y3ExplainedButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Y3ExplainedButtonComponent, data: {} });
export { RenderType_Y3ExplainedButtonComponent as RenderType_Y3ExplainedButtonComponent };
export function View_Y3ExplainedButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-explained-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "y3-flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "y3-explained-button-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "y3-explained-button-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["src", "./assets/icon/icon-arrow-right-black.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.description; _ck(_v, 5, 0, currVal_1); }); }
export function View_Y3ExplainedButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-y3-explained-button", [], null, null, null, View_Y3ExplainedButtonComponent_0, RenderType_Y3ExplainedButtonComponent)), i1.ɵdid(1, 114688, null, 0, i2.Y3ExplainedButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Y3ExplainedButtonComponentNgFactory = i1.ɵccf("app-y3-explained-button", i2.Y3ExplainedButtonComponent, View_Y3ExplainedButtonComponent_Host_0, { title: "title", description: "description" }, {}, []);
export { Y3ExplainedButtonComponentNgFactory as Y3ExplainedButtonComponentNgFactory };
