/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-property-gallery-photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../guest-gallery-detail-mobile/guest-gallery-detail-mobile.component.ngfactory";
import * as i3 from "../../../guest-gallery-detail-mobile/guest-gallery-detail-mobile.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./guest-property-gallery-photo.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../core/services/guest.service";
import * as i10 from "../../../../../core/services/language.service";
var styles_GuestPropertyGalleryPhotoComponent = [i0.styles];
var RenderType_GuestPropertyGalleryPhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestPropertyGalleryPhotoComponent, data: {} });
export { RenderType_GuestPropertyGalleryPhotoComponent as RenderType_GuestPropertyGalleryPhotoComponent };
function View_GuestPropertyGalleryPhotoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-gallery-detail-mobile", [], null, null, null, i2.View_GuestGalleryDetailMobileComponent_0, i2.RenderType_GuestGalleryDetailMobileComponent)), i1.ɵdid(1, 114688, null, 0, i3.GuestGalleryDetailMobileComponent, [i4.MatDialog], { property: [0, "property"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GuestPropertyGalleryPhotoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "main-container-useful  guest-view-mobile-section-container detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "img", [["alt", "back icon"], ["class", "back-icon"], ["src", "../../../assets/icon/backbutton.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Photo Gallery"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestPropertyGalleryPhotoComponent_1)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "/guest", _co.bookingId); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.property; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_GuestPropertyGalleryPhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-property-gallery-photo", [], null, null, null, View_GuestPropertyGalleryPhotoComponent_0, RenderType_GuestPropertyGalleryPhotoComponent)), i1.ɵdid(1, 245760, null, 0, i7.GuestPropertyGalleryPhotoComponent, [i5.Router, i5.ActivatedRoute, i8.TranslateService, i9.GuestService, i10.LanguageManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestPropertyGalleryPhotoComponentNgFactory = i1.ɵccf("app-guest-property-gallery-photo", i7.GuestPropertyGalleryPhotoComponent, View_GuestPropertyGalleryPhotoComponent_Host_0, {}, {}, []);
export { GuestPropertyGalleryPhotoComponentNgFactory as GuestPropertyGalleryPhotoComponentNgFactory };
