import { Routes } from '@angular/router';
const ɵ0 = () => import("./modules/guest/guest.module.ngfactory").then(mod => mod.GuestModuleNgFactory), ɵ1 = () => import("./modules/onlinecheck/onlinecheck.module.ngfactory").then(mod => mod.OnlinecheckModuleNgFactory), ɵ2 = () => import("./modules/guest/guest.module.ngfactory").then(mod => mod.GuestModuleNgFactory), ɵ3 = () => import("./modules/misc/misc.module.ngfactory").then(mod => mod.MiscModuleNgFactory), ɵ4 = () => import("./modules/password/password.module.ngfactory").then(mod => mod.PasswordModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'misc',
        pathMatch: 'full'
    },
    {
        path: 'guest',
        loadChildren: ɵ0
    },
    {
        path: 'onlinecheck',
        loadChildren: ɵ1
    },
    {
        path: 'rental',
        loadChildren: ɵ2
    },
    {
        path: 'misc',
        loadChildren: ɵ3
    },
    {
        path: 'pwd',
        loadChildren: ɵ4
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
