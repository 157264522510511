import * as i0 from "@angular/core";
import * as i1 from "@ngx-config/core";
export class ConfService {
    constructor(config) {
        this.config = config;
    }
    getBackendApiEndPoint() {
        // return 'http://localhost:8080/';
        return 'https://guest.yaago.com/';
        /*try {
          const  url: string = this.config.getSettings('system.apiEndPoint');
          if (url == null || url.trim() === '') {
            return window.location.origin;
          } else {
            return url;
          }
        } catch (exception) {
          console.log(exception);
          return window.location.origin;
        }*/
    }
}
ConfService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfService_Factory() { return new ConfService(i0.ɵɵinject(i1.ConfigService)); }, token: ConfService, providedIn: "root" });
