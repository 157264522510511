/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-platform-services.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./yaagov2-services-for-guests/yaagov2-services-for-guests.component.ngfactory";
import * as i3 from "./yaagov2-services-for-guests/yaagov2-services-for-guests.component";
import * as i4 from "./guest-platform-services.component";
import * as i5 from "../../../core/services/utils.service";
var styles_GuestPlatformServicesComponent = [i0.styles];
var RenderType_GuestPlatformServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestPlatformServicesComponent, data: {} });
export { RenderType_GuestPlatformServicesComponent as RenderType_GuestPlatformServicesComponent };
export function View_GuestPlatformServicesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mytmpl: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-yaagov2-services-for-guests", [], null, null, null, i2.View_Yaagov2ServicesForGuestsComponent_0, i2.RenderType_Yaagov2ServicesForGuestsComponent)), i1.ɵdid(2, 114688, null, 0, i3.Yaagov2ServicesForGuestsComponent, [], { platformServices: [0, "platformServices"], property: [1, "property"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.platformServices; var currVal_1 = _co.property; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_GuestPlatformServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-platform-services", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestPlatformServicesComponent_0, RenderType_GuestPlatformServicesComponent)), i1.ɵdid(1, 114688, null, 0, i4.GuestPlatformServicesComponent, [i5.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestPlatformServicesComponentNgFactory = i1.ɵccf("app-guest-platform-services", i4.GuestPlatformServicesComponent, View_GuestPlatformServicesComponent_Host_0, { platformServices: "platformServices", property: "property" }, {}, []);
export { GuestPlatformServicesComponentNgFactory as GuestPlatformServicesComponentNgFactory };
