/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tabs-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../directives/debounce-click.directive";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./tabs-menu.component";
var styles_TabsMenuComponent = [i0.styles];
var RenderType_TabsMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabsMenuComponent, data: {} });
export { RenderType_TabsMenuComponent as RenderType_TabsMenuComponent };
function View_TabsMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["appDebounceClick", ""], ["class", "clickable"]], null, [[null, "debounceClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).clickEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("debounceClick" === en)) {
        var pd_1 = (_co.onClick(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "selected": 0 }), i1.ɵdid(4, 212992, null, 0, i3.DebounceClickDirective, [], null, { debounceClick: "debounceClick" }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(7, 0, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(9, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "clickable"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit === _co.selectedTab)); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_v.context.$implicit.title)))); _ck(_v, 7, 0, currVal_4); }); }
export function View_TabsMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "grey-scroll-new"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsMenuComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_TabsMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tabs-menu", [], null, null, null, View_TabsMenuComponent_0, RenderType_TabsMenuComponent)), i1.ɵdid(1, 638976, null, 0, i9.TabsMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabsMenuComponentNgFactory = i1.ɵccf("app-tabs-menu", i9.TabsMenuComponent, View_TabsMenuComponent_Host_0, { tabs: "tabs" }, { selected: "selected" }, []);
export { TabsMenuComponentNgFactory as TabsMenuComponentNgFactory };
