/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-hygiene.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./yaagov2-hygiene/yaagov2-hygiene.component.ngfactory";
import * as i3 from "./yaagov2-hygiene/yaagov2-hygiene.component";
import * as i4 from "../../../core/services/IntercomService";
import * as i5 from "../../../core/services/utils.service";
import * as i6 from "../../../core/services/guest.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../core/services/language.service";
import * as i9 from "../../../core/services/internal.property.service";
import * as i10 from "@angular/common";
import * as i11 from "./guest-hygiene.component";
var styles_GuestHygieneComponent = [i0.styles];
var RenderType_GuestHygieneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestHygieneComponent, data: {} });
export { RenderType_GuestHygieneComponent as RenderType_GuestHygieneComponent };
function View_GuestHygieneComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-yaagov2-hygiene", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Yaagov2HygieneComponent_0, i2.RenderType_Yaagov2HygieneComponent)), i1.ɵdid(1, 114688, null, 0, i3.Yaagov2HygieneComponent, [i4.IntercomService, i5.UtilsService, i6.GuestService, i7.TranslateService, i8.LanguageManagementService, i9.InternalPropertyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_GuestHygieneComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mytmpl: 0 }), i1.ɵqud(671088640, 2, { masonry: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestHygieneComponent_1)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.property == null) ? null : _co.property.forceMajeureItems.length) > 0); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_GuestHygieneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-hygiene", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestHygieneComponent_0, RenderType_GuestHygieneComponent)), i1.ɵdid(1, 245760, null, 0, i11.GuestHygieneComponent, [i6.GuestService, i5.UtilsService, i7.TranslateService, i9.InternalPropertyService, i4.IntercomService, i8.LanguageManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestHygieneComponentNgFactory = i1.ɵccf("app-guest-hygiene", i11.GuestHygieneComponent, View_GuestHygieneComponent_Host_0, {}, {}, []);
export { GuestHygieneComponentNgFactory as GuestHygieneComponentNgFactory };
