export class GuestBookingInformationConfirmationDto {

  expectedCheckinLocalTime: string;
  requestedCheckinLocalTime: string;
  expectedCheckoutLocalTime: string;
  requestedCheckoutLocalTime: string;


  adults: number;
  children: number;
  babies: number;
  doubleBeds: number;
  singleBeds: number;
  sofaBeds: number;
  babyBeds: number;
  pets: number;

  guestNote: string;
  firstName: string;
  companyName: string;
  lastName: string;
  phone: string;
  secondaryPhone: string;
  email: string;
  secondaryEmail: string;

  travelForBusiness: boolean;

}
