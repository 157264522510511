/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./yaago2-yaago-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./yaago2-yaago-footer.component";
import * as i5 from "../../../core/services/IntercomService";
import * as i6 from "../../../core/services/utils.service";
var styles_Yaago2YaagoFooterComponent = [i0.styles];
var RenderType_Yaago2YaagoFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Yaago2YaagoFooterComponent, data: {} });
export { RenderType_Yaago2YaagoFooterComponent as RenderType_Yaago2YaagoFooterComponent };
function View_Yaago2YaagoFooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "content-container common-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "text-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpod(3, { date: 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["href", "https://yaago.com/fr/documentation-legale"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "text-right clickable"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform("footer.copyrights", _ck(_v, 3, 0, _co.year))); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("footer.legal")); _ck(_v, 7, 0, currVal_1); }); }
function View_Yaago2YaagoFooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "common-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "flex-row justify-content-space-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "flex-row justify-content-center align-center flex1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["href", "https://yaago.com/fr/documentation-legale"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "clickable"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "flex-row justify-content-center align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("footer.legal")); _ck(_v, 5, 0, currVal_0); var currVal_1 = (("\u00A9" + _co.year) + i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("footer.copyrights"))); _ck(_v, 9, 0, currVal_1); }); }
export function View_Yaago2YaagoFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "section", [["class", "footer-area2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "vogue-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Yaago2YaagoFooterComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Yaago2YaagoFooterComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDesktopMode(); _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.isDesktopMode(); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_Yaago2YaagoFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-yaago2-yaago-footer", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_Yaago2YaagoFooterComponent_0, RenderType_Yaago2YaagoFooterComponent)), i1.ɵdid(1, 114688, null, 0, i4.Yaago2YaagoFooterComponent, [i5.IntercomService, i6.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Yaago2YaagoFooterComponentNgFactory = i1.ɵccf("app-yaago2-yaago-footer", i4.Yaago2YaagoFooterComponent, View_Yaago2YaagoFooterComponent_Host_0, {}, {}, []);
export { Yaago2YaagoFooterComponentNgFactory as Yaago2YaagoFooterComponentNgFactory };
