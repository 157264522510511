import {Component, Input, OnInit} from '@angular/core';
import {Corporate} from '../../../models/guestview/Corporate';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {IntercomService} from '../../../core/services/IntercomService';
import {UtilsService} from '../../../core/services/utils.service';
import {ActivatedRoute} from '@angular/router';
import {LanguageManagementService} from '../../../core/services/language.service';
import {GuestService} from '../../../core/services/guest.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {LoaderService} from '../../../core/services/LoaderService';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-y3-header',
  templateUrl: './y3-header.component.html',
  styleUrls: ['./y3-header.component.scss']
})
export class Y3HeaderComponent extends AbstractWindow implements OnInit {

  @Input() corporate: Corporate;
  @Input() booking: BookingForGuest;
  @Input() property: PropertyForGuest;
  @Input() displayChat = true;

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              protected dialog: MatDialog) {
    super(intercomService, utilsService);
  }

  ngOnInit() {
  }

}
