<div class="y3-divider {{(curIndex%2===0)?'y3-silver':'y3-white'}}">
  <div class="y3-container " >

    <div *ngIf="!section" class="y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24">
      {{'guest-guid-page.title' | translate}}
    </div>
    <div *ngIf="section" class="y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24">
      {{section.sectionTitle}}
    </div>
    <!--<div class="y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-responsive y3-flex-justify-content-center ">
      <div style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{'guest-guid-page.btob-title' | translate }}" [type]="'deproprietaire'"></app-tag>
      </div>
    </div>-->

    <div>

      <div class="width-100 margin-top-44 ">
        <app-tabs-menu  [tabs]="categoryTabs" (selected)="onTabMenuSelected($event)"></app-tabs-menu>
      </div>

      <!--<mat-form-field class="pr-1" appearance="fill" style="width: 250px;">
        <mat-label>{{'guest-guid-page.select-category' | translate}} </mat-label>
        <mat-select [ngModelOptions]="{standalone: true}" [ngModel]="currentSelectedCategory" (selectionChange)="categorySelectionChange($event.value)" (ngModelChange)="currentSelectedCategory"  >
          <mat-option *ngFor="let cat of categoryGuides"  [value]="cat.category" style="display: flex; flex-flow: row;align-items: center">
            <span *ngIf="!cat.custom"> {{'guides-list.' + cat.category | translate}}</span>
            <span *ngIf="cat.custom"> {{ cat.category | translate}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>-->
    </div>

    <div class="y3-flex-responsive y3-flex-row-wrap y3-margin-top-16" style="min-height: 240px">
      <div *ngFor="let guide of currentGuidesList; let i = index">
        <div class="y3-guide-bloc y3-clickable" (click)="showGuide(guide)" *ngIf="needToShowGuide(guide)">
          <img class="y3-important-guide-picture" src="{{getGuideCover(guide)}}" alt="guide">
          <div class="y3-text-guide-bloc y3-bold"><span *ngIf="guide.category === 'wifi'">WIFI: </span> {{guide.translations[0].title |titlecase }}</div>
        </div>
      </div>
    </div>

  </div>
</div>
