
    <div class="page-container"
        [ngStyle]="{'background-image':  'url(&quot;' + (isDesktopMode()? homeSettings?.pictureDesktopUrl : homeSettings?.pictureMobileUrl) + '&quot;)' }">
        <div class="text-container">
            <img class="agencyLogo" src={{homeSettings?.agencyLogoUrl}}>
     
            <div [ngStyle]="{ 'color': homeSettings?.fontColor }" class="first-title">{{homeSettings?.agencyTitle}}</div>
            <div *ngIf="isDesktopMode()" class="contact">
                <div class="mail-position">
                    <!-- <img class="mail-width" src="/assets/icon/mail-white.svg"> -->
                 <mat-icon [ngStyle]="{ 'color': homeSettings?.fontColor }" class="mail-width">mail_outline</mat-icon>
                    <div class="contact-display">
                     
                        <a [ngStyle]="{ 'color': homeSettings?.fontColor }" href="mailto:{{propertyDetails?.host?.email}}">{{propertyDetails?.host?.email}}</a>
                    </div>
                </div>
                <div class="phone-position">
                    <mat-icon class="phone-width" [ngStyle]="{ 'color': homeSettings?.fontColor }">phone_in_talk</mat-icon>
                    <!-- <img [ngStyle]="{ 'color': homeSettings?.fontColor }" class="phone-width" src="/assets/icon/phone-call.svg"> -->
        
                    <div  class="contact-display">
                        <a [ngStyle]="{ 'color': homeSettings?.fontColor }" href="tel:{{propertyDetails?.host?.phone}}">{{propertyDetails?.host?.phone}}</a>
                    </div>
                </div>
            </div>
            <div *ngIf="!isDesktopMode()" class="contact">
                <div class="phone-position">
                    <a  href="tel:{{propertyDetails?.host?.phone}}">
                        <span [ngStyle]="{ 'color': homeSettings?.fontColor }" class="inner-circle">
                            <mat-icon  class="phone-width" [ngStyle]="{ 'color': homeSettings?.fontColor }">phone_in_talk</mat-icon>                  
                        </span>                     
                        <!-- <img class="phone-width" src="/assets/icon/white-phone.svg"> -->
                    </a>
                </div>
                    <div class="mail-position">
                    <a href="mailto:{{propertyDetails?.host?.email}}">
                        <span [ngStyle]="{ 'color': homeSettings?.fontColor }" class="inner-circle">
                            <mat-icon  class="phone-width" [ngStyle]="{ 'color': homeSettings?.fontColor }">mail_outline</mat-icon> 
                  
                        </span>
                    </a>
                </div>
                <!-- <div class="mail-position">
                    <a href="mailto:{{propertyDetails?.host?.email}}">
                        <img class="mail-width" src="/assets/icon/white-mail.svg">
                    </a>
                </div> -->
                
            </div>
            <hr [ngStyle]="{ 'border': '2px solid' + homeSettings?.fontColor }">
            <div [ngStyle]="{ 'color': homeSettings?.fontColor }" class="property-name">
                {{propertyDetails?.title}}
            </div>
            <div [ngStyle]="{ 'color': homeSettings?.fontColor }" class="adresse">
                {{propertyDetails?.fullAddress}}
            </div>
     <div style="padding-bottom: 100px;">
            <app-button (debounceClick)="navigateToLivret()" [type]="'primary'"
         text="{{'welcome-page.Access-to-booklet' | translate}} "></app-button>
        </div>
        </div>
    </div>
    <div class="width-100 blue-container">
        <span>{{'©' + year +' '+('welcome-page.copyrights' | translate) }}</span>
    </div>
