<div class="y3-divider">
  <div class="y3-container " >

    <div class="y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-responsive y3-flex-justify-content-center ">
      <div *ngIf="!section" style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{'guest-view-booking-details.booking-details' | translate}}" [type]="'deproprietaire'"></app-tag>
      </div>
      <div *ngIf="section" style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{section.sectionTitle}}" [type]="'deproprietaire'"></app-tag>

      </div>
    </div>

    <div>
      <p *ngIf="property?.description"  [innerHTML]="property.description"
         class="property-description roboto Medium txt-color-scorpion margin-top-16 margin-bottom-16
                   {{(property?.description && property?.description.length > 300 && !showingMoreDescription) ? 'description-max-height' : ''}}">
      </p>

      <span class="y3-roboto y3-Small y3-bold y3-underline txt-color-grvogue y3-flex-responsive y3-flex-justify-content-center y3-clickable y3-margin-top-8"
            *ngIf="property?.description && property?.description.length > 300"
            (click)="showingMoreDescription = !showingMoreDescription">
            {{(showingMoreDescription ? 'guest-view-booking-details.show-less' :'guest-view-booking-details.show-more') | translate }}
      </span>

      <app-audio-play *ngIf="this.property?.description && !property?.noAudioDisplay" [textToSpeak]="utilsService.getCleanTextToRead(property.description)"></app-audio-play>
    </div>


    <div class="y3-two-colum y3-margin-top-24">
      <div class="map-wrapper">
        <div class="map-container">
          <app-leaflet-map
            [mapId]="'y3-mapDetailDesktop'"
            [defaultZoom]="15"
            [centerOfTheMap]="centerOfTheMap"
            [corporate]="corporate"
            [propertyPoi]="propertyPoi"
            [whereIamPoi]=""
            [properties]=""
            [language]="language"
            [showAllProperties]="true"
            [showRecoCorporate]="true"
            [showProperty]="true"
            [showRecoYaago]="true"
            [showRecoInfluencer]="true"
            [showRecoTourism]="true"
            [showOwner]="true"
            [showFilterReco]="false"
            [recapVersion]="'v0'"
            [showZoomControl]="false"
            [showChecks]="false"
            [relatedPoiForDistanceCalculation]="propertyPoi"
          ></app-leaflet-map>
        </div>
        <!--<div #gMap class="map-container">
        </div>-->
        <div class="y3-flex-row y3-flex-justify-content-end">
        <div class="y3-booking-details-find">
          <app-button [text]="'guest-detail-booking.find-direction' | translate"
                      (debounceClick)="findDirection()"
          ></app-button></div>
        </div>

      </div>
      <div class="map-wrapper map-wrapper-2">

        <div *ngIf="localShowMeAsHost && !property?.host?.btobInfo && !corporate?.corporateTag" class="y3-margin-bottom-24  y3-roboto" >
          <app-tag text="{{(localShowMeAsHost ? 'guest-view-booking-details.your-co-host' : 'guest-view-booking-details.your-host') | translate}}" [type]="'deproprietaire'"></app-tag>
        </div>
        <div *ngIf="localShowMeAsHost && !property?.host?.btobInfo && corporate?.corporateTag" class="y3-margin-bottom-24 y3-roboto" >
          <app-tag  text="  {{('guest-view-booking-details.your-host') | translate}}" [type]="'deproprietaire'"></app-tag>

        </div>

        <div *ngIf="localShowMeAsHost" class="card y3-padding-24 person-container {{localCoHostInCharge ? 'margin-bottom-32' : ''}}">
          <div class="picture-container">
            <img *ngIf="property?.host?.pictureUrl" src="{{property?.host.pictureUrl}}" alt="picture">
            <span *ngIf="!property?.host?.pictureUrl" class="roboto Large picture-placeholder">
              {{utilsService.getFirstLetter(property?.host)}}
            </span>
            <div class="language-container margin-top-8">
              <ng-template [ngIf]="property?.host?.languages && property.host.languages.length <= 2">
                <img *ngFor="let languageCode of property?.host?.languages" class="margin-right-8 margin-top-8" src="{{ getLanguage(languageCode) }}" alt="flags">
              </ng-template>
              <ng-template [ngIf]="property?.host?.languages && property.host.languages.length > 2">
                <app-tooltip [position]="'right'">
                  <div class="flex-row flex-align-center" placeholder>
                    <img style="width: 17px; height: 17px;" src="/assets/icon/icon_world.svg" alt="world">
                    <span class="roboto Medium bold txt-color-grvogue margin-left-4">({{property.host?.languages?.length}})</span>
                  </div>
                  <div class="flex-row flex-align-center flex-justify-center" content>
                    <img *ngFor="let languageCode of property.host?.languages" class="margin-left-12 margin-right-12 " src="{{ getLanguage(languageCode) }}" alt="flags">
                  </div>
                </app-tooltip>
              </ng-template>
            </div>
          </div>
          <div class="infos-container y3-margin-left-24">
            <span class="y3-roboto y3-Large y3-txt-color-grvogue">{{property?.host?.firstName | titlecase}} {{property?.host?.lastName | titlecase}}</span>
            <p class="y3-margin-top-16 y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property?.host?.email"
               (click)="emailPerson(property?.host)">
              <img class="y3-margin-right-8" src="/assets/icon/icon_message.svg" alt="mail">
              {{property?.host?.email}}
            </p>

            <!--<a class="margin-top-4 roboto X-small txt-color-dimgray" *ngIf="property?.host?.whatsAppLink"
               href="{{property?.host?.whatsAppLink}}" target="_blank">
              <img class="margin-right-8" style="height: 16px;" src="/assets/icon/icon-whatsapp.svg" alt="whatsapp">
              <span style="text-decoration: underline; font-weight: bold; color: #03224C">WhatsApp Chat</span>
            </a>-->

            <p class="y3-margin-top-4 y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property?.host?.phone"
               (click)="call(property?.host)">
              <img class="y3-margin-right-8" src="/assets/icon/icon_tel.svg" alt="phone">
              {{property?.host?.phone}}
            </p>

            <div class="media-container">


              <a class="y3-margin-top-4 y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property?.host?.whatsAppLink"
                 href="{{property?.host?.whatsAppLink}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img  class="icon-social" src="assets/icon/whatsapp_icon.png">
                  <div>{{property?.host?.whatsAppLink}}</div>
                </div>
              </a>

              <a class="y3-margin-top-4 y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="corporate?.instagramUrl"
                 href="{{corporate?.instagramUrl}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img  class="icon-social" src="assets/icon/Instagram_icon.png">
                  <div>{{corporate?.instagramUrl}}</div>
                </div>
              </a>


              <a class="y3-margin-top-4 y3-flex-align-center y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="corporate?.linkedInUrl"
                 href="{{corporate?.linkedInUrl}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img  class="icon-social" src="assets/icon/linkedin.png">
                  <div style="text-decoration: underline; font-weight: bold; color: #03224C">{{corporate?.linkedInUrl}}</div>
                </div>
              </a>


              <a class="y3-margin-top-4 y3-flex-align-center y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="corporate?.facebookUrl"
                 href="{{corporate?.facebookUrl}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img class="icon-social" src="assets/icon/facebook_icon.png">
                  <div>{{corporate?.facebookUrl}}</div>
                </div>
              </a>


              <a class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="corporate?.webSiteUrl"
                 href="{{corporate?.webSiteUrl}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img class="icon-social" src="assets/icon/icon-web-sm.svg">
                  <div style="text-decoration: underline; font-weight: bold; color: #03224C">{{corporate?.webSiteUrl}}</div>
                </div>
              </a>

            </div>

            <div>

            </div>


          </div>
        </div>


        <!--CO-HOST IN CHARGE-INFOS-->
        <div *ngIf="localCoHostInCharge && localCoHostInCharge.email != null && !property?.host?.btobInfo && !corporate?.corporateTag" class="y3-margin-bottom-24  y3-roboto" >
            <app-tag text="{{(localShowMeAsHost ? 'guest-view-booking-details.your-co-host' : 'guest-view-booking-details.your-host') | translate}}" [type]="'deproprietaire'"></app-tag>
        </div>
        <div *ngIf="localCoHostInCharge && localCoHostInCharge.email != null && !property?.host?.btobInfo && corporate?.corporateTag" class="y3-margin-bottom-24 y3-roboto" >
          <app-tag  text="  {{(localShowMeAsHost ? 'guest-view-booking-details.your-co-host' : 'guest-view-booking-details.your-agency') | translate}}" [type]="'deproprietaire'"></app-tag>

        </div>


        <div *ngIf="localCoHostInCharge && localCoHostInCharge?.email != null" class="card y3-padding-24 person-container">
          <div class="picture-container">
            <img *ngIf="localCoHostInCharge?.pictureUrl" src="{{localCoHostInCharge.pictureUrl}}" alt="picture">
            <span *ngIf="!localCoHostInCharge?.pictureUrl" class="y3-roboto y3-Large picture-placeholder">
              {{utilsService.getFirstLetter(localCoHostInCharge)}}
            </span>
            <div class="language-container y3-margin-top-8">
              <ng-template [ngIf]="localCoHostInCharge?.languages && localCoHostInCharge.languages.length <= 2">
                <img *ngFor="let languageCode of localCoHostInCharge?.languages" class="margin-right-8 margin-top-8" src="{{ getLanguage(languageCode) }}" alt="flags">
              </ng-template>
              <ng-template [ngIf]="localCoHostInCharge?.languages && localCoHostInCharge.languages.length > 2">
                <app-tooltip [position]="'right'">
                  <div class="flex-row flex-align-center" placeholder>
                    <img style="width: 17px; height: 17px;" src="/assets/icon/icon_world.svg" alt="world">
                    <span class="y3-roboto y3-Medium y3-bold txt-color-grvogue y3-margin-left-4">({{localCoHostInCharge?.languages?.length}})</span>
                  </div>
                  <div class="flex-row flex-align-center y3-flex-justify-center" content>
                    <img *ngFor="let languageCode of localCoHostInCharge?.languages" class="margin-left-12 margin-right-12 " src="{{ getLanguage(languageCode) }}" alt="flags">
                  </div>
                </app-tooltip>
              </ng-template>
            </div>
          </div>
          <div class="infos-container y3-margin-left-24">
            <span class="y3-roboto y3-Large txt-color-grvogue">{{localCoHostInCharge?.firstName }} {{localCoHostInCharge?.lastName }}</span>
            <p class="y3-margin-top-16 y3-roboto y3-X-small txt-color-dimgray" *ngIf="localCoHostInCharge?.email"
               (click)="emailPerson(localCoHostInCharge)">
              <img class="y3-margin-right-8" src="/assets/icon/icon_message.svg" alt="mail">
              {{localCoHostInCharge?.email}}
            </p>

            <a class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="localCoHostInCharge?.whatsAppLink"
               href="{{localCoHostInCharge?.whatsAppLink}}" target="_blank">
              <img class="margin-right-8" style="height: 16px;" src="/assets/icon/icon-whatsapp.svg" alt="whatsapp">
              <span style="text-decoration: underline; font-weight: bold; color: #03224C">WhatsApp Chat</span>
            </a>

            <p class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="localCoHostInCharge?.phoneNumber"
               (click)="callTeammate(localCoHostInCharge)">
              <img class="y3-margin-right-8" src="/assets/icon/icon_tel.svg" alt="phone">
              {{localCoHostInCharge?.phoneNumber}} <span *ngIf="localCoHostInCharge?.phoneUrgencyOnly">&nbsp; {{'guest-view-booking-details.urgency-only' | translate}}</span>
            </p>

            <div class="media-container">


              <a class="y3-margin-top-4 y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="property?.host?.whatsAppLink"
                 href="{{property?.host?.whatsAppLink}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img  class="icon-social" src="assets/icon/whatsapp_icon.png">
                  <div>{{property?.host?.whatsAppLink}}</div>
                </div>
              </a>

              <a class="y3-margin-top-4 y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="corporate?.instagramUrl"
                 href="{{corporate?.instagramUrl}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img  class="icon-social" src="assets/icon/Instagram_icon.png">
                  <div>{{corporate?.instagramUrl}}</div>
                </div>
              </a>


              <a class="y3-margin-top-4 y3-flex-align-center y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="corporate?.linkedInUrl"
                 href="{{corporate?.linkedInUrl}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img  class="icon-social" src="assets/icon/linkedin.png">
                  <div style="text-decoration: underline; font-weight: bold; color: #03224C">{{corporate?.linkedInUrl}}</div>
                </div>
              </a>


              <a class="y3-margin-top-4 y3-flex-align-center y3-roboto y3-X-small y3-txt-color-dimgray" *ngIf="corporate?.facebookUrl"
                 href="{{corporate?.facebookUrl}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img class="icon-social" src="assets/icon/facebook_icon.png">
                  <div>{{corporate?.facebookUrl}}</div>
                </div>
              </a>


              <a class="y3-margin-top-4 y3-roboto y3-X-small txt-color-dimgray" *ngIf="corporate?.webSiteUrl"
                 href="{{corporate?.webSiteUrl}}" target="_blank">
                <div class="y3-flex-row y3-flex-align-center">
                  <img class="icon-social" src="assets/icon/icon-web-sm.svg">
                  <div style="text-decoration: underline; font-weight: bold; color: #03224C">{{corporate?.webSiteUrl}}</div>
                </div>
              </a>

            </div>
          </div>
        </div>

        <div *ngIf="(!property?.host?.btobInfo || !property?.noshowBedsDetail) && (property.doubleBeds + property.singleBeds + property.sofaBeds + property.bathrooms) >0  " class="y3-margin-top-24">
          <app-tag text="{{'guest-view-booking-details.disposition' | translate}}" [type]="'deproprietaire'"></app-tag>
        </div>

        <div *ngIf="(!property?.host?.btobInfo || !property?.noshowBedsDetail) && (property.doubleBeds + property.singleBeds + property.sofaBeds + property.bathrooms) >0  " class="card y3-flex-column y3-roboto y3-Small y3-margin-top-24">
          <div class="y3-margin-top-4" *ngIf="property.doubleBeds > 0">{{counter('doublebeds')}} {{counter('doublebeds') > 1 ? ('guest-detail-booking.doublebeds' | translate) : ('guest-detail-booking.doublebed' | translate) }}</div>
          <div class="y3-margin-top-4" *ngIf="property.singleBeds > 0">{{counter('singlebeds')}} {{counter('singlebeds') > 1 ? ('guest-detail-booking.singlebeds' | translate) : ('guest-detail-booking.singlebed' | translate)}}</div>
          <div class="y3-margin-top-4" *ngIf="property.sofaBeds > 0">{{counter('sofabeds')}} {{counter('sofabeds') > 1 ? ('guest-detail-booking.sofabeds' | translate) : ('guest-detail-booking.sofabed' | translate)}}</div>
          <div class="y3-margin-top-4" *ngIf="property.bathrooms > 0">{{counter('baths')}} {{counter('baths') > 1 ? ('guest-detail-booking.bathRooms' | translate) : ('guest-detail-booking.bathRoom' | translate)}}</div>
        </div>

      </div>
    </div>



  </div>
</div>
