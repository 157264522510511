<div class="y3-divider {{(curIndex%2===0)?'y3-silver':'y3-white'}}">
  <div class="y3-container " >

    <div class="y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-responsive y3-flex-justify-content-center ">
      <div style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{'booklet-review.avis' | translate}}" [type]="'deproprietaire'"></app-tag>
      </div>
    </div>

    <div class="y2021-h3-subtitle">{{'booklet-review.avis-subtitle' | translate}} </div>

    <div class="range-box y3-margin-top-16">
      <app-star-rating class="stars" [(rating)]="rating"></app-star-rating>
      <div class="value">{{rating}}</div>
    </div>

    <div class="y3-flex-responsive y3-margin-top-16 div-button">
      <div class="textarea-for-review">
        <textarea style="width: 100%" rows="10" [(ngModel)]="textReview"></textarea>
      </div>

      <app-button [type]="'primary'" text="{{'booklet-review.avis-submit' | translate}}" (debounceClick)="saveReview()"></app-button>

    </div>

  </div>
</div>
