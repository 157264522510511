import {CoHostInCharge} from './PropertyForGuest';
import {CountryISO} from 'ngx-intl-tel-input';
import {CorporateBookletSection} from '../CorporateBookletSection';

export class Corporate {
  id: string;
  name: string;
  corporateTag: string;

  fullAddress: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  email: string;
  phone: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  linkedInUrl: string;
  webSiteUrl: string;

  primaryColor: string;
  secondaryColor: string;
  forgetMeNotColor: string;
  greenVogueColor: string;
  catskillColor: string;
  logoUrl: string;
  favLogoUrl: string;
  coHostInCharge: CoHostInCharge;
  showMeAsHost: boolean;

  yaagoFooterBackgroundColor: string;
  yaagoFooterTextColor: string;

  bookletMasterKey: string;
  lightCheckInOnLineWording: string;

  customGuideCategoriesPictures: CustomGuideCategoriesPictures[];
  intlPreferredCountries: CountryISO[];
  includeCommunityRecommendation: boolean;
  onlyMineRecommendations: boolean;
  homePageSettings : HomePageSettings [];
  bookletSeeGuidePictureNotCategory: boolean;
  requestedCheckTimeOnly: boolean;
  bookletBrowserTitle: string;

  corporateBookletSections: CorporateBookletSection[];
}

export class CustomGuideCategoriesPictures {
  categoryName: string;
  url;
}
 export class HomePageSettings {
   agencyLogoUrl: string;
   agencyTitle: string;
   fontColor: string;
   pictureDesktopUrl: string;
   pictureMobileUrl: string;
 }
