import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {GuestService} from '../../../core/services/guest.service';
import {LanguageManagementService} from '../../../core/services/language.service';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {IntercomService} from '../../../core/services/IntercomService';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../core/services/utils.service';
import {ApiService} from '../../../core/http/api.service';
import {MessageCreationDto} from '../../../models/guestview/MessageCreationDto';
import {MessageAudit} from '../../../models/guestview/MessageAudit';
import {Property} from "../../../models/Property";
import {PropertyForGuest} from "../../../models/guestview/PropertyForGuest";
import {Corporate} from "../../../models/guestview/Corporate";
import { YaagoDocument } from 'src/app/models/Guide';
import { DocumentUploadTypes } from 'src/app/models/YaagoDocument';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';

@Component({
  selector: 'app-guest-messaging',
  templateUrl: './guest-messaging.component.html',
  styleUrls: ['./guest-messaging.component.scss']
})
export class GuestMessagingComponent extends AbstractWindow implements OnInit, OnDestroy {

  allowedImages = DocumentUploadTypes.images();
  allowedVideos = DocumentUploadTypes.videos();
  allowedWord = DocumentUploadTypes.words();
  allowedPfd = DocumentUploadTypes.pdf();
  photos = [];
  videos = [];
  pdf = [];
  word = [];
  urlsPhotos = [];




  textToSend: string;
  bookingId: string;
  booking: BookingForGuest;
  property: PropertyForGuest;
  corporate: Corporate;
  messages: MessageAudit[] = [];
  menuSelected: number = 1;
  myTimerFunction: any;
  CheckinValidated = false
  withOnlinecheck = false
  constructor(private route: ActivatedRoute,
              public intercomService: IntercomService,
              private snackbar: SharedSnackbarService,
              private translate: TranslateService,
              private apiService: ApiService,
              public utilService: UtilsService,
              private languageService: LanguageManagementService,
              private guestService: GuestService) {
    super(intercomService, utilService);
  }

  ngOnDestroy() {
    clearInterval(this.myTimerFunction);
  }

  ngOnInit() {
    this.route.paramMap.subscribe( ParamMap => {
      this.bookingId = ParamMap.get('bookingId');

      this.getMessages();

      this.myTimerFunction = setInterval( () => {
        this.getMessages();
      }, 10000);


      const lang = this.languageService.getLanguageInUrl();
      this.guestService.getPropertyAndBookingForGuest( this.bookingId, lang).pipe(takeUntil(this.onDestroy)).subscribe( res => {
        this.property = res.property;
        this.corporate = res.corporate;
        this.booking = res.booking;
        this.withOnlinecheck = res.property.checkInOnline
        if(res.booking){
          if (res.booking.onLineCheck){
        if (res.booking.onLineCheck.checkStatus === 'VALIDATED'){
          this.CheckinValidated = true
        }else{
          this.CheckinValidated = false
        }
       }
      }
      });

    });
  }

  getMessages() {
    if (!this.bookingId) {
      return;
    }
    this.apiService.getMessages(this.bookingId).subscribe( msgs => {
      if (msgs != null && this.messages != null && msgs.length != this.messages.length) {
        this.messages = msgs;
        setTimeout( () => {
          this.scrollToLastMessage();
        }, 1000);
        console.log('--> myMessages', this.messages);
      }
    });
  }

  scrollToLastMessage() {
    let htmlElement = document.getElementById("message-container");
    console.log("htmlElement", htmlElement);
    if (htmlElement != null) {
      htmlElement.scrollTo({
        behavior: 'smooth',
        top: htmlElement.scrollHeight
      });
    }
  }

  sendMessage() {
    if (this.textToSend != null && this.textToSend.trim() != '') {
      const message = new MessageCreationDto();
      message.type = 'internal';
      message.content = this.textToSend;
      this.apiService.sendMessage(this.bookingId, message).subscribe( messageBack => {
        this.messages.push(messageBack);
        this.textToSend = '';
        setTimeout( () => {
          this.scrollToLastMessage();
        }, 500);
        console.log(this.messages);
      });
    }
  }

  replaceLinks(content: string) {
    if (!content) {
      return content;
    }
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, (url) => {
      return '<div class="chat-link-container"><a href="' + url + '" target="_blank">' + url + '</a></div>';
    });
  }






  onPictureUploaded(doc: YaagoDocument) {
    const yaagoDocument = new YaagoDocument();
    if (!this.photos ) {
      this.photos = [];
    }
    if (!this.videos ) {
      this.videos = [];
    }

    if (!this.pdf ) {
      this.pdf = [];
    }
    this.photos.push(doc.url);
    this.videos.push(doc.url);
    this.pdf.push(doc.url);
    this.word.push(doc.url);
    if (!this.textToSend) {
      this.textToSend = '';
    }

    let url = '' // dont want to ovveride booking tiny url
    url = doc.url
    this.textToSend += ' ' + url;
  }





}
