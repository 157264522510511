
<app-guest-menu-desktop class="fixed-header" *ngIf="isDesktopMode()"
                        [property]="property"
                        [corporate]="corporate"
                        [booking]="booking"
                        [fragment]="landingPageFragment"
                        ></app-guest-menu-desktop>
<app-guest-menu-mobile *ngIf="!isDesktopMode()" [(booking)]="booking" [corporate]="corporate"></app-guest-menu-mobile>
<div style="position: relative">
<div class="landing-page-container">

  <div *ngFor="let section of sections; let lineIndex = index">
    <app-guest-toppage *ngIf="section==='sectionToppage'" [property]="property" [(booking)]="booking"></app-guest-toppage>
    <app-y3-booking-details *ngIf="section==='sectionGuestDetails'" id="details" #details [section]="getSection('sectionGuestDetails')" [booking]="booking" [property]="property" [corporate]="corporate"></app-y3-booking-details>

    <!--<app-guest-booking-details  *ngIf="section==='sectionGuestDetails'" id="details" #details [booking]="booking" [property]="property" [corporate]="corporate"></app-guest-booking-details>-->

    <!--<app-y3-item-title *ngIf="section==='sectionTitle'" [curIndex]="lineIndex" [property]="property"></app-y3-item-title>-->
    <app-y3-important-guide *ngIf="section==='sectionImportantGuide'" id="importantguide" #importantguide [section]="getSection('sectionImportantGuide')" [curIndex]="lineIndex" [corporate]="corporate" [booking]="booking" [property]="property" #importantGuide></app-y3-important-guide>

    <app-y3-landing-guides *ngIf="section==='sectionGuide'" id="guides" #guides [section]="getSection('sectionGuide')" [curIndex]="lineIndex" [corporate]="corporate" [booking]="booking" [property]="property"></app-y3-landing-guides>
    <!--<app-guest-property-guides *ngIf="section==='sectionGuide'" id="guides" #guides [booking]="booking" [property]="property" ></app-guest-property-guides>-->

    <app-y3-landing-recommendation *ngIf="section==='sectionRecommendation'" id="recommendations" #recommendations [section]="getSection('sectionRecommendation')" [curIndex]="lineIndex" [booking]="booking" [corporate]="corporate" [property]="property"></app-y3-landing-recommendation>
    <!--<app-guest-recommendations-view *ngIf="section==='sectionRecommendation'"  id="recommendations" #recommendations></app-guest-recommendations-view>-->


    <app-y3-usefulnumber *ngIf="section==='sectionUsefull'" id="usefulnumber" [curIndex]="lineIndex" [property]="property" #usefulnumber></app-y3-usefulnumber>
    <!--<app-guest-usefulnumber *ngIf="section==='sectionUsefull'" id="usefulnumber" #usefulnumber></app-guest-usefulnumber>-->


    <app-upsale-category-list *ngIf="section==='sectionUpsales' && hasUpsales" id="upsales" #upsales [booking]="booking" ></app-upsale-category-list>

    <!--<app-guest-platform-services *ngIf="section==='sectionServices'" id="services"  #services [property]="property" [platformServices]="platformAffiliateServices"></app-guest-platform-services>-->
    <app-y3-plaform-services *ngIf="section==='sectionServices'" id="services"  #services [curIndex]="lineIndex" [property]="property" [platformServices]="platformAffiliateServices"></app-y3-plaform-services>

    <app-guest-hygiene *ngIf="section==='sectionHygien'" id="hygienes"  #hygienes></app-guest-hygiene>

    <app-y3-booklet-review *ngIf="booking && !property?.noshowBookletReview && section==='sectionReview'" [curIndex]="lineIndex" [bookingId]="booking?.id" [bookletReviews]="bookletReviews"></app-y3-booklet-review>
    <!--<app-footer-booklet-reviews *ngIf="booking && !property?.noshowBookletReview && section==='sectionReview'" [bookingId]="booking?.id" [bookletReviews]="bookletReviews"></app-footer-booklet-reviews>-->
  </div>










  <app-guest-footer-page *ngIf="property?.host?.corporate" [corporate]="property?.host?.corporate"></app-guest-footer-page>
  <app-desktop-guest-arrived-left *ngIf="isDesktopMode() && booking" [booking]="booking" [property]="property"></app-desktop-guest-arrived-left>
  <app-mobile-guest-arrived-left *ngIf="!isDesktopMode() && booking" [booking]="booking" [property]="property"></app-mobile-guest-arrived-left>
</div>




</div>
