import {Component, Input, OnInit} from '@angular/core';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {IntercomService} from '../../../core/services/IntercomService';
import {UtilsService} from '../../../core/services/utils.service';
import {MatDialog} from '@angular/material';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {LanguageManagementService} from '../../../core/services/language.service';
import {GuestService} from '../../../core/services/guest.service';
import {Corporate} from '../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import * as moment from 'moment/moment';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {GuestBookingInformationConfirmationDto} from '../../../models/preCheckOnLine/GuestBookingInformationConfirmationDto';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {LoaderService} from '../../../core/services/LoaderService';
import {Location} from '@angular/common';

@Component({
  selector: 'app-y3-pre-checkin',
  templateUrl: './y3-pre-checkin.component.html',
  styleUrls: ['./y3-pre-checkin.component.scss']
})
export class Y3PreCheckinComponent extends AbstractWindow implements OnInit {

  @Input() nonBlocking = false;
  booking: BookingForGuest;
  corporate: Corporate;
  property: PropertyForGuest;
  displayDisableEmail = false;

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              private route: ActivatedRoute,
              private languageService: LanguageManagementService,
              private guestService: GuestService,
              private translateService: TranslateService,
              private snackbarService: SnackbarService,
              private loaderService: LoaderService,
              private location: Location,
              protected dialog: MatDialog) {
    super(intercomService, utilsService);
  }

  ngOnInit() {
    this.route.paramMap.subscribe( ParamMap => {
      const bookingId = ParamMap.get('bookingId');
      console.log("bookingId", bookingId);
      this.guestService.getPropertyAndBookingForGuest(bookingId , this.languageService.getLanguageInUrl()).pipe(takeUntil(this.onDestroy)).subscribe( res => {
        this.booking = res.booking;
        this.corporate = res.corporate;
        this.property = res.property;

        this.utilsService.manageCorporateColors(this.corporate);

        if (this.booking.expectedCheckinLocalTime != null && this.booking.expectedCheckinLocalTime.length === 8) {
          this.booking.expectedCheckinLocalTime = this.booking.expectedCheckinLocalTime.substr(0,5);
        }
        if (this.booking.expectedCheckoutLocalTime != null && this.booking.expectedCheckoutLocalTime.length === 8) {
          this.booking.expectedCheckoutLocalTime = this.booking.expectedCheckoutLocalTime.substr(0,5);
        }
        if (this.booking.expectedCheckinLocalTime == null && this.property.defaultCheckInTime != null) {
          console.log("change defaultime");
          this.booking.expectedCheckinLocalTime = this.property.defaultCheckInTime;
        }
        if (this.booking.expectedCheckoutLocalTime == null && this.property.defaultCheckOutTime != null) {
          this.booking.expectedCheckoutLocalTime = this.property.defaultCheckOutTime;
        }
      });
    });
  }

  addChild() {
    if (this.property.personCapacity > 0) {
      if (this.booking.adults + this.booking.children < this.property.personCapacity) {
        this.booking.children++;
      }
    } else {
      this.booking.children++;
    }

  }

  addAdults() {
    if (this.property.personCapacity > 0) {
      if (this.booking.adults + this.booking.children < this.property.personCapacity) {
        this.booking.adults++;
      }
    } else {
      this.booking.adults++;
    }

  }


  checkDisableBtnDecre(value : number) {
    if (value<1 ) {
      return 'primary-not-valid';
    }
  }

  removeAdults() {
    if (this.booking.adults > 0) {
      this.booking.adults--;
    }
  }
  removeChild() {
    if (this.booking.children > 0) {
      this.booking.children--;
    }
  }

  add(type: string) {
    if (type === 'double' && this.booking.doubleBeds<this.property.doubleBeds) {
      this.booking.doubleBeds++;
    }
    if (type === 'single' && this.booking.singleBeds<this.property.singleBeds) {
      this.booking.singleBeds++;
    }
    if (type === 'sofa' && this.booking.sofaBeds<this.property.sofaBeds) {
      this.booking.sofaBeds++;
    }
    if (type === 'crib' && this.booking.babyBeds<this.property.babyBeds) {
      this.booking.babyBeds++;
    }
  }

  remove(type: string) {
    if (type === 'double' && this.booking.doubleBeds > 0) {
      this.booking.doubleBeds--;
    }
    if (type === 'single' && this.booking.singleBeds > 0) {
      this.booking.singleBeds--;
    }
    if (type === 'sofa' && this.booking.sofaBeds > 0) {
      this.booking.sofaBeds--;
    }
    if (type === 'crib' && this.booking.babyBeds > 0) {
      this.booking.babyBeds--;
    }

  }

  saveBookingInfo() {
    this.loaderService.dismissLoader();
    this.snackbarService.dismiss();

    if (this.booking.guests[0].secondaryEmail==='' && this.displayDisableEmail) {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant( 'guest-setup-page.empty-email'), 'error'));
      return;
    }

    if (!this.booking.expectedCheckinLocalTime || !this.booking.expectedCheckoutLocalTime) {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant( 'guest-setup-page.checkin-checkout-empty'), 'error'));
      return;
    }

    if (!this.booking.guests[0].firstName || !this.booking.guests[0].lastName) {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant( 'guest-setup-page.name-empty'), 'error'));
      return;
    }


    if (this.property.defaultCheckInTime && !this.property.canEarlyCheck) {
      if (moment(this.booking.expectedCheckinLocalTime, 'hh:mm').isBefore(moment(this.property.defaultCheckInTime, 'hh:mm'))) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('guest-setup-page.impossible-checkin-time') + ' '
          + this.property.defaultCheckInTime, 'error'));
        return;
      }
    }
    if (this.property.defaultCheckOutTime && !this.property.canEarlyCheck) {
      if (moment(this.booking.expectedCheckoutLocalTime, 'hh:mm').isAfter(moment(this.property.defaultCheckOutTime, 'hh:mm'))) {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('guest-setup-page.impossible-checkout-time') + ' '
          + this.property.defaultCheckOutTime, 'error'));
        return;
      }
    }
    const bookingToSend = new GuestBookingInformationConfirmationDto();
    if (!this.corporate.requestedCheckTimeOnly) {
      bookingToSend.expectedCheckinLocalTime = this.booking.expectedCheckinLocalTime;
      bookingToSend.expectedCheckoutLocalTime = this.booking.expectedCheckoutLocalTime;
      bookingToSend.requestedCheckinLocalTime = null;
      bookingToSend.requestedCheckoutLocalTime = null;
    } else {
      bookingToSend.expectedCheckinLocalTime = null;
      bookingToSend.expectedCheckoutLocalTime = null;
      bookingToSend.requestedCheckinLocalTime = this.booking.expectedCheckinLocalTime;
      bookingToSend.requestedCheckoutLocalTime = this.booking.expectedCheckoutLocalTime;
    }

    bookingToSend.guestNote = this.booking.guestNote;
    bookingToSend.adults = this.booking.adults;
    bookingToSend.children = this.booking.children;
    bookingToSend.babies = this.booking.babies;
    bookingToSend.pets = this.booking.pets;
    bookingToSend.doubleBeds = this.booking.doubleBeds;
    bookingToSend.singleBeds = this.booking.singleBeds;
    bookingToSend.sofaBeds = this.booking.sofaBeds;
    bookingToSend.babyBeds = this.booking.babyBeds;
    bookingToSend.guestNote = this.booking.guestNote;
    bookingToSend.firstName = this.booking.guests[0].firstName;
    bookingToSend.lastName = this.booking.guests[0].lastName;
    bookingToSend.phone = this.booking.guests[0].phone;
    bookingToSend.secondaryPhone = this.booking.guests[0].secondaryPhone;
    bookingToSend.email = this.booking.guests[0].email;
    bookingToSend.secondaryEmail = this.booking.guests[0].secondaryEmail;
    bookingToSend.companyName = this.booking.guests[0].companyName;

    this.loaderService.showFullLoader('');
    this.guestService.saveBooking(this.booking.id, bookingToSend).subscribe(resp => {
      this.loaderService.dismissLoader();
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('guest-setup-page.guest_success_message'), 'info'));
    }, err => {
      this.loaderService.dismissLoader();
      console.log('ERROR', err);
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('guest-setup-page.missing-information'), 'error'));
    });

  }
  back() {
    this.location.back();
  }

}
