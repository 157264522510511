<div class="y3-divider {{(curIndex%2===0)?'y3-silver':'y3-white'}}" *ngIf="(canShowIglooHome() || canShowTheKeys() || isSwiklyConfigured() || canShowSmartLock() || (importantGuides && importantGuides.length > 0))">
  <div class="y3-container " >

    <div class="y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-responsive y3-flex-justify-content-center ">
      <div *ngIf="!section" style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text2="{{'guest-view-booking-details.important-info' | translate}}" [type]="'deproprietaire'"></app-tag>
      </div>
      <div *ngIf="section" style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text2="{{section.sectionTitle}}" [type]="'deproprietaire'"></app-tag>
      </div>
    </div>

    <div class="y3-partner-section">
      <img *ngIf="isDesktopMode()" src="/assets/icon/icon-arrow-left.svg" alt="previous" class="y3-clickable y3-margin-right-16" (click)="partnerScroll(partnerContainer, 'left')">
      <div class="y3-partner-container " #partnerContainer>

        <!-- YOUR PROPERTY IS READY -->
        <!--<div *ngIf="property.needPreCheck && booking" class="y3-guide-bloc">
          <img class="y3-important-guide-picture" src="{{property.photos[0].url}}" alt="ready">
          <div class="y3-text-guide-bloc y3-flex-responsive y3-flex-justify-space-between">
            <div *ngIf="isPropertyReady()" >
              <app-tag [minWidth]="'166px'" text="{{'guest-view-booking-details.logement-ready' | translate}}" [type]="'green'"> </app-tag>
            </div>
            <div *ngIf="!isPropertyReady()" >
              <app-tag [minWidth]="'166px'" text="{{'guest-view-booking-details.logement-not-ready' | translate}}" [type]="'orange'"> </app-tag>
            </div>
          </div>
        </div>-->

        <!-- IGLOOHOME -->
        <div *ngIf="canShowIglooHome()  && isSmartLockCodeAllowToShow()" class="y3-guide-bloc">
          <img *ngIf="booking.iglooHomeAccess?.lockId?.startsWith('IGK')" class="y3-important-guide-picture" src="assets/icon/guide-partner/igloohome-igk3_1_.jpg" alt="igloohome">
          <img *ngIf="booking.iglooHomeAccess?.lockId?.startsWith('IGM')" class="y3-important-guide-picture" src="assets/icon/guide-partner/igloohome-igm3_1_.jpg" alt="igloohome">
          <div class="y3-text-guide-bloc y3-bold y3-flex-responsive y3-flex-justify-space-between" *ngIf="(booking.iglooHomeAccess ? booking.iglooHomeAccess.code : booking.iglooHomeLockCode) as code"  >
            <div *ngIf="booking.iglooHomeAccess?.lockId?.startsWith('IGM')" >{{'guest-detail-booking.smartlock' | translate}} : </div>
            <div *ngIf="booking.iglooHomeAccess?.lockId?.startsWith('IGK')" >{{'guest-detail-booking.smartkeybox' | translate}} : </div>
            <div class="y3-normal">&nbsp; {{code}}🔓</div>
          </div>
        </div>

        <!-- SMARTLOCKS -->
        <div *ngIf="canShowSmartLock()  && isSmartLockCodeAllowToShow()" class="y3-flex-responsive">
          <div *ngFor="let lock of booking?.smartLockAccessList" >
            <div class="y3-guide-bloc">
              <div class="y3-flex-row y3-flex-align-center y3-flex-justify-content-center">
                <img *ngIf="lock.smartLockPartner === 'nuki' && lock.smartLockType === '2'" class="y3-important-guide-picture-nuki" style="height: 160px;" src="{{'assets/icon/guide-partner/opener.jpg'}}" alt="igloohome">
                <img *ngIf="lock.smartLockPartner === 'nuki' && lock.smartLockType === '4'" class="y3-important-guide-picture-nuki" style="height: 160px;" src="{{'assets/icon/guide-partner/nuki-smartlock.jpg'}}" alt="igloohome">
              </div>

              <div *ngIf="((lock.smartLockCode ? lock.smartLockCode : '') || lock.urlToOpen) && lock.canDisplay" class="nuki-click" >
                {{lock.smartLockName}}
                <div class="y3-margin-top-4 nuki-click">
                <app-tag *ngIf="lock.smartLockCode" url="{{lock.urlToOpen}}" [minWidth]="'100%'"
                         text="{{'guest-view-booking-details.smart-code' | translate}} {{(lock.smartLockCode ? lock.smartLockCode : '')}}🔓 " text2="{{lock.urlToOpen !== '' ? ('guest-view-booking-details.click-to-open' | translate) : '' }}" [type]="'blue'" ></app-tag>
                <app-tag *ngIf="!lock.smartLockCode" url="{{lock.urlToOpen}}" [minWidth]="'100%'"
                         text="{{lock.urlToOpen !== '' ? ('guest-view-booking-details.click-to-open' | translate) : '' }}" [type]="'blue'" ></app-tag>
                </div>
              </div>

            </div>
          </div>
        </div>


        <!-- THE KEYS -->
        <div *ngIf="canShowTheKeys() && isSmartLockCodeAllowToShow()" class="y3-guide-bloc">
          <img class="y3-important-guide-picture" src="assets/icon/guide-partner/the-keys.jpg" alt="thekeys">
          <div class="y3-text-guide-bloc" >
            <app-tag url="{{utilsService.getTheKeysLink(booking)}}" [minWidth]="'166px'"
                     text="{{'guest-view-booking-details.smart-code' | translate}} {{utilsService.getTheKeysCode(booking)}} " text2="{{utilsService.getTheKeysLink(booking) !== '' ? ('guest-view-booking-details.click-to-open' | translate) : '' }}" [type]="'blue'" ></app-tag>
          </div>

          <!--<p class="margin-top-24 roboto Small bold txt-color-scorpion" (click)="clipBoardCopy(utilService.getTheKeysCode(booking))">{{utilService.getTheKeysCode(booking)}}</p>
          <a class="margin-top-8 swikly-btn" *ngIf="utilService.getTheKeysLink(booking) != ''" href="{{utilService.getTheKeysLink(booking)}}" target="_blank">{{'guest-detail-booking.smartlock-link' | translate}}</a>-->
          <!--<p class="margin-top-8 roboto Small bold txt-color-scorpion" (click)="clipBoardCopy(utilService.getTheKeysLink(booking))">{{utilService.getTheKeysLink(booking)}}</p>-->
        </div>


        <!-- SWIKLY -->
        <div *ngIf="isSwiklyConfigured()" class="y3-guide-bloc">
          <img class="y3-important-guide-picture" src="assets/icon/guide-partner/deposit.jpg" alt="swikly">
          <div class="swikly-status margin-top-4">
            <img src="{{isCautionDeposed() ? '/assets/icon/valid.svg': '/assets/icon/not-valid.svg'}}" alt="status">
          </div>
          <!--<a class="margin-top-8 swikly-btn" *ngIf="!isCautionDeposed() && isCautionAllowToShow()" href="{{booking?.swiklyInfo?.swiklyAcceptUrl}}" target="_blank">{{'guest-view-booking-details.partners.deposit-caution' | translate}}</a>-->

          <div class="y3-text-guide-bloc" >
            <app-tag [minWidth]="'166px'"  *ngIf="isCautionDeposed()" text="{{booking.swiklyInfo?.swiklyDepositAmount?.amount | currency : booking.swiklyInfo?.swiklyDepositAmount?.currency}} {{'guest-view-booking-details.partners-deposit' | translate}}" [type]="'green'" [status]="'success'"></app-tag>
            <app-tag url="{{booking?.swiklyInfo?.swiklyAcceptUrl}}" [minWidth]="'166px'"  *ngIf="!isCautionDeposed()" text="{{booking.swiklyInfo?.swiklyDepositAmount?.amount | currency : booking.swiklyInfo?.swiklyDepositAmount?.currency}} {{'guest-view-booking-details.partners-to-deposit' | translate}}" [type]="'orange'" ></app-tag>
          </div>

        </div>

        <div *ngFor="let guide of importantGuides" >
              <div class="y3-guide-bloc y3-clickable" (click)="showGuide(guide)" *ngIf="needToShowGuide(guide)">
                <img class="y3-important-guide-picture" src="{{getGuideCover(guide)}}" alt="guide">
                <div class="y3-text-guide-bloc y3-bold"><span *ngIf="guide.category === 'wifi'">WIFI: </span> {{guide.translations[0].title |titlecase }}</div>
              </div>
        </div>
      </div>
      <img *ngIf="isDesktopMode()" src="/assets/icon/icon-arrow-left.svg" alt="next" class="y3-clickable y3-rotate-180 y3-margin-left-16" (click)="partnerScroll(partnerContainer, 'right')">
    </div>

  </div>
</div>

