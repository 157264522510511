import * as i0 from "@angular/core";
export class SecurityService {
    removeSecurtityPassword() {
        localStorage.removeItem("CheckPassword");
    }
    setSecurityPassword() {
        localStorage.setItem("CheckPassword", "YES");
    }
    getSecurityPassword() {
        return localStorage.getItem("CheckPassword");
    }
}
SecurityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SecurityService_Factory() { return new SecurityService(); }, token: SecurityService, providedIn: "root" });
