import {Component, HostListener, NgZone, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UtilsService} from '../../../../core/services/utils.service';
import {Recommendation} from '../../../../models/guestview/Recommendation';
import {Poi} from '../../../../models/Poi';
import {RecommendationSearchRequestDto} from '../../../../models/guestview/RecommendationSearchRequestDto';
import {GuestService} from '../../../../core/services/guest.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageManagementService} from '../../../../core/services/language.service';
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {Tags} from "../../../../models/guestview/Tags";
import {RecommendationCategory} from "../../../../models/guestview/RecommendationCategory";
import {BookingForGuest} from "../../../../models/guestview/BookingForGuest";
import { CollectDataServiceService } from 'src/app/core/services/collect-data-service.service';
import {Property} from '../../../../models/Property';
import {Corporate} from '../../../../models/guestview/Corporate';
import {ApiService} from '../../../../core/http/api.service';

declare var google: any;

@Component({
  selector: 'app-guest-recommendations-map',
  templateUrl: './guest-recommendations-map.component.html',
  styleUrls: ['./guest-recommendations-map.component.scss']
})
export class GuestRecommendationsMapComponent implements OnInit {

  isMine = true;
  isCommunity = false;
  isInfluencer = false;

  isYaago = false;
  recommendationSearch: RecommendationSearchRequestDto = new RecommendationSearchRequestDto();
  showMap = false;
  fromSearch = false;
  searchedAddress: string;
  searchedPlaceId: string;

  @ViewChild('guruMap', null) guruMapElement: any;
  public guruMap: any;
  public property;
  public booking: BookingForGuest;
  public propertyId: string = null;
  public lat: number;
  public lng: number;
  public markers = [];
  categories: any[] = new RecommendationCategory().list;
  selectedCategory: any;


  // NEW RECOMMENDATION MAP
  centerOfTheMap: Poi;
  propertiesForMap: Property[] = [];
  propertyPoi: Poi;
  whereIamPoi: Poi;
  platformName: string = "";
  propertyReference: string = "";
  showZoomControl = true;
  showFilterReco = true;
  showOwner = true;
  showRecoYaago = true;
  showRecoInfluencer = true;
  recapVersion = 'v1';
  language = 'fr';
  corporate: Corporate = new Corporate();

  constructor(
    public utilsService: UtilsService,
    public zone: NgZone,
    public renderer: Renderer2,
    public router: Router,
    public guestService: GuestService,
    public translateService: TranslateService,
    public languageService: LanguageManagementService,
    public gaSrv: GoogleAnalyticsService ,
    public CollectData :CollectDataServiceService,
    public apiService: ApiService
  ) { }

  ngOnInit() {
    // workaround to delete all on categories list
    //this.categories.splice(this.categories.length - 1, 1);
    this.guestService.isMap$.subscribe(
      res => {
        this.showMap = res.value;
        this.property = res.property;
        this.corporate = res.corporate;

        if (this.corporate  && this.corporate.onlyMineRecommendations) {
          this.isCommunity = !this.corporate.onlyMineRecommendations;
          this.isYaago = true;
        }
      },
      err => {}
    );
    this.guestService.getBooking().subscribe(
      data => {
        this.booking = data;

      }
    );
    this.gaSrv.pageView('/guest/{id}/recommendationsMap', 'Recommendation-Map');
  }

  initialize() {



    //this.lat = this.property.poi.y;
    //this.lng = this.property.poi.x;

    if (this.property.corporateId != null) {
      this.apiService.getCorporate(this.property.corporateId).subscribe(res => {
        this.corporate = res;
        this.centerOfTheMap = this.property.poi;
        this.propertiesForMap.push(this.property);
        console.log("Corporate:", this.corporate);
      });
    } else {
      this.centerOfTheMap = this.property.poi;
      this.propertiesForMap.push(this.property);
    }




    this.loadInputSearch();
  }

  loadInputSearch() {
    /*const zone = this.calculateZone(new google.maps.LatLng(this.property.poi.y, this.property.poi.x), 5000);*/
    const defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(this.lat, this.lng));
    const options = {
      bounds: defaultBounds,
      fields: [
        'formatted_address', 'geometry', 'place_id'
      ]
    };
    const addressInputElem = document.getElementById('fullAddress');
    const autocompleteFrom = new google.maps.places.Autocomplete(addressInputElem, options);
    autocompleteFrom.addListener('place_changed', () => {
      this.zone.run(() => {
        if (autocompleteFrom.getPlace().geometry.location) {

          this.centerOfTheMap = new Poi();
          this.centerOfTheMap.x = autocompleteFrom.getPlace().geometry.location.lng();
          this.centerOfTheMap.y = autocompleteFrom.getPlace().geometry.location.lat();
        }


      });
    });
  }

  @HostListener('window:resize', ['$event'])
  isDesktopMode(event?) {
    return this.utilsService.isDesktop(window.innerWidth);
  }
  closeMap() {
    this.guestService.closeMap();
  }
}
