<div class="tag" [ngClass]="type" [ngStyle]="{'min-width': minWidth ? minWidth : '100%', 'max-width' : minWidth ? minWidth : 'none', 'padding': padding}">
  <section *ngIf="url">
    <a style="text-align: left" href="{{url}}" target="_blank">
      <div style="display :flex; flex-flow: row; align-items: center; justify-content: space-between">
        <div style="display :flex; flex-flow: row; ">
          <p>{{text}}</p>
          <p style="font-weight: normal; margin-top: 4px" [ngStyle]="{'font-size': sizeText2}" *ngIf="text2">{{text2}}</p>
          <div>
            <img style="position: absolute; right: 8px" *ngIf="status" class="margin-left-8" [alt]="status" [src]="tagStatusImage">
          </div>
        </div>
      </div>
    </a>
  </section>

  <section *ngIf="!url">
    <div style="display :flex; flex-flow: row; align-items: center; justify-content: space-between">
      <div style="display :flex; flex-flow: row; ">
        <p>{{text}}</p>
        <p style="font-weight: normal; margin-top: 4px" [ngStyle]="{'font-size': sizeText2}" *ngIf="text2">{{text2}}</p>
        <div>
          <img  (click)="closeEvent.emit()" style="position: absolute; right: 8px" *ngIf="status" class="y3-clickable y3-margin-left-8" [alt]="status" [src]="tagStatusImage">
        </div>
      </div>
    </div>
  </section>
</div>
