<div class="page-container">

  <app-online-check-header [corporate]="corporate"
    *ngIf="booking?.onLineCheck?.checkStatus == 'EMPTY' || booking?.onLineCheck?.checkStatus == 'WAITING' || booking?.onLineCheck?.checkStatus == 'NOUVEAU' || booking?.onLineCheck?.checkStatus == 'REFUSED'">
  </app-online-check-header>
  <app-guest-menu-desktop [corporate]="corporate" [property]="property"
    *ngIf="booking?.onLineCheck?.checkStatus == 'VALIDATED'"></app-guest-menu-desktop>

  <div style="margin-top: 20px;"
    [ngClass]="booking?.onLineCheck?.checkStatus == 'VALIDATED' ? 'check-containerW':'check-container'">
    <div class="title">
      <p class="margin-bottom-16">{{property?.title}}</p>
    </div>

    <app-online-check-welcome-bar [property]="property" [booking]="booking"></app-online-check-welcome-bar>

    <div class="card-informations">

      <div class="online-check-firstline-card">
        <div *ngIf="booking?.onLineCheck?.checkStatus === 'WAITING' || booking?.onLineCheck?.checkStatus == 'NOUVEAU'" class="online-check-status-button">
          {{'online-check.online-check' | translate}} </div>
        <div *ngIf="booking?.onLineCheck?.checkStatus === 'REFUSED'" class="online-check-status-refused-button">
          {{'online-check.online-check-refused' | translate}} </div>
        <app-button *ngIf="booking?.onLineCheck?.checkStatus != 'VALIDATED' " [type]="'ternary'"
          [text]="'online-check.edit' | translate" (debounceClick)="editInfo()"></app-button>

      </div>

      <div *ngIf="booking?.onLineCheck?.checkStatus === 'REFUSED' " style="  padding-bottom: 18px;">

        <div class="reason"> {{'online-check.reason' | translate}} </div>
        <div *ngIf="this.booking?.guests[0]?.languages[0] ==='fr' " style="width: 40%;;">
          {{booking?.onLineCheck?.reasonRefused}}</div>
        <div *ngIf="this.booking?.guests[0]?.languages[0] != 'fr' " style="width: 40%;;">
          {{booking?.onLineCheck?.reasonRefusedTranslated}} </div>

      </div>



      <div class=containers-block>

        <div *ngIf="property?.checkinOnlineWithContract" class="information-position">
          <div class="informations ">
            <div class="information-title">{{'online-check.info' | translate}}</div>

            <div class="display-informations information-content">
              <div>{{'online-check.lastname' | translate}}</div>
              <div>{{localOnLineCheck?.lastName}} </div>
            </div>
            <div class="display-informations information-content">
              <div>{{'online-check.firstname' | translate}}</div>
              <div>{{localOnLineCheck?.firstName}} </div>
            </div>
            <div class="display-informations information-content">
              <div>{{'online-check.birthdate' | translate}}</div>
              <div>{{localOnLineCheck?.guestBirthDate | date: 'dd-MM-yyyy'}} </div>
            </div>


            <div class="display-informations information-content">
              <div>{{'online-check.gender' | translate}}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'homme' ">{{'online-check.male' | translate}}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'femme'">{{'online-check.female' | translate}}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'autre'">{{'online-check.other' | translate}}</div>
            </div>


            <div class="display-informations information-content">
              <div>{{'online-check.email' | translate}}</div>
              <div> {{localOnLineCheck?.email}}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{'online-check.contactNumber' | translate}}</div>
              <div> {{localOnLineCheck?.phoneNumber}}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{'online-check.country' | translate}}</div>

              <div>{{onLineCheckCountry}}</div>

            </div>
            <div *ngIf="property?.checkinOnlineWithTransport === true" class="display-informations information-content">
              <div>{{'online-check.onlineCheckWithTransport' | translate}}</div>

              <div *ngIf="localOnLineCheck?.onLineCheckWithTransport === true">{{'online-check.true' | translate}}</div>
              <div *ngIf="localOnLineCheck?.onLineCheckWithTransport === false">{{'online-check.false' | translate}}
              </div>
            </div>
          </div>
          <div *ngIf="property?.checkinOnlineWithContract" style="margin-right: 16px;">
            <div class="information-title">{{'online-check.id' | translate}}</div>
            <div *ngFor="let img of localOnLineCheck?.identityProofUrls">
              <iframe class="online-check-img" *ngIf="img.link().includes('.pdf')"
              [src]="img| safe"></iframe>
            <img  *ngIf="!img.link().includes('.pdf')" class="online-check-img"  [src]="img" />
            <!-- <img *ngIf="img.link().includes('.jpeg')"  class="online-check-img"  [src]="img" /> -->
          </div>

          </div>
          <div *ngIf="!property?.checkinOnlineWithContract" style="margin-right: 16px; width: 50%;">
            <div class="information-title">{{'online-check.id' | translate}}</div>
            <div *ngFor="let img of localOnLineCheck?.identityProofUrls">
              <iframe class="online-check-img" *ngIf="img.link().includes('.pdf')"
              [src]="img| safe"></iframe>
            <img *ngIf="img.link().includes('.jpg')"  class="online-check-img"  [src]="img" />
            <img *ngIf="img.link().includes('.jpeg')"  class="online-check-img"  [src]="img" />
          </div>

          </div>

        </div>

        <div *ngIf="property?.checkinOnlineWithContract  && !property?.checkinOnlineWithCgv"
          class="contrat contrat-position contratpdf">

          <div class="information-title">{{'online-check.contract' | translate}}</div>
          <div class="new-tab-position">
            <!-- <a class="new-tab" href="{{localOnLineCheck?.contractDocumentUrlSigned}}" target="_blank">{{'online-check.yourContract' | translate}}</a> -->


            <app-button class="new-tab" [type]="'ternary'" [text]="'online-check.contract-download' | translate"
              (debounceClick)="openInNewTab()"></app-button>


          </div>
          <pdf-viewer class="ng2-pdf-viewer-container" [src]="localOnLineCheck?.contractDocumentUrlSigned"
            [render-text]="true" [original-size]="false" [show-borders]="true" [show-all]="true" [fit-to-page]="true"
            [stick-to-page]="true"></pdf-viewer>

        </div>



        <div *ngIf="property?.checkinOnlineWithContract && property?.checkinOnlineWithCgv"
          class="contrat contrat-position contratpdf">

          <div class="information-title">{{'online-check.contract' | translate}}</div>
          <div class="new-tab-position">
            <!-- <a class="new-tab" href="{{localOnLineCheck?.contractDocumentUrlSigned}}" target="_blank">{{'online-check.yourContract' | translate}}</a> -->
            <app-button class="new-tab" [type]="'ternary'" [text]="'online-check.contract-download' | translate"
              (debounceClick)="openInNewTab()"></app-button>
          </div>

          <pdf-viewer class="ng2-pdf-viewer-container" [src]="localOnLineCheck?.contractDocumentUrlSigned"
            [render-text]="true" [original-size]="false" [show-borders]="true" [show-all]="true" [fit-to-page]="true"
            [stick-to-page]="true"></pdf-viewer>
          <div class="cgv-padding" style="padding-top: 14px;">{{'online-check.cgv' | translate}}</div>
          <div class="new-tab-position">
            <!-- <a class="new-tab" href="{{localOnLineCheck?.cgvDocumentUrl}}" target="_blank">{{'online-check.yourCGV' | translate}}</a> -->
            <app-button class="new-tab" [type]="'ternary'" [text]="'online-check.cgv-documents' | translate"
              (debounceClick)="openInNewTabCgv()"></app-button>

          </div>
          <pdf-viewer class="ng2-pdf-viewer-container" [src]="localOnLineCheck?.cgvDocumentUrl" [render-text]="true"
            [original-size]="false" [show-borders]="true" [show-all]="true" [fit-to-page]="true" [stick-to-page]="true">
          </pdf-viewer>
        </div>

      </div>

      <div *ngIf="!property?.checkinOnlineWithContract && !property?.checkinOnlineWithCgv"
        class="containers-block-nocontrat">

        <div class="information-position-nocontrat">
          <div class="informations ">
            <div class="information-title">{{'online-check.info' | translate}}</div>

            <div class="display-informations information-content">
              <div>{{'online-check.lastname' | translate}}</div>
              <div>{{localOnLineCheck?.lastName}} </div>
            </div>
            <div class="display-informations information-content">
              <div>{{'online-check.firstname' | translate}}</div>
              <div>{{localOnLineCheck?.firstName}} </div>
            </div>
            <div class="display-informations information-content">
              <div>{{'online-check.birthdate' | translate}}</div>
              <div>{{localOnLineCheck?.guestBirthDate | date: 'dd-MM-yyyy'}} </div>
            </div>
            <div class="display-informations information-content">
              <div>{{'online-check.gender' | translate}}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'homme' ">{{'online-check.male' | translate}}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'femme'">{{'online-check.female' | translate}}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'autre'">{{'online-check.other' | translate}}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{'online-check.email' | translate}}</div>
              <div> {{localOnLineCheck?.email}}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{'online-check.contactNumber' | translate}}</div>
              <div> {{localOnLineCheck?.phoneNumber}}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{'online-check.country' | translate}}</div>

              <div>{{onLineCheckCountry}}</div>

            </div>
            <div *ngIf="property?.checkinOnlineWithTransport === true" class="display-informations information-content">
              <div>{{'online-check.onlineCheckWithTransport' | translate}}</div>
              <div *ngIf="localOnLineCheck?.onLineCheckWithTransport === true">{{'online-check.true' | translate}}</div>
              <div *ngIf="localOnLineCheck?.onLineCheckWithTransport === false">{{'online-check.false' | translate}}
              </div>
            </div>
          </div>
          <div style="margin-right: 16px; width: 50%;">
            <div class="information-title">{{'online-check.id' | translate}}</div>
            <div *ngFor="let img of localOnLineCheck?.identityProofUrls">
              <iframe class="online-check-img" *ngIf="img.link().includes('.pdf')"
              [src]="img| safe"></iframe>

            <img *ngIf="!img.link().includes('.pdf')"  class="online-check-img"  [src]="img" />

          </div>
          </div>

        </div>
      </div>
      <div *ngIf="!property?.checkinOnlineWithContract && property?.checkinOnlineWithCgv" class="containers-block">

        <div class="information-position">
          <div class="informations ">
            <div class="information-title">{{'online-check.info' | translate}}</div>

            <div class="display-informations information-content">
              <div>{{'online-check.lastname' | translate}}</div>
              <div>{{localOnLineCheck?.lastName}} </div>
            </div>
            <div class="display-informations information-content">
              <div>{{'online-check.firstname' | translate}}</div>
              <div>{{localOnLineCheck?.firstName}} </div>
            </div>
            <div class="display-informations information-content">
              <div>{{'online-check.birthdate' | translate}}</div>
              <div>{{localOnLineCheck?.guestBirthDate | date: 'dd-MM-yyyy'}} </div>
            </div>
            <div class="display-informations information-content">
              <div>{{'online-check.gender' | translate}}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'homme' ">{{'online-check.male' | translate}}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'femme'">{{'online-check.female' | translate}}</div>
              <div *ngIf="this.localOnLineCheck?.guestGender === 'autre'">{{'online-check.other' | translate}}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{'online-check.email' | translate}}</div>
              <div> {{localOnLineCheck?.email}}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{'online-check.contactNumber' | translate}}</div>
              <div> {{localOnLineCheck?.phoneNumber}}</div>
            </div>

            <div class="display-informations information-content">
              <div>{{'online-check.country' | translate}}</div>

              <div>{{onLineCheckCountry}}</div>

            </div>

          </div>
          <div class="" style="margin-right: 16px;">
            <div class="information-title">{{'online-check.id' | translate}}</div>
            <div *ngFor="let img of localOnLineCheck?.identityProofUrls">
              <iframe class="online-check-img" *ngIf="img.link().includes('.pdf')"
              [src]="img| safe"></iframe>

            <img *ngIf="!img.link().includes('.pdf')"  class="online-check-img"  [src]="img" />

          </div>
          </div>


        </div>

        <div class="contrat contrat-position contratpdf">
          <div class="cgv-padding">{{'online-check.cgv' | translate}}</div>
          <div class="new-tab-position">
            <!-- <a class="new-tab" href="{{localOnLineCheck?.cgvDocumentUrl}}" target="_blank">{{'online-check.yourCGV' | translate}}</a> -->
            <app-button class="new-tab" [type]="'ternary'" [text]="'online-check.cgv-documents' | translate"
              (debounceClick)="openInNewTabCgv()"></app-button>
          </div>
          <pdf-viewer class="ng2-pdf-viewer-container" [src]="localOnLineCheck?.cgvDocumentUrl" [render-text]="true"
            [original-size]="false" [show-borders]="true" [show-all]="true" [fit-to-page]="true" [stick-to-page]="true">
          </pdf-viewer>
        </div>

      </div>


    </div>

  </div>
</div>
