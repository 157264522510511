/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-gallery-detail-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./guest-gallery-detail-mobile.component";
import * as i4 from "@angular/material/dialog";
var styles_GuestGalleryDetailMobileComponent = [i0.styles];
var RenderType_GuestGalleryDetailMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestGalleryDetailMobileComponent, data: {} });
export { RenderType_GuestGalleryDetailMobileComponent as RenderType_GuestGalleryDetailMobileComponent };
function View_GuestGalleryDetailMobileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "photo-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "photo.caption"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDetailImage(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.url, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_GuestGalleryDetailMobileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestGalleryDetailMobileComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.property == null) ? null : _co.property.photos); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GuestGalleryDetailMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-gallery-detail-mobile", [], null, null, null, View_GuestGalleryDetailMobileComponent_0, RenderType_GuestGalleryDetailMobileComponent)), i1.ɵdid(1, 114688, null, 0, i3.GuestGalleryDetailMobileComponent, [i4.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestGalleryDetailMobileComponentNgFactory = i1.ɵccf("app-guest-gallery-detail-mobile", i3.GuestGalleryDetailMobileComponent, View_GuestGalleryDetailMobileComponent_Host_0, { property: "property" }, {}, []);
export { GuestGalleryDetailMobileComponentNgFactory as GuestGalleryDetailMobileComponentNgFactory };
