<div class="container-desktop" style="margin-bottom: 100px;">



  <!--<div class="y3-top-video-container" *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet && utilService.videoUrlType(property?.matterportUrl) !== DocType.YOUTUBE">
    <iframe style="object-fit: cover; width: 100%; height: calc(550px)" [src]="transform(property?.matterportUrl)"></iframe>
  </div>

  <div style="height: calc(550px)" *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet && utilService.videoUrlType(property?.matterportUrl) === DocType.YOUTUBE">
    <app-external-video-box [videoUrl]="property?.matterportUrl"></app-external-video-box>
  </div>-->

  <div class="y3-top-image-container" >
    <div class="y3-images-container">
      <div class="y3-first-image">

        <section *ngIf="property?.matterportUrl">
          <iframe style="object-fit: cover; width: 100%; height: calc(550px)" [src]="transform(property?.matterportUrl)"></iframe>
        </section>

        <section *ngIf="!property?.matterportUrl">
        <img *ngIf="(!property?.matterportUrl || property.noshowMatterportOnBooklet) && property?.photos && currentImage"
             [@fade]="state" (@fade.done)="onDone($event)"
             class="y3-image fade" src="{{currentImage.url.replace('aki_policy=medium', 'aki_policy=xx_large')}}">
        </section>

        <!--<img class="y3-image" src="{{property.photos[0].url.replace('aki_policy=medium', 'aki_policy=xx_large').replace('aki_policy=large', 'aki_policy=xx_large')}}">-->
      </div>
      <div class="y3-second-and-third-images">
        <div class="y3-second-image">
          <img class="y3-image" src="{{property.photos[1].url.replace('aki_policy=medium', 'aki_policy=xx_large').replace('aki_policy=large', 'aki_policy=xx_large')}}">
        </div>
        <div class="y3-third-image">
          <img class="y3-image" src="{{property.photos[2].url.replace('aki_policy=medium', 'aki_policy=xx_large').replace('aki_policy=large', 'aki_policy=xx_large')}}">
          <div class="y3-flex-column y3-flex-align-end" style="position: absolute; bottom: 10px; right: 10px">
            <app-button *ngIf="property?.matterportUrl && property.noshowMatterportOnBooklet"
                        [text]="'guest-top-page.see-3D' | translate"
                        class="margin-bottom-8"
                        [type]="ButtonType.SHADOW"
                        [leftIcon]="Icons.PhotoCamera"
                        (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

            <app-button *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet"
                        [text]="'guest-top-page.see-diapo' | translate"
                        class="margin-bottom-8"
                        [type]="ButtonType.SHADOW"
                        [leftIcon]="Icons.PhotoCamera"
                        (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

            <app-button *ngIf="property?.photos?.length > 0"
                        [text]="'guest-top-page.see-photos' | translate"
                        [type]="ButtonType.SHADOW"
                        [leftIcon]="Icons.PhotoCamera"
                        (debounceClick)="showImgDialog(photos[0])"></app-button>

            <app-button *ngIf="property?.host?.subscriptionForLivretSeul" [text]="'guest-top-page.leave-btob-review' | translate"
                        [type]="ButtonType.PRIMARY"
                        [routerLink]="['/guest/'+this.bookingId+'/review']"
                        class="margin-top-8"
            ></app-button>
          </div>
        </div>
      </div>
      <div class="y3-second-and-third-images">
        <div class="y3-second-image">
          <img class="y3-image" src="{{property.photos[3].url.replace('aki_policy=medium', 'aki_policy=xx_large').replace('aki_policy=large', 'aki_policy=xx_large')}}">
        </div>
        <div class="y3-third-image">
          <img class="y3-image" src="{{property.photos[4].url.replace('aki_policy=medium', 'aki_policy=xx_large').replace('aki_policy=large', 'aki_policy=xx_large')}}">
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="booking" class="y3-journey-summary ">

    <div class="y3-margin-1024 y3-welcome-display y3-McQueen y3-margin-bottom-8">{{'guest-top-page.welcome' | translate}} {{booking?.guests[0]?.firstName | titlecase}} {{booking?.guests[0]?.lastName | titlecase}}</div>
    <div class="y3-margin-1024 y3-Small y3-bold y3-roboto y3-color-green-vogue">{{property.title}}</div>
    <div class="y3-margin-1024 y3-X-small y3-roboto y3-color-scorpion">{{property.fullAddress}}</div>

    <div class="y3-margin-1024 y3-flex-row y3-roboto y3-margin-top-16" >
      <div class="y3-flex-column y3-roboto y3-check-card" >
        <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Medium">{{'guest-top-page.start' | translate}}</div>
        <div class="y3-color-scorpion y3-Small">{{booking?.startDate | moment: currentLang  | titlecase}} </div>
        <div class="y3-color-scorpion y3-Small">{{getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</div>
      </div>
      <div class="y3-flex-column y3-roboto y3-check-card" >
        <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Medium">{{'guest-top-page.end' | translate}}</div>
        <div class="y3-color-scorpion y3-Small">{{booking?.endDate | moment: currentLang  | titlecase}} </div>
        <div class="y3-color-scorpion y3-Small">{{getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</div>
      </div>
      <!--<div class="y3-flex-column y3-roboto y3-check-card" style="width: 250px" >
        <div class="y3-margin-bottom-6">
          <app-tag *ngIf="isPropertyReady()" [padding]="'8px'" [sizeText2]="'12px'" text2="{{'guest-view-booking-details.logement-ready' | translate}}" [type]="'green'"></app-tag>
          <app-tag *ngIf="!isPropertyReady()" [padding]="'8px'" [sizeText2]="'12px'" text2="{{'guest-view-booking-details.logement-not-ready' | translate}}" [type]="'orange'"></app-tag>
        </div>
        <div class="y3-color-scorpion y3-X-small">{{booking?.nights}} Nuits</div>
        <div class="y3-color-scorpion y3-X-small">{{booking?.adults}} Adulte(s) | {{booking?.children}} Enfant(s) | {{booking.babies}} Bébé(s)</div>
      </div>-->
    </div>

    <!--
    <div class="y3-margin-1024 y3-flex-row y3-margin-top-32">
      <div class="y3-flex-column ">
        <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-flex-justify-content-start">
          <div *ngIf="booking?.startDate !== booking?.endDate" class="y3-date-cards">
            <div class="date-card margin-right-32">
              <img src="assets/images/illustrations/men-fly.svg" alt="start">
              <div class="date-container">
                <p class="title">{{'guest-top-page.start' | translate}}</p>
                <p class="date">{{booking?.startDate | moment: currentLang  | titlecase}}</p>
                <p class="date">{{getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</p>
              </div>
            </div>

            <div *ngIf="!property?.host?.btobInfo" class="y3-nights-container">
              <p class="Small">{{'guest-top-page.nights-desktop' | translate}}</p>
              <p class="number">{{booking?.nights}}</p>
            </div>

            <div class="date-card">
              <img src="assets/images/illustrations/men-luaggage.svg" alt="departure">
              <div class="date-container">
                <p class="title">{{'guest-top-page.end' | translate}}</p>
                <p class="date">{{booking?.endDate | moment: currentLang | titlecase}}</p>
                <p class="date">{{getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <div class="y3-flex-row y3-flex-justify-content-end" style="position: absolute; right:8px">
      <div class="y3-flex-column y3-flex-justify-content-start" style="margin-right: 3px">
        <app-y3-explained-button *ngIf="property?.showGuestInventory && booking?.id && isDuringTheDay()" [title]="'guest-view-booking-details.inventory' | translate" [description]="'Vous allez pouvoir évaluer votre séjour'"></app-y3-explained-button>
      </div>

      <div class="y3-flex-column y3-flex-justify-content-start">
        <!--<app-y3-explained-button *ngIf="!property?.host?.btobInfo && booking?.id" [title]="'Réaliser mon Pré-Check in '" [description]="'Vous allez pouvoir renseigner les informations de départ et d arrivée'" [routerLink]="['/guest/'+this.bookingId+'/booking-setup']"></app-y3-explained-button>-->
        <!--<app-y3-explained-button *ngIf="!property?.host?.btobInfo && !property.noshowGuestReview && booking?.id" [routerLink]="['/guest/'+this.bookingId+'/review']" [title]="'Evaluer mon séjour'" [description]="'Vous allez pouvoir évaluer votre séjour'"></app-y3-explained-button>-->
      </div>

    </div>


  </div>

  <!--<div class="y3-margin-1024 y3-flex-row y3-margin-top-32 y3-flex-justify-space-between ">
    <div *ngIf="property?.showGuestInventory && booking?.id && isDuringTheDay()">
      <app-button  text="{{'guest-view-booking-details.inventory' | translate}}" (debounceClick)="showInventory()" [type]="'secondary'"></app-button>
    </div>
    <div *ngIf="!property?.host?.btobInfo && booking?.id">
      <app-button   [type]="'secondary'" [text]="'guest-top-page.request-btn' | translate" [routerLink]="['/guest/'+this.bookingId+'/booking-setup']"></app-button>
    </div>
    <div *ngIf="!property?.host?.btobInfo && !property.noshowGuestReview && booking?.id">
      <app-button  [text]="'guest-top-page.leave-review' | translate" [type]="ButtonType.SECONDARY" [routerLink]="['/guest/'+this.bookingId+'/review']" ></app-button>
    </div>
  </div>-->




  <!--<div *ngIf="1==2" class="top-image-container y3-divider">

      &lt;!&ndash;<div id="slideshow" class="slideshow">
        <div class="mySlides" >

        </div>
      </div>&ndash;&gt;



    &lt;!&ndash; SLIDER FOR PROPERTY PICTURES &ndash;&gt;
    <div class="divMainPhotoLandingPage" *ngIf="(!property?.matterportUrl || property.noshowMatterportOnBooklet) && property?.photos && currentImage">
      <img *ngIf="(!property?.matterportUrl || property.noshowMatterportOnBooklet) && property?.photos && currentImage"
           [@fade]="state" (@fade.done)="onDone($event)"
           class="mainPhotoLandingPage mainPhotoLandingPageBlur fade" src="{{currentImage.url.replace('aki_policy=medium', 'aki_policy=xx_large')}}">

      <div class="divSecondMainPhotoLandingPage">
        <img *ngIf="(!property?.matterportUrl || property.noshowMatterportOnBooklet) && property?.photos && currentImage"
             [@fade]="state" (@fade.done)="onDone($event)"
             class="secondMainPhotoLandingPage " src="{{currentImage.url.replace('aki_policy=medium', 'aki_policy=xx_large').replace('aki_policy=large', 'aki_policy=xx_large')}}">
      </div>
    </div>

    &lt;!&ndash; DEFAULT MAIN PICTURE &ndash;&gt;
    <img *ngIf="(!property?.matterportUrl || property.noshowMatterportOnBooklet) && property && !property?.photos" class="mainPhotoLandingPage" src="assets/defaut-top-img.png"
         alt="mainPhoto">
      &lt;!&ndash;<img *ngIf="!property?.host?.btobInfo?.matterportUrl && !property?.matterportUrl && property && mainPropertyPhoto" class="mainPhotoLandingPage" [src]="mainPropertyPhoto"
           alt="mainPhoto">
      <img *ngIf="!property?.host?.btobInfo?.matterportUrl && !property?.matterportUrl && property && !mainPropertyPhoto" class="mainPhotoLandingPage" src="assets/defaut-top-img.png"
           alt="mainPhoto">&ndash;&gt;

    &lt;!&ndash;<div *ngIf="property?.host?.btobInfo?.matterportUrl">
      <iframe style="object-fit: fill;
            width: 100%;
            height: calc(796px)" [src]="transform(property?.host?.btobInfo?.matterportUrl)"></iframe>
    </div>&ndash;&gt;

    <div *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet && utilService.videoUrlType(property?.matterportUrl) !== DocType.YOUTUBE">
      <iframe style="object-fit: fill;
            width: 100%;
            height: calc(796px)" [src]="transform(property?.matterportUrl)"></iframe>
    </div>
    <div style="height: calc(796px)" *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet && utilService.videoUrlType(property?.matterportUrl) === DocType.YOUTUBE">
      <app-external-video-box [videoUrl]="property?.matterportUrl"></app-external-video-box>
    </div>

    <div  class="position-container">
      <div *ngIf="booking?.hostWords" class="owner-word">
        <p *ngIf="!property?.host?.btobInfo" class="header">{{'guest-top-page.host-words' | translate}}</p>
        <p *ngIf="property?.host?.btobInfo" class="header">{{'guest-top-page.btob-host-words' | translate}}</p>
        <p class="message">{{booking.hostWords}}</p>
      </div>
      <div class="second-level">

        <app-button *ngIf="property?.matterportUrl && property.noshowMatterportOnBooklet"
                    [text]="'guest-top-page.see-3D' | translate"
                    class="margin-bottom-16"
                    [type]="ButtonType.SHADOW"
                    [leftIcon]="Icons.PhotoCamera"
                    (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

        <app-button *ngIf="property?.matterportUrl && !property.noshowMatterportOnBooklet"
                    [text]="'guest-top-page.see-diapo' | translate"
                    class="margin-bottom-16"
                    [type]="ButtonType.SHADOW"
                    [leftIcon]="Icons.PhotoCamera"
                    (debounceClick)="property.noshowMatterportOnBooklet = !property.noshowMatterportOnBooklet"></app-button>

        <app-button *ngIf="property?.photos?.length > 0"
                    [text]="'guest-top-page.see-photos' | translate"
                    [type]="ButtonType.SHADOW"
                    [leftIcon]="Icons.PhotoCamera"
                    (debounceClick)="showImgDialog(photos[0])"></app-button>

        <app-button *ngIf="property?.host?.subscriptionForLivretSeul" [text]="'guest-top-page.leave-btob-review' | translate"
                    [type]="ButtonType.PRIMARY"
                    [routerLink]="['/guest/'+this.bookingId+'/review']"
                    class="margin-top-16"
        ></app-button>

        <div *ngIf="booking" class="y3-journey-summary y3-margin-top-16">

          <div class="y3-welcome-display y3-McQueen">{{'guest-top-page.welcome' | translate}} {{booking?.guests[0]?.firstName | titlecase}} {{booking?.guests[0]?.lastName | titlecase}}</div>

          <div class="y3-flex-row y3-flex-justify-content-center y3-margin-top-32">
            <div class="y3-flex-column ">
              <div class="y3-flex-row y3-flex-align-center y3-flex-justify-space-between y3-flex-justify-content-start">
                <div *ngIf="booking?.startDate !== booking?.endDate" class="y3-date-cards">
                  <div class="date-card margin-right-32">
                    <img src="assets/images/illustrations/men-fly.svg" alt="start">
                    <div class="date-container">
                      <p class="title">{{'guest-top-page.start' | translate}}</p>
                      <p class="date">{{booking?.startDate | moment: currentLang  | titlecase}}</p>
                      <p class="date">{{getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</p>
                    </div>
                  </div>

                  <div *ngIf="!property?.host?.btobInfo" class="y3-nights-container">
                    <p class="Small">{{'guest-top-page.nights-desktop' | translate}}</p>
                    <p class="number">{{booking?.nights}}</p>
                  </div>

                  <div class="date-card">
                    <img src="assets/images/illustrations/men-luaggage.svg" alt="departure">
                    <div class="date-container">
                      <p class="title">{{'guest-top-page.end' | translate}}</p>
                      <p class="date">{{booking?.endDate | moment: currentLang | titlecase}}</p>
                      <p class="date">{{getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="y3-flex-row y3-margin-top-32 y3-flex-justify-space-between y3-margin-left-0">
          <div *ngIf="property?.showGuestInventory && booking?.id && isDuringTheDay()">
              <app-button  text="{{'guest-view-booking-details.inventory' | translate}}" (debounceClick)="showInventory()" [type]="'secondary'"></app-button>
          </div>
          <div *ngIf="!property?.host?.btobInfo && booking?.id">
              <app-button   [type]="'secondary'" [text]="'guest-top-page.request-btn' | translate" [routerLink]="['/guest/'+this.bookingId+'/booking-setup']"></app-button>
          </div>
          <div *ngIf="!property?.host?.btobInfo && !property.noshowGuestReview && booking?.id">
              <app-button  [text]="'guest-top-page.leave-review' | translate" [type]="ButtonType.SECONDARY" [routerLink]="['/guest/'+this.bookingId+'/review']" ></app-button>
          </div>
        </div>

        &lt;!&ndash;<div *ngIf="booking" class="journey-summary">


          <div class="welcome-container">
            <p class="margin-bottom-16">{{'guest-top-page.welcome' | translate}}</p>
            <p>{{booking?.guests[0]?.firstName | titlecase}} <span *ngIf="property?.host?.btobInfo">{{booking?.guests[0]?.lastName | titlecase}}</span></p>
            <p *ngIf="property?.host?.btobInfo" class="company-name">{{booking?.guests[0]?.companyName | titlecase}}</p>
          </div>
          <div *ngIf="!property?.host?.btobInfo" class="nights-container">
            <p class="Small">{{'guest-top-page.nights-desktop' | translate}}</p>
            <p class="number">{{booking?.nights}}</p>
          </div>
          <div *ngIf="booking?.startDate !== booking?.endDate" class="date-cards">
              <div class="date-card margin-right-16">
                <img src="assets/images/illustrations/men-fly.svg" alt="start">
                <div class="date-container">
                  <p class="title">{{'guest-top-page.start' | translate}}</p>
                  <p class="date">{{booking?.startDate | moment: currentLang  | titlecase}}</p>
                  <p class="date">{{getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</p>
                </div>
              </div>
              <div class="date-card">
                <img src="assets/images/illustrations/men-luaggage.svg" alt="departure">
                <div class="date-container">
                  <p class="title">{{'guest-top-page.end' | translate}}</p>
                  <p class="date">{{booking?.endDate | moment: currentLang | titlecase}}</p>
                  <p class="date">{{getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</p>
                </div>
              </div>
          </div>

          <div *ngIf="booking?.startDate === booking?.endDate" class="date-cards">
            <div class="date-card margin-right-16">
              <img src="assets/images/illustrations/men-fly.svg" alt="start">
              <div class="date-container">
                <p class="title">{{'guest-top-page.your-resa' | translate}}</p>
                <p class="date">{{booking?.startDate  | titlecase}}</p>
              </div>
            </div>

          </div>

          <div class="buttons">
            <app-button *ngIf="!property?.host?.btobInfo"  [text]="'guest-top-page.request-btn' | translate" [routerLink]="['/guest/'+this.bookingId+'/booking-setup']"></app-button>
            <app-button *ngIf="!property?.host?.btobInfo && !property.noshowGuestReview" [text]="'guest-top-page.leave-review' | translate"
                        [type]="ButtonType.SECONDARY_LIGHT"
                        [routerLink]="['/guest/'+this.bookingId+'/review']"
                        class="margin-top-16"
            ></app-button>

            <app-button *ngIf="property?.host?.btobInfo"  [text]="'guest-top-page.request-btob-btn' | translate" [routerLink]="['/guest/'+this.bookingId+'/booking-setup']"></app-button>
            <app-button *ngIf="(property?.host?.btobInfo || property?.host?.subscriptionForLivretSeul) && !property.noshowGuestReview" [text]="'guest-top-page.leave-btob-review' | translate"
                        [type]="ButtonType.SECONDARY_LIGHT"
                        [routerLink]="['/guest/'+this.bookingId+'/review']"
                        class="margin-top-16"
            ></app-button>
          </div>
        </div>&ndash;&gt;
      </div>
    </div>
  </div>-->
</div>
