import { Component, HostListener, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-frenchconcierge-footer',
  templateUrl: './frenchconcierge-footer.component.html',
  styleUrls: ['./frenchconcierge-footer.component.scss']
})
export class FrenchconciergeFooterComponent implements OnInit {

  constructor(
    private utilService:UtilsService
  ) {  this.getScreenWidth()}
  
  screenWidth: number;

  ngOnInit() {
  }
  isDesktopMode(): boolean {
    return this.utilService.isDesktop(this.screenWidth);
  }
  @HostListener("window:resize", ["$event"])
  getScreenWidth(event?) {
    this.screenWidth = window.innerWidth;
  }
}
