/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./y3-landing-guides.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/components/tabs-menu/tabs-menu.component.ngfactory";
import * as i5 from "../../../shared/components/tabs-menu/tabs-menu.component";
import * as i6 from "./y3-landing-guides.component";
import * as i7 from "../../../core/services/IntercomService";
import * as i8 from "../../../core/services/utils.service";
import * as i9 from "../guest.service";
import * as i10 from "@angular/material/dialog";
var styles_Y3LandingGuidesComponent = [i0.styles];
var RenderType_Y3LandingGuidesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Y3LandingGuidesComponent, data: {} });
export { RenderType_Y3LandingGuidesComponent as RenderType_Y3LandingGuidesComponent };
function View_Y3LandingGuidesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("guest-guid-page.title")); _ck(_v, 1, 0, currVal_0); }); }
function View_Y3LandingGuidesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section.sectionTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_Y3LandingGuidesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["WIFI: "]))], null, null); }
function View_Y3LandingGuidesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "y3-guide-bloc y3-clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showGuide(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "guide"], ["class", "y3-important-guide-picture"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "y3-text-guide-bloc y3-bold"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3LandingGuidesComponent_5)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", ""])), i1.ɵppd(6, 1)], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.category === "wifi"); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getGuideCover(_v.parent.context.$implicit), ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.translations[0].title)); _ck(_v, 5, 0, currVal_2); }); }
function View_Y3LandingGuidesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3LandingGuidesComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.needToShowGuide(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_Y3LandingGuidesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "y3-container "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3LandingGuidesComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3LandingGuidesComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "width-100 margin-top-44 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-tabs-menu", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onTabMenuSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TabsMenuComponent_0, i4.RenderType_TabsMenuComponent)), i1.ɵdid(10, 638976, null, 0, i5.TabsMenuComponent, [], { tabs: [0, "tabs"] }, { selected: "selected" }), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "y3-flex-responsive y3-flex-row-wrap y3-margin-top-16"], ["style", "min-height: 240px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3LandingGuidesComponent_3)), i1.ɵdid(13, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.section; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.section; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.categoryTabs; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.currentGuidesList; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "y3-divider ", (((_co.curIndex % 2) === 0) ? "y3-silver" : "y3-white"), ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_Y3LandingGuidesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-y3-landing-guides", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_Y3LandingGuidesComponent_0, RenderType_Y3LandingGuidesComponent)), i1.ɵdid(1, 638976, null, 0, i6.Y3LandingGuidesComponent, [i7.IntercomService, i8.UtilsService, i9.GuestServiceIntern, i10.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Y3LandingGuidesComponentNgFactory = i1.ɵccf("app-y3-landing-guides", i6.Y3LandingGuidesComponent, View_Y3LandingGuidesComponent_Host_0, { booking: "booking", property: "property", corporate: "corporate", curIndex: "curIndex", section: "section" }, {}, []);
export { Y3LandingGuidesComponentNgFactory as Y3LandingGuidesComponentNgFactory };
