import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/guest.service";
import * as i2 from "@angular/router";
import * as i3 from "../../core/services/internal.property.service";
import * as i4 from "./guest.service";
import * as i5 from "../../core/services/SecurityService";
export class GuestResolverService {
    constructor(guestService, router, propertyService, internGuestService, securityService
    // private errorHandler: BugsnagChunkLoadErrorHandler
    ) {
        this.guestService = guestService;
        this.router = router;
        this.propertyService = propertyService;
        this.internGuestService = internGuestService;
        this.securityService = securityService;
    }
    resolve(route, state) {
        const bookingId = route.children[0].params.bookingId;
        const lang = route.children[0].queryParams.lang ? route.children[0].queryParams.lang : undefined;
        return this.guestService.getPropertyAndBookingForGuest(bookingId, lang).pipe(mergeMap(res => {
            this.propertyService.setCurrentProperty(res.property);
            this.propertyService.localProperty = res.property;
            this.internGuestService.setBookingForGuest(res.booking);
            this.guestService.sendCorporate(res.corporate);
            this.propertyService.setCurrentCorporate(res.corporate);
            let urlForGuides = false;
            if (state.url.indexOf('/guides/') > 0) {
                urlForGuides = true;
            }
            if (res.booking != null) {
                /*if (res.property.lightOnLinePreCheck && !res.booking.lightOnLinePreCheckDone && state.url.indexOf('/messaging') < 0) {
                  return this.router.navigate(['/onlinecheck/light/' + bookingId]);
                } else if (res.booking.onLineCheck != null && res.booking.onLineCheck.checkStatus != null && !['VALIDATED', 'SKIPPED'].includes(res.booking.onLineCheck.checkStatus) && state.url.indexOf('/messaging') < 0) {
                    return this.router.navigate(['/onlinecheck/' + bookingId]);
                }*/
            }
            if (!res.property.host.subscriptionForLivretSeul) {
                console.log('Security Password: ', this.securityService.getSecurityPassword());
                if (!urlForGuides && res.booking == null && route && this.securityService.getSecurityPassword() !== 'YES' && res.corporate != null && res.corporate.bookletMasterKey != null && res.corporate.homePageSettings === undefined) {
                    // console.log('route', route.children[0].url);
                    // console.log('No Booking');
                    // console.log('PageSettings', res.corporate.homePageSettings);
                    return this.router.navigate(['/pwd/' + bookingId + '/ask4pwd']);
                }
                else if (!urlForGuides && res.booking == null && route && this.securityService.getSecurityPassword() !== 'YES' && res.corporate != null && res.corporate.bookletMasterKey != null && res.corporate.homePageSettings && state.url.split('/')[state.url.split('/').length - 1] !== 'livret-welcome') {
                    return this.router.navigate(['/guest/' + bookingId + '/livret-welcome']);
                }
            }
            if (res.booking != null) {
                // console.log('Current Routes: ', state.url);
                // console.log('res.booking.welcomePageSeen', res.booking.welcomePageSeen);
                if (localStorage.getItem("WelcomeSeen") == "false" && res.corporate.homePageSettings !== undefined && state.url.split('/')[state.url.split('/').length - 1] !== 'livret-welcome') {
                    // console.log('res.corporate.homePageSettings', res.corporate.homePageSettings);
                    return this.router.navigate(['/guest/' + bookingId + '/livret-welcome']);
                }
                else if (res.booking.welcomePageSeen === false && state.url.split('/')[state.url.split('/').length - 1] === 'livret-welcome' && !res.corporate.homePageSettings) {
                    return this.router.navigate(['/guest/' + bookingId]);
                }
                else if (res.booking.welcomePageSeen === true && state.url.split('/')[state.url.split('/').length - 1] === 'livret-welcome') {
                    // console.log('imhere');
                    return this.router.navigate(['/guest/' + bookingId]);
                }
            }
            // this.errorHandler.addMetadata(res.property.id, res.booking.id);
            return of(res);
        }), catchError((err) => {
            console.log('Error:', err);
            return this.router.navigate(['/misc/lost']);
        }));
    }
}
GuestResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuestResolverService_Factory() { return new GuestResolverService(i0.ɵɵinject(i1.GuestService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.InternalPropertyService), i0.ɵɵinject(i4.GuestServiceIntern), i0.ɵɵinject(i5.SecurityService)); }, token: GuestResolverService, providedIn: "root" });
