import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BookingForGuest, TeammateInCharge} from '../../../models/guestview/BookingForGuest';
import {CoHostInCharge, PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {IntercomService} from '../../../core/services/IntercomService';
import {UtilsService} from '../../../core/services/utils.service';
import {MatDialog} from '@angular/material';
import {Poi} from '../../../models/Poi';
import {Property} from '../../../models/Property';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {SnackbarService} from '../../../core/services/SnackbarService';
import * as moment from 'moment/moment';
import {Router} from '@angular/router';
import {PersonForGuest} from '../../../models/guestview/PersonForGuest';
import {CorporateBookletSection} from '../../../models/CorporateBookletSection';

@Component({
  selector: 'app-y3-booking-details',
  templateUrl: './y3-booking-details.component.html',
  styleUrls: ['./y3-booking-details.component.scss']
})
export class Y3BookingDetailsComponent extends AbstractWindow implements OnInit, OnChanges {

  centerOfTheMap: Poi;
  propertyPoi: Poi;
  language = 'fr';
  localShowMeAsHost = true;
  public localCoHostInCharge: CoHostInCharge = null;

  showingMoreDescription = false;
  @Input() booking: BookingForGuest;
  @Input() property: PropertyForGuest;
  @Input() corporate: Corporate;
  @Input() section: CorporateBookletSection;

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              public translateService: TranslateService,
              public snackBarService: SnackbarService,
              public router: Router,
              protected dialog: MatDialog) {
    super(intercomService, utilsService);
  }

  ngOnInit() {
    this.language = this.translateService.currentLang;
    this.localShowMeAsHost = this.corporate.showMeAsHost;
    if (this.corporate && this.corporate.coHostInCharge) {
      this.localCoHostInCharge = this.corporate.coHostInCharge;
    } else if (this.property && this.property.coHostInCharge) {
      this.localCoHostInCharge = this.property.coHostInCharge;
    } else {
      this.localCoHostInCharge = null;
      this.localShowMeAsHost = !this.corporate.showMeAsHost;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.property && this.property) {
      if (this.property.poi) {
        this.centerOfTheMap = new Poi();
        this.centerOfTheMap.x = this.property.poi.x;
        this.centerOfTheMap.y = this.property.poi.y
        this.propertyPoi = this.property.poi;
      }
    }
  }

  openToPosition(distGps) {
    UtilsService.openLinkInNewTab('https://www.google.com/maps?q=' + distGps);
  }
  getDirection(currentGps, distGps) {
    UtilsService.openLinkInNewTab('https://www.google.com/maps/dir/' + currentGps + '/' + distGps);
  }

  findDirection() {
    const distGps = this.property.poi.y + ',' + this.property.poi.x;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("position", position);
        const lat = position.coords.latitude;
        const long = position.coords.longitude;

        const currentGps = lat + ',' + long;
        this.getDirection(currentGps, distGps);
      }, err => {
        this.openToPosition(distGps);
      });
    } else {
      this.openToPosition(distGps);
    }
  }

  getLanguage(code: string) {
    return UtilsService.getLanguageFlag(code);
  }


  counter(type: 'travelers' | 'beds' | 'baths' | 'doublebeds' | 'singlebeds' | 'sofabeds') {
    switch (type) {
      case 'travelers':
        const travelersCount = this.booking.adults + this.booking.children + this.booking.babies;
        return travelersCount > 0 ? travelersCount : (this.booking.guests ? this.booking.guests.length : 0);

      case 'beds':
        return this.property.doubleBeds + this.property.singleBeds + this.property.sofaBeds + this.property.babyBeds;
      case 'doublebeds':
        return this.property.doubleBeds;
      case 'singlebeds':
        return this.property.singleBeds;
      case 'sofabeds':
        return this.property.sofaBeds;
      case 'baths':
        return this.property.bathrooms;
    }
  }

  callTeammate(use: TeammateInCharge | CoHostInCharge) {
    document.location.href = 'tel:' + use.phoneNumber;
  }

  emailPerson(use: PersonForGuest | TeammateInCharge | CoHostInCharge) {
    document.location.href = 'mailto:' + use.email;
  }

  call(host: PersonForGuest | undefined) {
    if (!host) {
      return;
    }
    document.location.href = 'tel:' + host.phone;
  }
}
