/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./loader.component";
import * as i5 from "../../../core/services/LoaderService";
import * as i6 from "../../../core/services/IntercomService";
import * as i7 from "../../../core/services/utils.service";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
function View_LoaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["class", "loader-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("loader.loading")); _ck(_v, 1, 0, currVal_0); }); }
function View_LoaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["class", "loader-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.mainText)); _ck(_v, 1, 0, currVal_0); }); }
function View_LoaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.corporate.logoUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_LoaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"], ["src", "/assets/img/favicon_updated.png"]], null, null, null, null, null))], null, null); }
function View_LoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "loader-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "loader-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["style", "position: absolute; top: 6px; right: 8px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "assets/icon/close-round.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeLoader() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_3)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "text-large margin-top-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [["class", "animation-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_4)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_5)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 0, "div", [["class", "dot1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "dot2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "div", [["class", "dot3"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.mainText; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.mainText; _ck(_v, 8, 0, currVal_1); var currVal_3 = ((_co.corporate == null) ? null : _co.corporate.logoUrl); _ck(_v, 14, 0, currVal_3); var currVal_4 = !((_co.corporate == null) ? null : _co.corporate.logoUrl); _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("loader.please_wait")); _ck(_v, 10, 0, currVal_2); }); }
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showFull; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 245760, null, 0, i4.LoaderComponent, [i5.LoaderService, i6.IntercomService, i7.UtilsService, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i4.LoaderComponent, View_LoaderComponent_Host_0, { corporate: "corporate" }, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
