<div class="y3-divider {{(curIndex%2===0)?'y3-silver':'y3-white'}}">
  <div class="y3-container " >

    <div class="y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24">
      {{'guest-platefromservices-page.title' | translate}}
    </div>

    <!--
    <div class="y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-responsive y3-flex-justify-content-center ">
      <div style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{'guest-platefromservices-page.title' | translate}}" [type]="'deproprietaire'"></app-tag>
      </div>
    </div>
    -->


    <div class="y3-flex-responsive">
      <div class="y3-service-card" *ngFor="let p of platformServices; let i = index">
        <div class="y3-service-image-container">
          <img [id]="'platformLogo' + i" class="y3-service-image" [src]="p.logoUrl" alt="partner-img"/>
        </div>
        <div class="y3-card-description">
          <div class="y3-service-title">
            {{p.name}}
          </div>
          <div class="y3-service-description">
            {{p.translations[0].description}}
          </div>

          <div class="partner-link y3-margin-top-8">
            <a [id]="'platformLink' + i" class="alink-no-decoration " target="_blank" [href]="p.translations[0].webSiteUrl">{{'guest-platefromservices-page.more-btn'  | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
