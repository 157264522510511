import {Component, Input, OnInit} from '@angular/core';
import {BookingForGuest} from "../../../models/guestview/BookingForGuest";
import {PropertyForGuest} from "../../../models/guestview/PropertyForGuest";
import {TranslateService} from "@ngx-translate/core";
import {AbstractWindow} from "../../../core/abstract/AbstractWindow";
import {IntercomService} from "../../../core/services/IntercomService";
import {UtilsService} from "../../../core/services/utils.service";
import * as util from "util";
import {utils} from "protractor";

@Component({
  selector: 'app-online-check-welcome-bar',
  templateUrl: './online-check-welcome-bar.component.html',
  styleUrls: ['./online-check-welcome-bar.component.scss']
})
export class OnlineCheckWelcomeBarComponent extends AbstractWindow implements OnInit {

  @Input() booking: BookingForGuest;
  @Input() property: PropertyForGuest;

  constructor(public translateService: TranslateService,
              public intercomService: IntercomService,
              public utilsService: UtilsService) {
    super(intercomService, utilsService);
  }

  ngOnInit() {
  }

}
