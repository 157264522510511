/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./y3-plaform-services.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./y3-plaform-services.component";
import * as i5 from "../../../core/services/IntercomService";
import * as i6 from "../../../core/services/utils.service";
import * as i7 from "@angular/material/dialog";
var styles_Y3PlaformServicesComponent = [i0.styles];
var RenderType_Y3PlaformServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Y3PlaformServicesComponent, data: {} });
export { RenderType_Y3PlaformServicesComponent as RenderType_Y3PlaformServicesComponent };
function View_Y3PlaformServicesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "y3-service-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "y3-service-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "partner-img"], ["class", "y3-service-image"]], [[8, "id", 0], [8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "y3-card-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "y3-service-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "y3-service-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "partner-link y3-margin-top-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "alink-no-decoration "], ["target", "_blank"]], [[8, "id", 0], [8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = ("platformLogo" + _v.context.index); var currVal_1 = _v.context.$implicit.logoUrl; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.translations[0].description; _ck(_v, 7, 0, currVal_3); var currVal_4 = ("platformLink" + _v.context.index); var currVal_5 = _v.context.$implicit.translations[0].webSiteUrl; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("guest-platefromservices-page.more-btn")); _ck(_v, 10, 0, currVal_6); }); }
export function View_Y3PlaformServicesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "y3-container "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "y3-flex-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3PlaformServicesComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.platformServices; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "y3-divider ", (((_co.curIndex % 2) === 0) ? "y3-silver" : "y3-white"), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("guest-platefromservices-page.title")); _ck(_v, 3, 0, currVal_1); }); }
export function View_Y3PlaformServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-y3-plaform-services", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_Y3PlaformServicesComponent_0, RenderType_Y3PlaformServicesComponent)), i1.ɵdid(1, 114688, null, 0, i4.Y3PlaformServicesComponent, [i5.IntercomService, i6.UtilsService, i7.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Y3PlaformServicesComponentNgFactory = i1.ɵccf("app-y3-plaform-services", i4.Y3PlaformServicesComponent, View_Y3PlaformServicesComponent_Host_0, { platformServices: "platformServices", property: "property", curIndex: "curIndex" }, {}, []);
export { Y3PlaformServicesComponentNgFactory as Y3PlaformServicesComponentNgFactory };
