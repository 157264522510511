/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./yaago2-powered-by-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./yaago2-powered-by-footer.component";
import * as i3 from "../../../core/services/IntercomService";
import * as i4 from "../../../core/services/utils.service";
var styles_Yaago2PoweredByFooterComponent = [i0.styles];
var RenderType_Yaago2PoweredByFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Yaago2PoweredByFooterComponent, data: {} });
export { RenderType_Yaago2PoweredByFooterComponent as RenderType_Yaago2PoweredByFooterComponent };
export function View_Yaago2PoweredByFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "width-100 blue-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Powered By\u00A0\u00A0"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "margin-left-8 txt-color-white"], ["href", "https://yaago.com"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Yaago"]))], null, null); }
export function View_Yaago2PoweredByFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-yaago2-powered-by-footer", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_Yaago2PoweredByFooterComponent_0, RenderType_Yaago2PoweredByFooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.Yaago2PoweredByFooterComponent, [i3.IntercomService, i4.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Yaago2PoweredByFooterComponentNgFactory = i1.ɵccf("app-yaago2-powered-by-footer", i2.Yaago2PoweredByFooterComponent, View_Yaago2PoweredByFooterComponent_Host_0, { corporate: "corporate" }, {}, []);
export { Yaago2PoweredByFooterComponentNgFactory as Yaago2PoweredByFooterComponentNgFactory };
