<footer class="et-l et-l--footer">
    <div class="et_builder_inner_content et_pb_gutters3">

        <div class="et_pb_section et_pb_section_1_tb_footer et_pb_with_background et_section_regular">




            <div class="et_pb_row et_pb_row_2_tb_footer et_pb_equal_columns">
                <div
                    class="et_pb_column et_pb_column_1_2 et_pb_column_3_tb_footer  et_pb_css_mix_blend_mode_passthrough">


                    <div class="et_pb_module et_pb_image et_pb_image_2_tb_footer">


                        <span class="et_pb_image_wrap "><img loading="lazy" width="1796" height="550"
                                src="https://sh-developpement.fr/wp-content/uploads/2022/02/SHDev_Logo-exe2_white.png"
                                alt="logo stay home developpement" title="SHDev_Logo exe2_white"
                                srcset="https://sh-developpement.fr/wp-content/uploads/2022/02/SHDev_Logo-exe2_white.png 1796w, https://sh-developpement.fr/wp-content/uploads/2022/02/SHDev_Logo-exe2_white-1280x392.png 1280w, https://sh-developpement.fr/wp-content/uploads/2022/02/SHDev_Logo-exe2_white-980x300.png 980w, https://sh-developpement.fr/wp-content/uploads/2022/02/SHDev_Logo-exe2_white-480x147.png 480w"
                                sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) and (max-width: 1280px) 1280px, (min-width: 1281px) 1796px, 100vw"
                                class="wp-image-691"></span>
                    </div>
                </div>
                <div
                    class="et_pb_column et_pb_column_1_2 et_pb_column_4_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">


                    <div
                        class="et_pb_module et_pb_text et_pb_text_1_tb_footer  et_pb_text_align_left et_pb_text_align_center-tablet et_pb_bg_layout_light">


                        <div class="et_pb_text_inner">
                            <p>6 rue des coquelicots<br>
                                71530 CHAMPFORGEUIL<br>
                              <a href="mailto:contact@my-french-concierge.com" target="_blank"
                              ><span class="text-primary"
                              >contact@sh-developpement.fr</span
                              ></a>
                                <br>
                                07 78 32 59 76</p>
                        </div>
                    </div>
                </div>


            </div>


        </div>
        <div class="et_pb_section et_pb_section_2_tb_footer et_section_regular">




            <div class="et_pb_row et_pb_row_3_tb_footer">
                <div
                    class="et_pb_column et_pb_column_4_4 et_pb_column_5_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">


                    <div
                        class="et_pb_module et_pb_text et_pb_text_2_tb_footer  et_pb_text_align_center et_pb_bg_layout_light">


                        <div class="et_pb_text_inner">
                            <p><a href="https://sh-developpement.fr/mentions-legales-politique-de-confidentialite">Mentions
                                    légales et politique de confidentialité</a> | <a
                                    href="https://sh-developpement.fr/sitemap_index.xml">Sitemap</a></p>
                            <!--<p>Création et réalisation : <a href="https://www.emelista.fr/">Agence Émélista</a></p>-->
                        </div>
                    </div>
                </div>


            </div>


        </div>
    </div>
</footer>

