<div class="y3-divider {{(curIndex%2===0)?'y3-silver':'y3-white'}}">
  <div class="y3-container " >

    <div *ngIf="!section" class="y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24">
      {{property?.showMeAsHost ? property.host.firstName : property?.coHostInCharge?.firstName}} {{'guest-view-recommendation.you-recommend' | translate}}
    </div>
    <div *ngIf="section" class="y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24">
      {{section.sectionTitle}}
    </div>

    <!--<div class="y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-responsive y3-flex-justify-content-center ">
      <div style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{property?.showMeAsHost ? property.host.firstName : property?.coHostInCharge?.firstName}} {{'guest-view-recommendation.you-recommend' | translate}}" [type]="'deproprietaire'"></app-tag>
      </div>
    </div>-->

    <section *ngIf="!section">
      <div *ngIf="recommendationSearch.onlyMine && !property?.host?.btobInfo" class="y3-Small sous-titre">{{'guest-view-recommendation.select-category' | translate}}</div>
      <div *ngIf="!recommendationSearch.onlyMine && !property?.host?.btobInfo" class="y3-Small sous-titre">{{'guest-view-recommendation.select-host-category' | translate}}</div>
    </section>
    <section *ngIf="section">
      <div class="y3-Small sous-titre">{{section.sectionSubTitle}}</div>
    </section>



    <div class="y3-flex-responsive y3-flex-justify-space-between y3-flex-align-start">

      <mat-form-field class="pr-1" appearance="fill" >
        <mat-label>{{'guest-guid-page.select-category' | translate}} </mat-label>
        <mat-select [ngModelOptions]="{standalone: true}" [ngModel]="selectedCategory" (selectionChange)="categorySelectionChange($event.value)" (ngModelChange)="selectedCategory"  >
          <mat-option *ngFor="let cat of categories"  [value]="cat.key" style="display: flex; flex-flow: row;align-items: center">
            <img *ngIf="cat.key != 'all'" class="img-small-category" src="{{'./assets/icon/recommendation/blue/' + cat.key + '.svg'}}">
            <span> {{'recommendation.' + cat.key | translate}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <app-button [type]="'secondary'" text="{{'guest-view-recommendation.see-on-map' | translate}}" (debounceClick)="showMap()" ></app-button>
      </div>
    </div>

    <section #SectionRecommendationCard>
      <div>
        <div class="new-recommendation-card-container y3-flex-responsive" >
          <div class="new-recommendation-card"
               (click)="openCard(recommendation); onClickOnRecomendationItemAtPage(recommendation)"
               *ngFor="let recommendation of newRecommendations">
            <img class="new-reco-photo" src="{{recommendation.mainPhoto == null ? 'assets/icon/recommendation/empty/' + recommendation.category.key : recommendation.mainPhoto}}"
                 onerror="">

            <div class="new-reco-descr">
              <div class="header1 truncate-text">
                <div class="reco-title truncate-text">{{recommendation.title }}</div>
                <div class="category-container" style="margin-left: 8px">
                  <img class="img-star" src="assets/icon/icon-rate-star.svg">
                  <div class="distance">{{recommendation.ranking.rating}}</div>
                </div>
              </div>

              <div class="header1 truncate-text" style="margin-top: 8px">
                <div class="category-container">
                  <img class="img-small-category" src="{{'./assets/icon/recommendation/map-cluster-' + recommendation.category.key + '.svg'}}">
                  <div class="category truncate-text">{{('recommendation.' + recommendation.category.key) | translate}}</div>
                </div>
                <div class="distance" style="width: 70px">({{'guest-view-recommendation.at' | translate}} {{recommendation.distance}} km)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>


<app-guest-recommendations-map></app-guest-recommendations-map>
