import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Console } from 'console';
import { takeUntil } from 'rxjs/operators';
import { AbstractWindow } from 'src/app/core/abstract/AbstractWindow';
import { GuestService } from 'src/app/core/services/guest.service';
import { IntercomService } from 'src/app/core/services/IntercomService';
import { InternalPropertyService } from 'src/app/core/services/internal.property.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { BookingForGuest } from 'src/app/models/guestview/BookingForGuest';
import { Corporate, HomePageSettings } from 'src/app/models/guestview/Corporate';
import { PropertyAndBookingForGuestDto } from 'src/app/models/guestview/PropertyAndBookingForGuestDto';
import { Property } from 'src/app/models/Property';
import { GuestServiceIntern } from '../guest.service';

@Component({
  selector: 'app-guest-welcome-livret',
  templateUrl: './guest-welcome-livret.component.html',
  styleUrls: ['./guest-welcome-livret.component.scss']
})
export class LivretWelcomeComponent extends AbstractWindow implements OnInit {
  corporate: Corporate;
  homeSettings : any
  screenWidth: number;
  propertyDetails ;
  bookingId : string;
  year = new Date().getFullYear();
  bookingNull : boolean
  propertyId : string
  fortest : any
  constructor(
    public intercomService: IntercomService,
    public propertyService: InternalPropertyService,
    public utilService: UtilsService,
    public route: ActivatedRoute,
    public guestService: GuestService,
    public router: Router,
    private internGuestService: GuestServiceIntern,
    private translateService: TranslateService,

  ) {
  super(intercomService, utilService)
   }

  ngOnInit() {
     this.internGuestService.getBookingForGuest().subscribe(res => {
      console.log('this.globalfortest', res)
      if (res != null){
        this.bookingNull = false

      }
     });
    const corporate = this.propertyService.getCurrentCorporate();
    if (corporate) {
      this.homeSettings = corporate.homePageSettings;
      console.log("this.homeSettings",this.homeSettings)
      this.utilsService.manageCorporateColors(corporate);

    }
      this.propertyDetails =  this.propertyService.localProperty
      this.propertyId = this.propertyService.localProperty.id
      console.log("this.propertyId",this.propertyId)
      this.route.paramMap.subscribe( ParamMap => {
        this.bookingId = ParamMap.get('bookingId');
      });



  }
  navigateToLivret(){
    //add for test
    if ( this.bookingNull === false ){
      localStorage.setItem("WelcomeSeen", "true");
      this.router.navigate(['/guest/' + this.bookingId ]);
    /*this.guestService
    .getPropertyAndBookingForGuestWelcomeSeen(this.bookingId)
    .subscribe((res) => {
      console.log("resres",res)
      if (res.booking.welcomePageSeen === true){
        this.router.navigate(['/guest/' + this.bookingId ]);
       }
    }
    );*/
  }else {
    this.navigateToPass()
  }
  }
  navigateToPass(){
    return this.router.navigate(['/pwd/'+this.propertyId+'/ask4pwd']);
  }
}
