/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-recommendations-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./guest-recommendations-view-desktop/guest-recommendations-view-desktop.component.ngfactory";
import * as i3 from "./guest-recommendations-view-desktop/guest-recommendations-view-desktop.component";
import * as i4 from "../../../../core/services/utils.service";
import * as i5 from "../../../../core/services/guest.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../core/services/language.service";
import * as i8 from "../../../../core/services/collect-data-service.service";
import * as i9 from "./guest-recommendations-view-mobile/guest-recommendations-view-mobile.component.ngfactory";
import * as i10 from "./guest-recommendations-view-mobile/guest-recommendations-view-mobile.component";
import * as i11 from "@angular/common";
import * as i12 from "./guest-recommendations-view.component";
var styles_GuestRecommendationsViewComponent = [i0.styles];
var RenderType_GuestRecommendationsViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestRecommendationsViewComponent, data: {} });
export { RenderType_GuestRecommendationsViewComponent as RenderType_GuestRecommendationsViewComponent };
function View_GuestRecommendationsViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-view-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GuestRecommendationsViewDesktopComponent_0, i2.RenderType_GuestRecommendationsViewDesktopComponent)), i1.ɵdid(1, 114688, null, 0, i3.GuestRecommendationsViewDesktopComponent, [i4.UtilsService, i5.GuestService, i6.TranslateService, i1.NgZone, i7.LanguageManagementService, i8.CollectDataServiceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_GuestRecommendationsViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-view-mobile", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_GuestRecommendationsViewMobileComponent_0, i9.RenderType_GuestRecommendationsViewMobileComponent)), i1.ɵdid(1, 114688, null, 0, i10.GuestRecommendationsViewMobileComponent, [i4.UtilsService, i5.GuestService, i6.TranslateService, i1.NgZone, i7.LanguageManagementService, i8.CollectDataServiceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_GuestRecommendationsViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mytmpl: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["mytemplate", 1]], null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecommendationsViewComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GuestRecommendationsViewComponent_2)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isDesktopMode() && !((_co.property == null) ? null : _co.property.noshowRecommendationOnBooklet)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.isDesktopMode() && !((_co.property == null) ? null : _co.property.noshowRecommendationOnBooklet)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_GuestRecommendationsViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-view", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestRecommendationsViewComponent_0, RenderType_GuestRecommendationsViewComponent)), i1.ɵdid(1, 114688, null, 0, i12.GuestRecommendationsViewComponent, [i4.UtilsService, i5.GuestService, i6.TranslateService, i1.NgZone, i7.LanguageManagementService, i8.CollectDataServiceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestRecommendationsViewComponentNgFactory = i1.ɵccf("app-guest-recommendations-view", i12.GuestRecommendationsViewComponent, View_GuestRecommendationsViewComponent_Host_0, {}, {}, []);
export { GuestRecommendationsViewComponentNgFactory as GuestRecommendationsViewComponentNgFactory };
