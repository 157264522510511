import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from './components/button/button.component';
import {
  MatBadgeModule,
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSliderModule
} from '@angular/material';
import {SharedSnackbarComponent} from './components/shared-snackbar/shared-snackbar.component';
import {TranslateModule} from '@ngx-translate/core';
import {GuestGalleryDetailComponent} from './components/guest-gallery-detail/guest-gallery-detail.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedDirectivesModule} from '../directives/shared-directives.module';
import {AmenitiesCounterComponent} from './components/amenities-counter/amenities-counter.component';
import {TooltipComponent} from './components/tooltip/tooltip.component';
import {ClipboardModule} from 'ngx-clipboard';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import { AudioPlayComponent } from './components/audio-play/audio-play.component';
import {VbcaFooterComponent} from './components/footers/vbca-footer/vbca-footer.component';
import { CotoonFooterComponent } from './components/footers/cotoon-footer/cotoon-footer.component';
import {TabsMenuComponent} from './components/tabs-menu/tabs-menu.component';
import {ExternalVideoBoxComponent} from "./components/external-video-box/external-video-box.component";
import {QrCodeComponent} from "./components/qr-code/qr-code.component";
import {SharedUploadComponent} from './components/shared-upload/shared-upload.component';
import {LoaderComponent} from "./components/loader/loader.component";
import {LottieModule} from "ngx-lottie";
import { RentatranquilleFooterComponent } from './components/footers/rentatranquille/rentatranquille-footer.component';
import { GuestAdomFooterComponent } from './components/footers/guest-adom/guest-adom-footer.component';
import { KeylodgeFooterComponent } from './components/footers/keylodge/keylodge-footer.component';
import { VillabaliFooterComponent } from './components/footers/villabali-footer/villabali-footer.component';
import { VillasduluberonFooterComponent } from './components/footers/villasduluberon-footer/villasduluberon-footer.component';
import { FrenchconciergeFooterComponent } from './components/footers/frenchconcierge-footer/frenchconcierge-footer.component';
import { Yaago2YaagoFooterComponent } from '../modules/guest/yaago2-yaago-footer/yaago2-yaago-footer.component';
import { Yaago2PoweredByFooterComponent } from '../modules/guest/yaago2-poweredby-footer/yaago2-powered-by-footer.component';
import {LuckeychalonFooterComponent} from "./components/footers/luckeychalon-footer/luckeychalon-footer.component";
import { GitesDeFranceComponent } from './components/footers/gites-de-france/gites-de-france.component';
import { LeafletMapComponent } from './components/leaflet-map/leaflet-map.component';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { HaloconciergeComponent } from './components/footers/haloconcierge/haloconcierge.component';
import {TagComponent} from './components/tag/tag.component';
import { Y3ExplainedButtonComponent } from './components/y3-explained-button/y3-explained-button.component';



@NgModule({
  declarations: [ButtonComponent, SharedSnackbarComponent, GuestGalleryDetailComponent,
  AmenitiesCounterComponent,
    SharedUploadComponent,
  TooltipComponent, VbcaFooterComponent,
  AudioPlayComponent,
  CotoonFooterComponent,
    ExternalVideoBoxComponent,
    QrCodeComponent,
    LoaderComponent,
    TabsMenuComponent,
    RentatranquilleFooterComponent,
    GuestAdomFooterComponent,
    KeylodgeFooterComponent,
    VillabaliFooterComponent,
    VillasduluberonFooterComponent,
    FrenchconciergeFooterComponent,
    Yaago2PoweredByFooterComponent,
    LuckeychalonFooterComponent,
    Yaago2YaagoFooterComponent,
    GitesDeFranceComponent,
    LeafletMapComponent,
    HaloconciergeComponent,
    TagComponent,
    Y3ExplainedButtonComponent
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule,
        RouterModule,
        SharedDirectivesModule,
        ClipboardModule,
        NgxGoogleAnalyticsModule,
        LottieModule,
        SharedPipesModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule

    ],
    exports: [
        CommonModule,
        TranslateModule,
        MatButtonModule,
        MatBadgeModule,
        ButtonComponent,
        SharedSnackbarComponent,
        GuestGalleryDetailComponent,
        SharedUploadComponent,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedDirectivesModule,
        AmenitiesCounterComponent,
        TooltipComponent,
        ClipboardModule,
        NgxGoogleAnalyticsModule,
        AudioPlayComponent,
        VbcaFooterComponent,
        CotoonFooterComponent,
        ExternalVideoBoxComponent,
        QrCodeComponent,
        TabsMenuComponent,
        LoaderComponent,
        RentatranquilleFooterComponent,
        GuestAdomFooterComponent,
        KeylodgeFooterComponent,
        VillabaliFooterComponent,
        VillasduluberonFooterComponent,
        FrenchconciergeFooterComponent,
        Yaago2PoweredByFooterComponent,
        LuckeychalonFooterComponent,
        Yaago2YaagoFooterComponent,
        GitesDeFranceComponent,
        LeafletMapComponent,
        HaloconciergeComponent,
        TagComponent,
        Y3ExplainedButtonComponent
    ],
  entryComponents: [
    GuestGalleryDetailComponent
  ]
})
export class SharedModule { }
