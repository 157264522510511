import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {IntercomService} from '../../../core/services/IntercomService';
import {UtilsService} from '../../../core/services/utils.service';
import {MatDialog} from '@angular/material';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {RecommendationCategory} from '../../../models/guestview/RecommendationCategory';
import * as moment from 'moment/moment';
import {RecommendationSearchRequestDto} from '../../../models/guestview/RecommendationSearchRequestDto';
import {GuestService} from '../../../core/services/guest.service';
import {LanguageManagementService} from '../../../core/services/language.service';
import {Recommendation} from '../../../models/guestview/Recommendation';
import {CollectDataServiceService} from '../../../core/services/collect-data-service.service';
import {CorporateBookletSection} from '../../../models/CorporateBookletSection';

@Component({
  selector: 'app-y3-landing-recommendation',
  templateUrl: './y3-landing-recommendation.component.html',
  styleUrls: ['./y3-landing-recommendation.component.scss']
})
export class Y3LandingRecommendationComponent extends AbstractWindow implements OnInit, OnChanges {

  @Input() booking: BookingForGuest;
  @Input() property: PropertyForGuest;
  @Input() corporate: Corporate;
  @Input() curIndex: number;
  @Input() section: CorporateBookletSection;

  categories: any[] = new RecommendationCategory().list;
  newRecommendations: Recommendation[] = [];
  recommendationSearch = new RecommendationSearchRequestDto();
  selectedCategory = 'all';

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              public guestService: GuestService,
              public languageService: LanguageManagementService,
              public CollectData: CollectDataServiceService,
              protected dialog: MatDialog) {
    super(intercomService, utilsService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.property && this.booking && this.booking.startDate && this.booking.endDate) {
      this.searchQuery();
    } else if (this.property) {
      this.searchQuery();
    }
  }

  searchQuery() {
    this.recommendationSearch.propertyId = this.property.id;

    if (this.booking) {
      this.recommendationSearch.bookingStart = moment(this.booking.startDate).startOf('day');
      this.recommendationSearch.bookingEnd = moment(this.booking.endDate).endOf('day');
      this.recommendationSearch.bookingId = this.booking.id;
    }

    this.recommendationSearch.categories = [];
    if (this.selectedCategory !== 'all') {
      this.recommendationSearch.categories.push(this.selectedCategory);
    }

    if (this.corporate && this.corporate.onlyMineRecommendations) {
      this.recommendationSearch.hostAdresses = true;
      this.recommendationSearch.communityAdresses = false;
      this.recommendationSearch.yaagoAdresses = false;
      this.recommendationSearch.influencerAdresses = false;
      const personId = (this.property && this.property.host) ? this.property.host.id : null;
      this.guestService.getRecommendations(
        this.recommendationSearch,
        this.languageService.getLanguageInUrl(),
        personId, this.corporate.id
      ).subscribe(res => {

        this.completeRecommendations(res);
      });
    } else {
      this.recommendationSearch.hostAdresses = true;
      this.recommendationSearch.communityAdresses = true;
      this.recommendationSearch.yaagoAdresses = true;
      this.recommendationSearch.influencerAdresses = true;
      const personId = this.property.host ? this.property.host.id : null;
      this.guestService.getRecommendations(this.recommendationSearch, this.languageService.getLanguageInUrl(), personId, this.corporate.id)
        .subscribe(res => {
          this.completeRecommendations(res);
        });
    }
  }

  completeRecommendations(res: Recommendation[]) {
    let nbItems = 0;
    this.newRecommendations = [];
    res.forEach( r => {
      if (nbItems < 4) {
        if (r.mainPhoto == null || r.mainPhoto === '') {
          r.mainPhoto = '/assets/icon/recommendation/empty/' + r.category.key + '.jpg';
        }
        this.newRecommendations.push(r);
      }
      nbItems++;
    });
  }

  openCard(recommendation) {
    this.showMap();
    this.guestService.openCard(recommendation, this.property);
  }

  showMap() {
    this.guestService.showMap(true, this.property, this.corporate);
  }

  onClickOnRecomendationItemAtPage(recomendation : any ){
    if(this.booking==undefined){
      console.error("booking not found")
    }else{
      this.CollectData.setOnClickItemInRecomendation(this.booking.id,recomendation.id);
    }
  }

  categorySelectionChange(event: string) {
    this.selectedCategory = event;
    this.searchQuery();
  }

}
