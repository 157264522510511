import {Component, Input, OnInit} from '@angular/core';
import {CategoryGuide, DocType, Guide} from '../../../models/Guide';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import * as moment from 'moment/moment';
import {MatDialog} from '@angular/material';
import {GuestGuideDetailsComponent} from '../guest-guides/guest-guide-details/guest-guide-details.component';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {IntercomService} from '../../../core/services/IntercomService';
import {UtilsService} from '../../../core/services/utils.service';
import {CorporateBookletSection} from '../../../models/CorporateBookletSection';

@Component({
  selector: 'app-y3-important-guide',
  templateUrl: './y3-important-guide.component.html',
  styleUrls: ['./y3-important-guide.component.scss']
})
export class Y3ImportantGuideComponent extends AbstractWindow implements OnInit {

  currentGuide: Guide;
  importantGuides: Guide[] = [];

  @Input() property: PropertyForGuest;
  @Input() booking: BookingForGuest;
  @Input() corporate: Corporate;
  @Input() curIndex: number;
  @Input() section: CorporateBookletSection;

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              protected dialog: MatDialog) {
    super(intercomService, utilsService);
  }

  ngOnInit() {
    if (this.property.guides && this.property.guides.length > 0) {
      this.importantGuides = this.property.guides.filter(item => item.showAsImportantInfo);
    }
  }

  getGuideCover(guide: Guide) {
    if (this.corporate && this.corporate.customGuideCategoriesPictures) {
      if (!this.corporate.bookletSeeGuidePictureNotCategory) {
        const found = this.corporate.customGuideCategoriesPictures.find(
          (item) => guide.category === item.categoryName
        );
        if (found && found.url) {
          return found.url;
        }
      }
    }

    if (!guide || !guide.documents || guide.documents.length === 0) {
      return this.getPictureFromCategory(guide.category);
    }
    const firstPhoto = guide.documents.find(item => item.type === DocType.IMAGE);
    if (!firstPhoto) {
      return this.getPictureFromCategory(guide.category);
    }

    return firstPhoto.url;
  }

  getPictureFromCategory(category: string) {
    let image;
    switch (category) {
      case CategoryGuide.APPLIANCES:
        image = '../../../../assets/images/list-guides/Appliance/Appliances.png';
        break;
      case CategoryGuide.CHECKIN:
        image = '../../../../assets/images/list-guides/Checkin/checkin.png';
        break;
      case CategoryGuide.CHECKOUT:
        image = '../../../../assets/images/list-guides/Check-out/checkout.png';
        break;
      case CategoryGuide.ORIENTATION:
        image = '../../../../assets/images/list-guides/Direction/dir.png';
        break;
      case CategoryGuide.RULES:
        image = '../../../../assets/images/list-guides/Rules/img@3x.png';
        break;
      case CategoryGuide.SERVICES:
        image = '../../../../assets/images/list-guides/service.png';
        break;
      case CategoryGuide.WIFI:
        image = '../../../../assets/images/list-guides/Wifi/wifi.png';
        break;
      case CategoryGuide.PARKING:
        image = '../../../../assets/images/list-guides/Parking/parking.png';
        break;
      case CategoryGuide.TRASH:
        image = '../../../../assets/images/list-guides/Trash/trash.png';
        break;
      case CategoryGuide.HEATING:
        image = '../../../../assets/images/list-guides/Heating/heater.png';
        break;
      case CategoryGuide.OTHER:
        image = '../../../../assets/images/list-guides/Other/questions.png';
        break;
      default: {
        image = '../../../../assets/images/list-guides/Appliance/Appliances.png';
      }
    }
    return image;
  }

  canShowIglooHome() {
    return (this.isIgloohomeConfigured() && (!this.isSwiklyConfigured() || this.isCautionDeposed()));
  }

  canShowTheKeys() {
    return (this.isTheKeysConfigured() && (!this.isSwiklyConfigured() || this.isCautionDeposed()));
  }

  canShowSmartLock() {
    return (this.booking != null && this.booking.smartLockAccessList != null && this.booking.smartLockAccessList.length > 0);
  }

  isCautionDeposed(): boolean {
    return this.isSwiklyConfigured() && !!this.booking.swiklyInfo.swiklyDepositAcceptedDate;
  }

  isCautionAllowToShow(): boolean {
    // console.log(this.booking.startDate);
    // console.log(moment(this.booking.startDate).add(-5, 'days').format('YYY/MM/DD'));
    return moment(this.booking.startDate).add(-1 * this.getNbDaysForDepositShow(), 'days').isBefore(moment.now());

  }

  isSmartLockCodeAllowToShow(): boolean {
    // console.log(this.booking.startDate);
    // console.log(moment(this.booking.startDate).add(-5, 'days').format('YYY/MM/DD'));
    return moment(this.booking.startDate).add(-1 * this.getNbDaysForSmartLockCodeShow(), 'days').isBefore(moment.now());

  }

  getNbDaysForSmartLockCodeShow(): number {
    return this.property.nbDaysToShowSmartLockCode != null ? this.property.nbDaysToShowSmartLockCode : 1;
  }

  getNbDaysForDepositShow(): number {
    return this.property.nbDaysToShowDepositLink != null ? this.property.nbDaysToShowDepositLink : 5;
  }


  isSwiklyConfigured() {
    return this.booking && this.booking.swiklyInfo && this.booking.swiklyInfo.swiklyAcceptUrl;
  }

  isIgloohomeConfigured(): boolean {
    return this.property.iglooHomeDisplayWelcomeBooklet && this.booking && !!(this.booking.iglooHomeAccess ? this.booking.iglooHomeAccess.code : this.booking.iglooHomeLockCode);
  }

  isTheKeysConfigured(): boolean {
    return this.property.smartLockDisplayWelcomeBooklet && this.booking && this.booking.theKeysAccesses && this.booking.theKeysAccesses.length > 0;
  }

  partnerScroll(el: any, direction: string) {
    // TODO make a smooth scroll for partners
    el.scrollTo({
      behavior: 'smooth',
      left: direction === 'left' ? el.scrollLeft - 200 : el.scrollLeft + 200, // 200 is the bloc width set on the css
    });
  }

  needToShowGuide(guide: Guide) {
    if (guide.partnerName != null && guide.partnerName !== '' && guide.partnerType != null && guide.partnerType !== '') {
      if (guide.partnerName === 'swikly') {
        return this.isSwiklyConfigured() && !this.booking.swiklyInfo.swiklyDepositAcceptedDate;
      } else if (guide.partnerName === 'the-keys') {
        return this.isTheKeysConfigured();
      } else if (guide.partnerName === 'igloohome') {
        return this.isIgloohomeConfigured();
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  isPropertyReady() {
    if (!this.booking) {
      return false;
    }
    const checkinTime = moment(this.booking.startDate + ' ' + this.booking.expectedCheckinLocalTime, 'YYYY-MM-DD HH:mm:ss');
    return this.booking.preCheckIn || moment().isAfter(checkinTime);
  }

  showGuide(guide: Guide) {
    const taskCreationDialog = this.dialog.open(GuestGuideDetailsComponent, {
      height: this.isDesktopMode() ? '90%' : '100vh',
      width: this.isDesktopMode() ? '75%': '100vw',
      autoFocus: false,
      data: {
        property: this.property,
        guide: guide
      }
    });
  }

  closeGuide() {
    this.currentGuide = null;
  }

}
