/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./y3-usefulnumber.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./y3-usefulnumber.component";
import * as i5 from "../../../core/services/IntercomService";
import * as i6 from "../../../core/services/utils.service";
import * as i7 from "@angular/material/dialog";
var styles_Y3UsefulnumberComponent = [i0.styles];
var RenderType_Y3UsefulnumberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Y3UsefulnumberComponent, data: {} });
export { RenderType_Y3UsefulnumberComponent as RenderType_Y3UsefulnumberComponent };
function View_Y3UsefulnumberComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "y3-margin-top-8 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "y3-text-number-bloc y3-font-11"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "y3-text-number-bloc y3-font-11"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " km"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.fullAddress; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.distance.toFixed(2); _ck(_v, 4, 0, currVal_1); }); }
function View_Y3UsefulnumberComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "y3-number-bloc-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "y3-number-bloc y3-roboto y3-Small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "y3-text-number-bloc y3-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "y3-text-number-bloc y3-font-11"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "y3-text-number-bloc y3-bold y3-Small  y3-margin-top-8 y3-underline y3-clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Call(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3UsefulnumberComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "y3-text-number-bloc y3-XX-small y3-bold y3-underline y3-margin-top-8 y3-clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emailPerson(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.fullAddress; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.category; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.phoneNumber; _ck(_v, 8, 0, currVal_2); var currVal_4 = _v.context.$implicit.email; _ck(_v, 12, 0, currVal_4); }); }
function View_Y3UsefulnumberComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "y3-container "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "y3-flex-row-wrap "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3UsefulnumberComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getUsefullNumbers(); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "y3-divider ", (((_co.curIndex % 2) === 0) ? "y3-silver" : "y3-white"), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("guest-view-useful-number.useful-number")); _ck(_v, 3, 0, currVal_1); }); }
export function View_Y3UsefulnumberComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_Y3UsefulnumberComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.property == null) ? null : ((_co.property.usefulNumbers == null) ? null : _co.property.usefulNumbers.length)) > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_Y3UsefulnumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-y3-usefulnumber", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_Y3UsefulnumberComponent_0, RenderType_Y3UsefulnumberComponent)), i1.ɵdid(1, 114688, null, 0, i4.Y3UsefulnumberComponent, [i5.IntercomService, i6.UtilsService, i7.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Y3UsefulnumberComponentNgFactory = i1.ɵccf("app-y3-usefulnumber", i4.Y3UsefulnumberComponent, View_Y3UsefulnumberComponent_Host_0, { property: "property", curIndex: "curIndex" }, {}, []);
export { Y3UsefulnumberComponentNgFactory as Y3UsefulnumberComponentNgFactory };
