<footer id="footer" class="its--wrapper-full footer-block-its-corporative">
  <div id="upper_footer">
    <div class="its--container">
      <div
        class="its--row datos_empresa-wrapper links-wrapper ayuda-wrapper newsletter-wrapper"
      >
        <div class="its--container">
          <div class="its--col-sm-4">
            <div class="widget datos_empresa">
              <div id="address_widget" class="widget">
                <a href=""
                  ><img
                    src="https://www.villasduluberon.fr/child/assets/img/logo_footer.png"
                /></a>
                <p class="direccion_empresa">
                  797 Route de Gargas <br />
                  84400 GARGAS
                </p>
                <div class="widget_inner">
                  <hr />
                  <p class="">
                    <img src="assets/icon/telephone.svg" width="17px" style=" margin-bottom: -3px; margin-right: 15px;" >
                    <a href="tel:00 33 (0)6 89 88 80 98"
                      >00 33 (0)6 89 88 80 98</a
                    >
                  </p>
                  <p class="">
                    <img src="assets/icon/mail-white.svg" width="17px" style=" margin-bottom: -3px; margin-right: 15px;" >
                    <a href="mailto:info@villasduluberon.fr"
                      >info@villasduluberon.fr</a
                    >
                  </p>
                </div>
                <p class="empresa_name">© VILLAS DU LUBERON 2022</p>
              </div>
            </div>
          </div>
          <div class="its--col-sm-4">
            <div class="widget links">
              <div id="links_widget" class="widget">
                <p class="title fwk-color-cont">Liens rapides</p>
                <div class="widget_inner">
                  <ul>
                    <li>
                      <a href="https://www.villasduluberon.fr/" target="_blank"
                        >Accueil</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.villasduluberon.fr/location/location-locations-d0/tag-gordes,oppede,provence-luberon-alpilles,goult,gargas,bonnieux//"
                        target="_blank"
                        >Location</a
                      >
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.villasduluberon.fr/offers/"
                        >Offres</a
                      >
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.villasduluberon.fr/a-propos/"
                        >À propos</a
                      >
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.villasduluberon.fr/contact/"
                        >Contact</a
                      >
                    </li>
                    <li>
                      <a target="_blank" href="https://blog.villasduluberon.fr/"
                        >Blog</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="its--col-sm-4">
            <div class="widget ayuda">
              <div id="links_widget" class="widget">
                <p class="title fwk-color-cont">Aide</p>
                <div class="widget_inner">
                  <ul>
                    <li>
                      <a
                        href="https://www.villasduluberon.fr/conditions-generales/"
                        target="_blank"
                        >Conditions générales</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.villasduluberon.fr/politique-de-cookies/"
                        target="_blank"
                        >Politique de Cookies</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.villasduluberon.fr/politique-de-confidentialite/"
                        target="_blank"
                        >Politique de confidentialité</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="its--col-sm-4">
            <div class="widget newsletter">
              <p class="title fwk-color-cont">Newsletter</p>

              <div class="widget_inner">
                <form
                  name="formNewsletterFooter"
                  method="post"
                  data-endpoint="https://fwk.avantio.com/services/sendMail.php"
                  id="formNewsletterFooter"
                  class="send-form form-default"
                >
                  <input type="hidden" name="Envia_contacto" value="Si" />
                  <input type="hidden" name="Idioma" value="FR" />
                  <input type="hidden" name="type" value="newsletter" />
                  <input
                    style="display: none"
                    type="text"
                    name="valida"
                    value="avantio"
                    size="10"
                    tabindex="-1"
                  />
                  <input style="display: none" type="checkbox" name="check" />

                  <label id="CapaEmail">
                    <span>E-mail:<span>*</span></span>
                    <input
                      class="form-control"
                      name="email"
                      value=""
                      type="email"
                      id="email2"
                      size="38"
                      placeholder="Your email..."
                      align="left"
                      required=""
                    />
                  </label>

                  <div id="botonContacto">
                    <label>
                      <div id="boton_contacto">
                        <input
                          id="boton_enviar"
                          type="submit"
                          name="Submit"
                          value="S'abonner"
                          class="btn btn-primary"
                        />
                      </div>
                    </label>
                  </div>

                  <div class="its--col-md-12 acepto">
                    <input
                      id="acepto-footer1"
                      type="checkbox"
                      value="acepto"
                      required=""
                    />
                    <label for="acepto-footer1"
                      >J'ai lu et j'accepte les
                      <a
                        target="_self"
                        href="https://www.villasduluberon.fr/politique-de-confidentialite/"
                        >politique de confidentialité</a
                      >
                      et les
                      <a
                        target="_self"
                        href="https://www.villasduluberon.fr/conditions-generales/"
                      >
                        conditions générales</a
                      ></label
                    >
                  </div>
                  <div class="its--col-md-12 acepto si-acepta">
                    <input
                      id="acepto-footer2"
                      type="checkbox"
                      value="Oui"
                      name="aceptaOfertas"
                      required=""
                      oninvalid="setCustomValidity('Sélectionnez ce champs si vous souhaitez recevoir toutes nos communications')"
                    />
                    <label for="acepto-footer2"
                      >J'accepte de recevoir les offres et promotions</label
                    >
                  </div>
                  <div
                    style="display: none"
                    class="its--col-md-12 acepto no-acepta"
                  >
                    <input
                      id="no-acepto"
                      type="hidden"
                      value="Non"
                      name="aceptaOfertas"
                    />
                  </div>
                </form>

                <div class="clearfix"></div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <p class="empresa_name_mobile visible-xs">© VILLAS DU LUBERON 2022</p>
    </div>
  </div>
  <div id="lower_footer">
    <div class="its--container">
      <div class="its--row">
        <div id="social_widget" class="its--col-sm-5">
          <ul>
            <li>
               <a
                href="https://www.facebook.com/Villas-du-Luberon-349509215450003/"
                target="_blank">
                <img src="assets/icon/facebook-icon.svg" >
              </a> 
            </li>
            <li>
               <a
                href="https://www.linkedin.com/in/aline-calvi-144a24114"
                target="_blank">
                <img  src="assets/icon/linkedin-icon.svg"
              ></a> 
            </li>
            <li>
              <a
                href="https://www.instagram.com/villas_du_luberon/"
                target="_blank" >
                <img  src="assets/icon/instagram-icon.svg">
            </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCCiY7HfSfW4aQVCbGzCuwoA"
                target="_blank">
                <img  src="assets/icon/youtube-icon.svg" >
              </a> 
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
