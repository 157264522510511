/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./yaagov2-services-for-guests.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./yaagov2-services-for-guests.component";
var styles_Yaagov2ServicesForGuestsComponent = [i0.styles];
var RenderType_Yaagov2ServicesForGuestsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Yaagov2ServicesForGuestsComponent, data: {} });
export { RenderType_Yaagov2ServicesForGuestsComponent as RenderType_Yaagov2ServicesForGuestsComponent };
function View_Yaagov2ServicesForGuestsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "card-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "partner-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "partner-img"], ["class", "picture-logo"]], [[8, "id", 0], [8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "partner-info"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "partner-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "alink-no-decoration "], ["target", "_blank"]], [[8, "id", 0], [8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = ("platformLogo" + _v.context.index); var currVal_1 = _v.context.$implicit.logoUrl; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = ("platformDescription" + _v.context.index); _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.translations[0].description; _ck(_v, 4, 0, currVal_3); var currVal_4 = ("platformLink" + _v.context.index); var currVal_5 = _v.context.$implicit.translations[0].webSiteUrl; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("guest-platefromservices-page.more-btn")); _ck(_v, 7, 0, currVal_6); }); }
function View_Yaagov2ServicesForGuestsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "section", [["class", "section-color"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "service-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h3", [["class", "big-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "Small sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "guest-view-block-card"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Yaagov2ServicesForGuestsComponent_2)), i1.ɵdid(10, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.platformServices; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("guest-platefromservices-page.title")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("guest-platefromservices-page.description")); _ck(_v, 6, 0, currVal_1); }); }
export function View_Yaagov2ServicesForGuestsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_Yaagov2ServicesForGuestsComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!((_co.property == null) ? null : ((_co.property.host == null) ? null : _co.property.host.btobInfo)) && !((_co.property == null) ? null : _co.property.noshowPlatformServicesOnBooklet)) && (((_co.platformServices == null) ? null : _co.platformServices.length) > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_Yaagov2ServicesForGuestsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-yaagov2-services-for-guests", [], null, null, null, View_Yaagov2ServicesForGuestsComponent_0, RenderType_Yaagov2ServicesForGuestsComponent)), i1.ɵdid(1, 114688, null, 0, i4.Yaagov2ServicesForGuestsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Yaagov2ServicesForGuestsComponentNgFactory = i1.ɵccf("app-yaagov2-services-for-guests", i4.Yaagov2ServicesForGuestsComponent, View_Yaagov2ServicesForGuestsComponent_Host_0, { platformServices: "platformServices", property: "property" }, {}, []);
export { Yaagov2ServicesForGuestsComponentNgFactory as Yaagov2ServicesForGuestsComponentNgFactory };
