import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaysService {

  constructor(private http: HttpClient) { }

  // https://restcountries.com/v3.1/all
  getPays(lang: string){
    return this.http.get<any>('assets/pays.json')
  }

}
