import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { GuestService } from '../../core/services/guest.service';
import { InternalPropertyService } from '../../core/services/internal.property.service';
import { GuestServiceIntern } from './guest.service';
import {SecurityService} from '../../core/services/SecurityService';

@Injectable({
  providedIn: 'root'
})
export class GuestResolverService implements Resolve<any> {

  constructor(
    private guestService: GuestService,
    private router: Router,
    private propertyService: InternalPropertyService,
    private internGuestService: GuestServiceIntern,
    private securityService: SecurityService
    // private errorHandler: BugsnagChunkLoadErrorHandler
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {

    const bookingId = route.children[0].params.bookingId;
    const lang = route.children[0].queryParams.lang ? route.children[0].queryParams.lang : undefined;
    return this.guestService.getPropertyAndBookingForGuest(bookingId, lang).pipe(
      mergeMap(res => {
        this.propertyService.setCurrentProperty(res.property);
        this.propertyService.localProperty = res.property;
        this.internGuestService.setBookingForGuest(res.booking);
        this.guestService.sendCorporate(res.corporate);
        this.propertyService.setCurrentCorporate(res.corporate);

        let urlForGuides = false;
        if (state.url.indexOf('/guides/') > 0) {
          urlForGuides = true;
        }
        if (res.booking != null) {
          /*if (res.property.lightOnLinePreCheck && !res.booking.lightOnLinePreCheckDone && state.url.indexOf('/messaging') < 0) {
            return this.router.navigate(['/onlinecheck/light/' + bookingId]);
          } else if (res.booking.onLineCheck != null && res.booking.onLineCheck.checkStatus != null && !['VALIDATED', 'SKIPPED'].includes(res.booking.onLineCheck.checkStatus) && state.url.indexOf('/messaging') < 0) {
              return this.router.navigate(['/onlinecheck/' + bookingId]);
          }*/
        }
        if (!res.property.host.subscriptionForLivretSeul) {
          console.log('Security Password: ', this.securityService.getSecurityPassword());
          if (!urlForGuides && res.booking == null && route && this.securityService.getSecurityPassword() !== 'YES' && res.corporate != null && res.corporate.bookletMasterKey != null && res.corporate.homePageSettings === undefined ) {
          // console.log('route', route.children[0].url);
          // console.log('No Booking');
          // console.log('PageSettings', res.corporate.homePageSettings);
          return this.router.navigate(['/pwd/' + bookingId + '/ask4pwd']);
          } else if (!urlForGuides && res.booking == null && route && this.securityService.getSecurityPassword() !== 'YES' && res.corporate != null && res.corporate.bookletMasterKey != null && res.corporate.homePageSettings && state.url.split('/')[state.url.split('/').length - 1] !== 'livret-welcome') {
            return this.router.navigate(['/guest/' + bookingId + '/livret-welcome']);
          }
        }

        if (res.booking != null ) {
          // console.log('Current Routes: ', state.url);
          // console.log('res.booking.welcomePageSeen', res.booking.welcomePageSeen);
          if (localStorage.getItem("WelcomeSeen") == "false" && res.corporate.homePageSettings !== undefined && state.url.split('/')[state.url.split('/').length - 1] !== 'livret-welcome') {
            // console.log('res.corporate.homePageSettings', res.corporate.homePageSettings);
            return this.router.navigate(['/guest/' + bookingId + '/livret-welcome']);
           } else if (res.booking.welcomePageSeen === false &&  state.url.split('/')[state.url.split('/').length - 1] === 'livret-welcome' && !res.corporate.homePageSettings) {
            return this.router.navigate(['/guest/' + bookingId ]);
           } else if (res.booking.welcomePageSeen === true &&  state.url.split('/')[state.url.split('/').length - 1] === 'livret-welcome') {
            // console.log('imhere');
            return this.router.navigate(['/guest/' + bookingId ]);
           }
        }

        // this.errorHandler.addMetadata(res.property.id, res.booking.id);
        return of(res);
      }),
      catchError((err) => {
        console.log('Error:', err);
        return this.router.navigate(['/misc/lost']);
      })
    );
  }

}
