import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class SecurityService {

  removeSecurtityPassword() {
    localStorage.removeItem("CheckPassword");
  }

  setSecurityPassword() {
    localStorage.setItem("CheckPassword", "YES");
  }

  getSecurityPassword(): string {
    return localStorage.getItem("CheckPassword");
  }

}
