import {Component, Input, OnInit} from '@angular/core';
import {IntercomService} from '../../../core/services/IntercomService';
import {UtilsService} from '../../../core/services/utils.service';
import {MatDialog} from '@angular/material';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';

@Component({
  selector: 'app-y3-usefulnumber',
  templateUrl: './y3-usefulnumber.component.html',
  styleUrls: ['./y3-usefulnumber.component.scss']
})
export class Y3UsefulnumberComponent extends AbstractWindow implements OnInit {

  @Input() property: PropertyForGuest;
  @Input() curIndex: number;

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              protected dialog: MatDialog) {
    super(intercomService, utilsService);
  }

  ngOnInit() {
  }

  getUsefullNumbers() {
    if (this.isDesktopMode()) {
      return this.property.usefulNumbers.slice(0, 8);
    } else {
      return this.property.usefulNumbers.slice(0, 4);
    }
  }

  Call(use) {
    document.location.href = 'tel:' + use.phoneNumber;
  }

  emailPerson(use) {
    if (use.email) {
      document.location.href = 'mailto:' + use.email;
    }
  }

}
