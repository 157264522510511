

<div class="scrollable-container">
  <router-outlet (activate)="onActivate($event)"></router-outlet>

  <ng-template [ngIf] ="corporate?.corporateTag && showFooter">
    <app-cotoon-footer *ngIf="corporate?.corporateTag === 'COTOON'" ></app-cotoon-footer>
    <app-rentatranquille-footer *ngIf="corporate?.corporateTag === 'RENTATRANQUILLE'" ></app-rentatranquille-footer>
    <app-guest-adom-footer *ngIf="corporate?.corporateTag === 'GUESTADOM'" ></app-guest-adom-footer>
    <app-keylodge-footer  *ngIf="corporate?.corporateTag === 'KEYLODGE'"></app-keylodge-footer>
    <app-villabali-footer *ngIf="corporate?.corporateTag === 'VILLABALI'"> </app-villabali-footer>
    <app-villasduluberon-footer  *ngIf="corporate?.corporateTag === 'VILLALUBERON'" ></app-villasduluberon-footer>
    <app-frenchconcierge-footer *ngIf="corporate?.corporateTag === 'FRENCHCONCIERGE'"  ></app-frenchconcierge-footer>
    <app-luckeychalon-footer  *ngIf="corporate?.corporateTag === 'LUCKEYCHALON'"></app-luckeychalon-footer>
    <app-gites-de-france *ngIf="corporate?.corporateTag === 'GITESDEFRANCE'" ></app-gites-de-france>
    <app-haloconcierge *ngIf="corporate?.corporateTag === 'HALOCONCIERGE'" ></app-haloconcierge>
    <app-yaago2-powered-by-footer [corporate]="corporate"></app-yaago2-powered-by-footer>
  </ng-template>

  <section>
    <ng-template [ngIf]="!corporate?.corporateTag && showFooter">
      <!--<app-rte-widget></app-rte-widget>-->
      <app-yaago2-yaago-footer *ngIf="showFooter"></app-yaago2-yaago-footer>
    </ng-template>
  </section>
</div>

<app-loader [corporate]="corporate"></app-loader>
<gusl-shared-snackbar></gusl-shared-snackbar>
