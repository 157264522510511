/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-snackbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./shared-snackbar.component";
import * as i4 from "./services/shared-snackbar.service";
var styles_SharedSnackbarComponent = [i0.styles];
var RenderType_SharedSnackbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedSnackbarComponent, data: { "animation": [{ type: 7, name: "fadeInOut", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateY(100%)", opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(0)", opacity: 1 }, offset: null }, timings: "500ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateY(0)", opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(100%)", opacity: 0 }, offset: null }, timings: "500ms" }], options: null }], options: {} }] } });
export { RenderType_SharedSnackbarComponent as RenderType_SharedSnackbarComponent };
function View_SharedSnackbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [["class", "snackbar-text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.message; _ck(_v, 0, 0, currVal_0); }); }
function View_SharedSnackbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "snackbar-text call-to-action"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.callToActionMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_SharedSnackbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "dismiss-img"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, ":svg:svg", [["height", "14.025"], ["viewBox", "0 0 14.047 14.025"], ["width", "14.047"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:style", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" .cls-1{fill:#FFFFFF;fill-rule:evenodd} "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["class", "cls-1"], ["d", "M22.729 21.271L17.461 16l5.239-5.193a.992.992 0 0 0 0-1.414 1.018 1.018 0 0 0-1.428 0l-5.232 5.188-5.309-5.31A1.011 1.011 0 1 0 9.3 10.7l5.3 5.3-5.327 5.292a.994.994 0 0 0 0 1.414 1.017 1.017 0 0 0 1.429 0l5.324-5.28L21.3 22.7a1.01 1.01 0 1 0 1.427-1.431z"], ["id", "button-cross-erase"], ["transform", "translate(-8.978 -8.974)"]], null, null, null, null, null))], null, null); }
function View_SharedSnackbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "first-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "snackbar-container"]], [[24, "@fadeInOut", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "info": 0, "warning": 1, "error": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedSnackbarComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedSnackbarComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedSnackbarComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = "snackbar-container"; var currVal_2 = _ck(_v, 4, 0, (_v.context.ngIf.type === "info"), (_v.context.ngIf.type === "warning"), (_v.context.ngIf.type === "error")); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _v.context.ngIf.message; _ck(_v, 6, 0, currVal_3); var currVal_4 = _v.context.ngIf.callToActionMessage; _ck(_v, 8, 0, currVal_4); var currVal_5 = (_v.context.ngIf.type === "error"); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 1, 0, currVal_0); }); }
export function View_SharedSnackbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_SharedSnackbarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.message$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SharedSnackbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gusl-shared-snackbar", [], null, null, null, View_SharedSnackbarComponent_0, RenderType_SharedSnackbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SharedSnackbarComponent, [i4.SharedSnackbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedSnackbarComponentNgFactory = i1.ɵccf("gusl-shared-snackbar<", i3.SharedSnackbarComponent, View_SharedSnackbarComponent_Host_0, {}, {}, []);
export { SharedSnackbarComponentNgFactory as SharedSnackbarComponentNgFactory };
