<app-y3-header [corporate]="corporate" [booking]="booking" [property]="property"></app-y3-header>

<div class="y3-container {{isDesktopMode()?'y3-padding-top-32':'y3-padding-top-0'}}">

  <div class="y3-roboto">
    <div class="y3-flex-row y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-justify-content-center ">
      <div class="">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text2="{{'guest-setup-page.checkin' | translate}}" [type]="'deproprietaire'"></app-tag>
      </div>
    </div>


    <div *ngIf="booking" class="y3-journey-summary ">

      <div class="y3-margin-1024 y3-welcome-display y3-McQueen y3-margin-bottom-8">{{'guest-top-page.welcome' | translate}} {{booking?.guests[0]?.firstName | titlecase}} {{booking?.guests[0]?.lastName | titlecase}}</div>
      <div class="y3-margin-1024 y3-Small y3-bold y3-roboto y3-color-green-vogue">{{property.title}}</div>
      <div class="y3-margin-1024 y3-X-small y3-roboto y3-color-scorpion">{{property.fullAddress}}</div>

      <div class="y3-margin-1024 y3-flex-row y3-roboto y3-margin-top-16" >
        <div class="y3-flex-column y3-roboto y3-check-card" >
          <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Medium">{{'guest-top-page.start' | translate}}</div>
          <div class="y3-color-scorpion y3-Small">{{booking?.startDate | moment: translateService.currentLang  | titlecase}} </div>
          <div class="y3-color-scorpion y3-Small">{{getTime(booking?.expectedCheckinLocalTime, property?.defaultCheckInTime)}}</div>
        </div>
        <div class="y3-flex-column y3-roboto y3-check-card" >
          <div class="y3-color-green-vogue y3-bold y3-margin-bottom-8 y3-Medium">{{'guest-top-page.end' | translate}}</div>
          <div class="y3-color-scorpion y3-Small">{{booking?.endDate | moment: translateService.currentLang  | titlecase}} </div>
          <div class="y3-color-scorpion y3-Small">{{getTime(booking?.expectedCheckoutLocalTime, property?.defaultCheckOutTime)}}</div>
        </div>
        <div class="y3-flex-column y3-roboto y3-check-card" style="width: 250px" >
          <div class="y3-margin-bottom-6">
          </div>
          <div class="y3-color-scorpion y3-X-small">{{booking?.nights}} Nuits</div>
          <div class="y3-color-scorpion y3-X-small">{{booking?.adults}} Adulte(s) | {{booking?.children}} Enfant(s) | {{booking.babies}} Bébé(s)</div>
        </div>
      </div>


    </div>

  </div>
</div>
