/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-number-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./card-number-mobile.component";
import * as i4 from "../../../../core/services/utils.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/material/dialog";
var styles_CardNumberMobileComponent = [i0.styles];
var RenderType_CardNumberMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardNumberMobileComponent, data: {} });
export { RenderType_CardNumberMobileComponent as RenderType_CardNumberMobileComponent };
function View_CardNumberMobileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "XX-small number-distance clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getDirection(((_co.number == null) ? null : _co.number.fullAddress)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "assets/icon/useful-numbers/geoloc-number.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" \u00E0 ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.utilService.displayDistance(_co.number.distance); _ck(_v, 2, 0, currVal_0); }); }
function View_CardNumberMobileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "number-email Small clickable "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emailPerson(_co.number) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.number == null) ? null : _co.number.email); _ck(_v, 1, 0, currVal_0); }); }
function View_CardNumberMobileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "number-address Small"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.number == null) ? null : _co.number.fullAddress); _ck(_v, 1, 0, currVal_0); }); }
export function View_CardNumberMobileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "number-card-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "number-card-top bgcolor-catskill"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["style", "overflow: hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "number-title-category"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "number-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardNumberMobileComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "number-card-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "number-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "number-number Small clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Call(_co.number) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardNumberMobileComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardNumberMobileComponent_3)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.number == null) ? null : _co.number.distance); _ck(_v, 8, 0, currVal_2); var currVal_4 = _co.number.email; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.number.fullAddress; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.number.category; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.number.title; _ck(_v, 6, 0, currVal_1); var currVal_3 = ((_co.number == null) ? null : _co.number.phoneNumber); _ck(_v, 12, 0, currVal_3); }); }
export function View_CardNumberMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-number-mobile", [], null, null, null, View_CardNumberMobileComponent_0, RenderType_CardNumberMobileComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardNumberMobileComponent, [i4.UtilsService, i5.Router, i6.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardNumberMobileComponentNgFactory = i1.ɵccf("app-card-number-mobile", i3.CardNumberMobileComponent, View_CardNumberMobileComponent_Host_0, { property: "property", number: "number", fake: "fake" }, {}, []);
export { CardNumberMobileComponentNgFactory as CardNumberMobileComponentNgFactory };
