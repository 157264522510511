/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./qr-code.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./qr-code.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "ngx-clipboard";
var styles_QrCodeComponent = [i0.styles];
var RenderType_QrCodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QrCodeComponent, data: {} });
export { RenderType_QrCodeComponent as RenderType_QrCodeComponent };
function View_QrCodeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "link-qrcode"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "qr-url"], ["style", "white-space: pre-line;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "url"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "text-url"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "img-copy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "copy"], ["class", "copy"], ["src", "../../../../assets/icons/button-copy-link-manual-bookings.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 4, 0, currVal_0); }); }
export function View_QrCodeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["style", "max-width: 1080px; margin-left: auto; margin-right: auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "qr-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "qr-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["style", "text-align: center"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrCodeComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "qr-footer"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isCopyVisible; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.id, ""); _ck(_v, 4, 0, currVal_0); }); }
export function View_QrCodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-qr-code", [], null, null, null, View_QrCodeComponent_0, RenderType_QrCodeComponent)), i1.ɵdid(1, 4833280, null, 0, i3.QrCodeComponent, [i4.TranslateService, i5.ClipboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QrCodeComponentNgFactory = i1.ɵccf("app-qr-code", i3.QrCodeComponent, View_QrCodeComponent_Host_0, { url: "url", isLogin: "isLogin", isCopyVisible: "isCopyVisible", id: "id" }, { closeComponent: "closeComponent" }, []);
export { QrCodeComponentNgFactory as QrCodeComponentNgFactory };
