<!-- <div class="container-images">
  <div class="main-title d-flex align-items-center">
      <div class="goBackButton clickable ml-4" (click)="onClose()">
          <img class="icon-arrow-left" src="assets/icons/arrows/arrow-up.svg" alt="arrow-left"/>
      </div>
      <h4 class="text-center my-phtots-text">{{ 'property-specs.my-myPhotos' | translate }}</h4>
      <div class="container-cross ml-auto mr-4 clickable" (click)="onClose()">
          <img src="assets/icons/property-details/cross.svg" />
      </div>
  </div>

  <div class="img-body" *ngIf="imgimg">
      <img class="picture" [src]="imgimg" alt="picture"/>
      <div class="navigation navigation-right clickable" *ngIf="length>numImg && !showRequestDelete" (click)="navigateRight()">
          <img class="icon-arrow-right" src="assets/icons/arrows/arrow-up.svg" alt="arrow-right"/>
      </div>
      <div class="navigation navigation-left clickable" *ngIf="numImg>1 && !showRequestDelete" (click)="navigateLeft()">
          <img class="icon-arrow-left" src="assets/icons/arrows/arrow-up.svg" alt="arrow-left"/>
      </div>
  </div>
  <div class="mt-3 text-center">
      <span class="index">{{numImg}}/{{length}}</span>
  </div>
  <form #fform="ngForm" class="form" *ngIf="!showRequestDelete">
      <div class="row mx-0 justify-content-center" *ngIf="myPhotos[index]">
          <div class="column first-column">
              <h4 class="legend">{{ 'property-specs.caption' | translate }}</h4>
              <div class="mt-3 text-radio-button d-flex align-items-center">
                  <mat-radio-button [checked]="myPhotos[index].main" (change)="onChange(index)">{{ 'property-specs.cover-pic' | translate }}</mat-radio-button>
              </div>
              <div class="delete" *ngIf="length>1">
                  <div class="event-delete d-inline-block clickable" (click)="displayRequestDelete()">{{ 'property-specs.delete-pic' | translate }}</div>
              </div>
          </div>
          <div class="column second-column" *ngIf="showTextArea === true">
              <textarea name="caption"
                  class="col-12"
                  [placeholder]="'property-specs.textarea-caption' | translate"
                  [(ngModel)]="property.myPhotos[index].caption"></textarea>
          </div>
          <div class="column second-column captionNonEditable px-0" *ngIf="showTextArea === false">
              <div class="row mx-0">
                  <div class="column textarea-column  caption px-0">
                          {{property.myPhotos[index].caption}}
                  </div>
                  <div class="column update">
                      <div class="event-update float-right d-inline-block clickable" (click)="displayTextArea()">{{ 'property-specs.update' | translate }}</div>
                  </div>
                  <div class="column update-mobile secondary-button clickable" (click)="displayTextArea()">{{ 'property-specs.update' | translate }}</div>
              </div>
          </div>
      </div>
      
      
      <div *ngIf="showTextArea === true"  class="primary-button primary-button-save" (click)="save()">{{ 'property-specs.save' | translate }}</div>
      <div class="delete-mobile text-center mb-4" *ngIf="length>1">
          <div class="event-delete d-inline-block clickable" (click)="displayRequestDelete()">{{ 'property-specs.delete-pic' | translate }}</div>
      </div>
  </form>
  <div class="interface-delete" *ngIf="showRequestDelete">
      <h4 class="text-center request-confirm">{{'homepage.ask-for-delete' | translate}}</h4>
      <div class="row mx-0 d-flex justify-content-center">
          <div class="mt-2 cancel-container">
              <button class="cancel w-100 clickable"  mat-stroked-button (click)="cancel()">
                  {{'setupIgloohome.cancel-delete' | translate}}
              </button>
          </div>
          <div  class="mt-2 confirm-container ml-sm-3">
              <button mat-flat-button class="confirm w-100 clickable" (click)="removeImg()">
                  {{'setupIgloohome.confirm-delete' | translate}}
              </button>
          </div>
      </div>
  </div>
</div> -->
      

<div class="header">
  <div class="header-title">{{'property-gallery.property-gallery' | translate}}</div>
  <div class="close-class" (click)="onClose()">
    <img src="assets/icon/cross.svg" alt="icon-close" />
</div>
</div>
    <!-- <div class="header d-flex align-items-center justify-content-center text-center">
      <span class="header-title text-center">{{'property-gallery.property-gallery' | translate}}</span>
      <div class="ml-auto mr-4 clickable" (click)="onClose()">
        <img src="assets/icon/cross.svg" alt="icon-close"/>
      </div>
    </div> -->
    <div class="img-body" *ngIf="myPhotos[index]">
      <img [src]="myPhotos[index].img" alt="photo" class="photo" />

      <div class="navigation navigation-right clickable" *ngIf="myPhotos.length>(index+1)" (click)="navigateRight()">
        <img class="icon-arrow-right" src="assets/icon/back-icon-arrow.svg" alt="arrow-right"/>
      </div>
      <div class="navigation navigation-left clickable" *ngIf="index>0" (click)="navigateLeft()">
          <img class="icon-arrow-left" src="assets/icon/back-icon-arrow.svg" alt="arrow-left"/>
      </div>
    </div>
    
    <div class="num-photo" *ngIf="myPhotos">{{index+1}}/{{myPhotos.length}}</div>

    <div class="caption" *ngIf="myPhotos[index] && myPhotos[index].caption && myPhotos[index].caption !== ''">
      <div class="caption-text">{{'property-gallery.caption' | translate}}</div>
      <div class="caption-desc px-1">{{myPhotos[index].caption}}</div>
    </div>




<!-- <app-progressbar [stepIn]="3"></app-progressbar> -->
<!-- <div id="sous-step-container">
  <div class="grid-container">
    <div class="sous-step-content-container">
      <div class="content">
        <button (click)="goBack()"  class="back-link" ><img class="icon" src="assets/icon/back-icon-arrow.svg" /> {{'guest-global-text.back-btn' | translate}}</button>
        <h2 class="title-style">{{'property-gallery.property-gallery' | translate}}</h2>
      </div>
      <div [ngClass]="{'scrollbar':myPhotos?.length > 2}" class="global-container style-3 " >

        <div id="photo-list"  >
          <div  *ngFor="let photo of myPhotos" class="list-item" >
            <div (click)="setSelectedPhoto(photo)" class="example-custom-placeholder photo-container">
              <div  class="photo-block">
                <img *ngIf="photo.img" src="{{photo.img}}" />
                <img *ngIf="!photo.img" src="{{photo}}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sous-step-right-container">
      <div class="image-block-right">
        <img  class="background"  [src]="selectedPhoto" />
      </div>

    </div>
  </div>
</div> -->
