<div class="y3-divider {{(curIndex%2===0)?'y3-silver':'y3-white'}}" *ngIf="this.property?.usefulNumbers?.length > 0">
  <div class="y3-container " >


    <div class="y3-divider-title y3-XX-large y3-McQueen y3-margin-bottom-24">
      {{'guest-view-useful-number.useful-number' | translate }}
    </div>

    <!--
    <div class="y3-margin-bottom-24 y3-Large y3-McQueen y3-flex-responsive y3-flex-justify-content-center ">
      <div style="text-align: center; width: 100%">
        <app-tag sizeText2="'16px'" minWidth="{{isDesktopMode()? '1024px': '100%'}}" text="{{'guest-view-useful-number.useful-number' | translate }}" [type]="'deproprietaire'"></app-tag>
      </div>
    </div>
    -->


    <div class="y3-flex-row-wrap " >
      <div class="y3-number-bloc-container" *ngFor="let number of getUsefullNumbers()">
        <div class="y3-number-bloc y3-roboto y3-Small">
          <div >
            <div class="y3-text-number-bloc y3-bold">{{number.category}}</div>
            <div class="y3-text-number-bloc y3-font-11">{{number.title}}</div>
          </div>

          <div class="y3-text-number-bloc y3-bold y3-Small  y3-margin-top-8 y3-underline y3-clickable" (click)="Call(number)">{{number.phoneNumber}}</div>

          <section *ngIf="number.fullAddress" class="y3-margin-top-8 ">
            <div class="y3-text-number-bloc y3-font-11">{{number.fullAddress}}</div>
            <div class="y3-text-number-bloc y3-font-11">{{number.distance.toFixed(2)}} km</div>
          </section>

          <div class="y3-text-number-bloc y3-XX-small y3-bold y3-underline y3-margin-top-8 y3-clickable" (click)="emailPerson(number)">{{number.email}}</div>
        </div>
      </div>
    </div>

  </div>
</div>
