import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IntercomService} from '../../../core/services/IntercomService';
import {UtilsService} from '../../../core/services/utils.service';
import {MatDialog} from '@angular/material';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {BookletReviews} from '../../../models/BookletReviews';
import {BookletReviewsLight} from '../../../models/BookletReviewsLight';
import {SnackbarMessage} from '../../../shared/components/shared-snackbar/models/snackbar-message';
import {ApiService} from '../../../core/http/api.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {SharedSnackbarService} from '../../../shared/components/shared-snackbar/services/shared-snackbar.service';

@Component({
  selector: 'app-y3-booklet-review',
  templateUrl: './y3-booklet-review.component.html',
  styleUrls: ['./y3-booklet-review.component.scss']
})
export class Y3BookletReviewComponent extends AbstractWindow implements OnInit, OnChanges {

  @Input() bookletReviews: BookletReviews;
  @Input() bookingId: string;
  @Input() curIndex: number;

  textReview: string = '';
  rating: number = 0;

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              private apiService: ApiService,
              private translateService: TranslateService,
              public sharedSnackbarService: SharedSnackbarService,
              protected dialog: MatDialog) {
    super(intercomService, utilsService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bookletReviews && this.bookletReviews) {
      this.textReview = this.bookletReviews.guestReviewComment;
      this.rating = this.bookletReviews.guestReviewRate;
    }
  }

  saveReview() {
    if (this.textReview.length > 0 && this.rating > 0) {
      const bookletReviewsLight = new BookletReviewsLight();
      bookletReviewsLight.guestReviewComment = this.textReview;
      bookletReviewsLight.guestReviewRate = this.rating;
      this.apiService.leaveBookletReviews(this.bookingId, bookletReviewsLight).subscribe(res => {
        this.sharedSnackbarService.push(new SnackbarMessage(this.translateService.instant('booklet-review.success'), 'info'));
      });
    } else {
      this.sharedSnackbarService.push(new SnackbarMessage(this.translateService.instant('booklet-review.need-info'), 'warning'));
    }
  }

}
