import { Component, OnInit } from '@angular/core';
import {IntercomService} from '../../../core/services/IntercomService';
import {UtilsService} from '../../../core/services/utils.service';
import {ActivatedRoute} from '@angular/router';
import {LanguageManagementService} from '../../../core/services/language.service';
import {GuestService} from '../../../core/services/guest.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {LoaderService} from '../../../core/services/LoaderService';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {takeUntil} from 'rxjs/operators';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import * as moment from 'moment';

@Component({
  selector: 'app-y3-checkin',
  templateUrl: './y3-checkin.component.html',
  styleUrls: ['./y3-checkin.component.scss']
})
export class Y3CheckinComponent extends AbstractWindow implements OnInit {

  booking: BookingForGuest;
  corporate: Corporate;
  property: PropertyForGuest;

  constructor(public intercomService: IntercomService,
              public utilsService: UtilsService,
              private route: ActivatedRoute,
              private languageService: LanguageManagementService,
              public translateService: TranslateService,
              private snackbarService: SnackbarService,
              private loaderService: LoaderService,
              private guestService: GuestService,
              private location: Location,
              protected dialog: MatDialog) {
    super(intercomService, utilsService);
  }

  ngOnInit() {

    this.route.paramMap.subscribe( ParamMap => {
      const bookingId = ParamMap.get('bookingId');
      console.log('bookingId', bookingId);
      this.guestService.getPropertyAndBookingForGuest(bookingId, this.languageService.getLanguageInUrl()).pipe(takeUntil(this.onDestroy)).subscribe(res => {
        this.booking = res.booking;
        this.corporate = res.corporate;
        this.property = res.property;
      });
    });
  }

  isDuringTheDay(): boolean {
    return moment(this.booking.startDate).add(-1, 'days').isBefore(moment.now()) && moment(this.booking.endDate).add(1, 'days').isAfter(moment.now());
  }

  getTime(date: string, defaultDate: string) {
    if (date) {
      return moment(date, 'hh:mm:ss').format('HH:mm').replace(':', 'h');
    } else if (defaultDate) {
      return (moment(defaultDate, 'hh:mm:ss').format('HH:mm')).replace(':', 'h');
    } else {
      return this.translateService.instant('guest-detail-booking.set-time');
    }
  }

}
